import { Backdrop as MuiBackdrop } from "@mui/material";

interface IProps {
    open: boolean,
}
export const Backdrop: React.FC<React.PropsWithChildren<IProps>> = ({ open, children }) => {
    return (
        <MuiBackdrop 
            open={open} 
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                {children}
        </MuiBackdrop>
    );
}