import MuiLink from "@mui/material/Link";
import React from "react";
import { Link as RLink } from "react-router-dom";

export const LinkButton = MuiLink;

export const RouterLink = ({ to, underline = "none", className, children }: React.PropsWithChildren<{ 
    to: string, 
    className?: string | undefined, 
    underline?: "none" | "hover" | "always" | undefined,
}>) => {
    return <MuiLink className={className || ""} underline={underline} component={RLink} to={to}>
        {children}
    </MuiLink>;
}
