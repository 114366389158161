import { routerAbsoluteBase } from "./settings";

export const routeBase = "/";

export const routes = {
    getById: (id: string) => {
        switch (id) {
            case "b9d0e55b-16e0-49cc-aa64-d7f2c4f0fd2a":
                return routes.estimator.projects;

            case "18c3a9dd-76de-4741-9825-69a48ea6f10e":
                return routes.uuid;

            case "c42d8000-7d0c-4d33-bd93-493b3c076b63":
                return routes.todos.lists;

            case "21e73d8e-27d4-4941-ba1b-af4b2d4dc35f":
                return routes.ipecho;

            case "0fb2e514-c452-4cef-a631-112972d323fb":
                return routes.profile;

            case "5f0b01cd-a9c5-4707-aa7e-5ca372dd9c3e":
                return routes.fitness.home;

            case "acbc3c3d-167e-4582-82fd-97d6393dc506":
                return routes.trade.holdings;
        }

        return "";
    },
    home: routeBase + "",
    auth: {
        signinAbsolute: routerAbsoluteBase + "signin",
        signin: routeBase + "signin",
        signup: routeBase + "signup",
    },
    profile: routeBase + "profile",
    uuid: routeBase + "uuid",
    ipecho: routeBase + "echoip",
    estimator: {
        projects: routeBase + "estimator/projects",
        estimatePath: routeBase + "estimator/estimate/:id",
        estimate: (id: string) => routeBase + `estimator/estimate/${id}`,
        addEstimate: routeBase + "estimate/add",
    },
    todos: {
        lists: routeBase + "tasks/lists",
        listPath: routeBase + "tasks/lists/:id",
        list: (id: string) => routeBase + `tasks/lists/${id}`,
        listAdd: routeBase + `tasks/lists/add`,
        listEditPath: routeBase + "tasks/lists/edit/:id",
        listEdit: (id: string) => routeBase + `tasks/lists/edit/${id}`,
        sharelistPath: routeBase + "tasks/lists/:id/sharing",
        sharelist: (id: string) => routeBase + `tasks/lists/${id}/sharing`,
        todoPath: routeBase + "tasks/task/:id",
        todo: (id: string) => routeBase + `tasks/task/${id}`,
    },
    fitness: {
        home: routeBase + "fitness/index",
    },
    mealplanner: {
        home: routeBase + "mealplanner/index",
    },
    trade: {
        holdings: routeBase + "trade/holdings",
    },
}