import * as React from "react";
import ReactDOM from "react-dom";
import { APPBAR_MENU_START } from "@auth/components/header";
import { IconButton } from "@common/components/forms/iconButton";
import { Back } from "@common/components/icons";

interface IProps {
    disabled?: boolean,
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined,
}

export const BackButton: React.FC<IProps> = ({ disabled = false, onClick }) => {
    const container = document.getElementById(APPBAR_MENU_START);
    return container && ReactDOM.createPortal((
        <IconButton 
            edge="start" 
            size="large" 
            disabled={disabled} 
            icon={<Back size={24} />}
            onClick={onClick} 
        />
    ), container);
}