import { Uuid } from "@common/models";
import { authorize } from "@common/services/api";
import { uuidService } from "@common/services/uuid";
import { wsBase } from "@config/settings";
import { ITodo } from "@todos/models";

const WS_SERVER_PATH = `${wsBase}api/v0/tasks/ws`;
const WS_PING_TIMEOUT = 1000;
const WS_CONNECT_TIMEOUT = 5000;

export namespace WSOutbound {
    export interface ListConnect { 
        ListConnect: { 
            list_id: Uuid,
        },
    };

    export interface ListDisconnect { 
        ListDisconnect: { 
            list_id: Uuid,
        },
    };

    export interface Ping {
        Ping: Uuid,
    }
}

export namespace WSInbound {
    export interface UserInfo {
        user_name: string,
        user_email: string,
    }

    export interface ListChanged { 
        ListChanged: { 
            list_id: Uuid,
            users: Array<WSInbound.UserInfo>,
        },
    }

    export interface TodoAdded { 
        TodoAdded: {
            user_info: UserInfo,
            todo: ITodo,
        },
    }

    export interface TodoUpdated { 
        TodoUpdated: {
            user_info: UserInfo,
            todo: ITodo,
        },
    }

    export interface TodoRemoveDone { 
        TodoRemoveDone: {
            user_info: UserInfo,
            todo_ids: Uuid[],
        },
    }

    export interface Error {
        Error: string,
    }

    export interface Pong { 
        Pong: string,
    }

    export interface Connected {
        Connected: {
            user_info: UserInfo,
        }
    }
}

export type WSOutboundMessage 
    = WSOutbound.Ping
    | WSOutbound.ListConnect 
    | WSOutbound.ListDisconnect
    ;

export type WSInboundMessage
    = WSInbound.Pong
    | WSInbound.ListChanged
    | WSInbound.TodoAdded
    | WSInbound.TodoUpdated
    | WSInbound.TodoRemoveDone
    | WSInbound.Connected
    ;

export type WSMessageCallback = (message: WSInboundMessage) => void;

let pingTimeout: any = null;

interface IPing {
    pingId: Uuid,
    at: number,
    socket: WebSocket,
}
let pings: IPing[] = [];
let pingTryCount = 0;

(window as unknown as any).pings = pings;

/**
 * Start the ping timer.
 * @param socket WebSocket to send over
 * @param milliseconds time between pings
 */
function startPing(socket: WebSocket, firstMessage: string, milliseconds: number = WS_PING_TIMEOUT) {
    stopPing();

    const nextTry = pingTryCount < 10 ? 1000
        : pingTryCount < 15 ? 2000
        : pingTryCount < 21 ? 5000
        : pingTryCount < 22 ? 10000
        : 15000; 

    pingTimeout = setTimeout(function() {
        const pingId = uuidService.uuid();
        pings.push({ pingId, at: Date.now(), socket });

        
        if (socket.readyState === socket.CLOSED || socket.readyState === socket.CLOSING) {
            pingTryCount = pingTryCount + 1;

            ws.connect("PING RECONNECT ATTEMPT", firstMessage)
                .catch(err => {
                    // Ping again on the closed socket in order
                    // to force a connect retry.
                    console.error("PING RECONNECT FAILED: ", pingTryCount, nextTry, err);
                    stopPing();

                    if (!err?.stop) {
                        startPing(socket, firstMessage, nextTry);
                    }
                });

            // Connect will initiate ping.
        } else {
            //console.debug("PING SEND", pingId, socket.readyState);
            socket.send(JSON.stringify({ Ping: pingId } as WSOutbound.Ping));

            // Next ping
            pingTryCount = 0;
            stopPing();
            startPing(socket, firstMessage, WS_PING_TIMEOUT);
        }
    }, milliseconds);
}

/**
 * Stop the ping timer
 */
function stopPing() {
    clearTimeout(pingTimeout);
}

interface ITryConnect {
    uuid: Uuid,
    socket: WebSocket,
    timeout: number,
}
const attempts: ITryConnect[] = [];

function connectOne(token: string): Promise<WebSocket> {
    return new Promise((resolve, reject) => {
        // Create a websocket and subscribe to some of the events
        // Set a timout to verify connect, if no connection is established
        // in time then drop the attempt and close the socket in order
        // to avoid creating many connections.

        const uuid = uuidService.uuid();
        const socket = new WebSocket(WS_SERVER_PATH, [".token", token.substring(7)]);
        const timeout = setTimeout(function() {
            // If there's a successful connection, we'll cancel the timer
            // as well as any other try.
            socket.close();
        }, WS_CONNECT_TIMEOUT);

        const attempt = { uuid, socket, timeout };
        attempts.push(attempt);

        // Attach the callbacks and remove them before returning the
        // successful connection
        let onError: any = null;
        let onClose: any = null;
        let onOpen: any = null;

        const removeAll = () => {
            socket.removeEventListener("open", onOpen);
            socket.removeEventListener("close", onClose);
            socket.removeEventListener("error", onError);
        }
        
        onOpen = () => {
            console.log("WS CONNECTED", uuid);

            // Clear the current connect timer
            clearTimeout(timeout);

            // Iterate and remove the other tries
            // making sure to close the sockets.
            const others = attempts.filter(p => p.uuid !== uuid);
            others.map(p => {
                p.socket.close();
            });

            // Remove self, the caller will attach it's own.
            removeAll();
            resolve(socket);
        };
        socket.addEventListener("open", onOpen);
        
        onError = (event: Event) => {
            console.log("Websocket - Error", uuid, event);
            reject && reject("Failed to connect with error.");
        };
        socket.addEventListener("error", onError);

        onClose = (_event: Event) => {
            console.log("Websocket - Closed", uuid);

            removeAll();
            clearTimeout(timeout);
            reject && reject("Closed")
        };
        socket.addEventListener("close", onClose);
    });
}

export const ws = {
    webSocket: null as unknown as WebSocket,
    subscribers: new Set<WSMessageCallback>(),
    sendQueue: [] as WSOutboundMessage[],
    sendConsumer: null as any,
    userInfo: undefined as WSInbound.UserInfo | undefined,

    connect: (reason: string = "connect", firstMessage: string): Promise<WebSocket> => {
        try {
            console.log("Websocket - CONNECTING: ", reason);

            const state = ws.webSocket?.readyState || WebSocket.CLOSED;
            if (state !== WebSocket.CLOSED) {
                console.log("Websocket - ALREADY CONNECTED", ws.webSocket?.readyState);
                return Promise.resolve(ws.webSocket);
            }

            const headers = authorize([]);
            const token = headers.length === 1 && headers[0].length === 2 && headers[0][1] || "";
            if (token == "") {
                console.log("Websocket - USER NOT LOGGED IN");
                return Promise.reject({ message: "Websocket - USER NOT LOGGED IN", stop: true });
            }
            
            clearInterval(ws.sendConsumer);

            return connectOne(token).then(socket => {
                console.info("Websocket - CONNECTED", firstMessage);

                ws.webSocket = socket;

                ws.webSocket.onerror = (_event) => {
                    pings.length = 0;
                };

                ws.webSocket.onclose = (event) => {
                    console.info("Websocket - Closed", event);
                    pings.length = 0;
                };

                ws.webSocket.onmessage = (event) => {    
                    const data = JSON.parse(event.data) as WSInboundMessage;

                    // Errors
                    {
                        const m = data as unknown as WSInbound.Error;
                        if (m.Error) {
                            console.error("RECV Error", m.Error);
                            return;
                        }
                    }

                    // Process pong. Don't send it on to subscribers.
                    {
                        const m = data as unknown as WSInbound.Pong;
                        if (m.Pong) {
                            //console.debug("RECV PONG");
                            pings = pings.filter(p => p.pingId === m.Pong);
                            return;
                        }
                    }
                    
                    // Process connected. Don't send it on to subscribers.
                    {
                        const m = data as unknown as WSInbound.Connected;
                        if (m.Connected) {
                            console.debug("RECV CONNECTED", m.Connected);
                            ws.userInfo = m.Connected.user_info;
                            return;
                        }
                    }

                    // Send the message on to the subscribers.
                    {
                        console.debug("RECV Data", event.data);
                        ws.subscribers.forEach(p => p && p(data));
                        return;
                    }
                };

                // Send first message
                ws.webSocket.send(firstMessage);

                // Start the consumer. It will send queued messages
                ws.sendConsumer = setTimeout(() => {
                    let state =  ws.webSocket?.readyState || WebSocket.CLOSED;

                    // Send the messages to the server
                    while(ws.sendQueue.length > 0 && state === WebSocket.OPEN) {
                        const message = ws.sendQueue.pop();
                        ws.webSocket.send(JSON.stringify(message));

                        // Next
                        state = ws.webSocket?.readyState || WebSocket.CLOSED;
                    }
                }, 100);

                stopPing();
                startPing(ws.webSocket, firstMessage);
                return ws.webSocket;
            });
        } catch (error) {
            console.error("Websocket - FAILED", error);
            return Promise.reject(error);
        }
    },

    send: (message: WSOutboundMessage) => {
        ws.sendQueue.push(message);
    },

    subscribe: (listener: WSMessageCallback, listId: Uuid) => {
        const firstMessage = JSON.stringify({
            ListConnect: { list_id: listId },
        });

        ws.connect("subscribe", firstMessage);
        ws.subscribers.add(listener);
    },

    unsubscribe: (listener: WSMessageCallback) => {
        ws.subscribers.delete(listener);
    },

    close: () => {
        console.log("DISCONNECT", ws.webSocket, pings);
        stopPing();
        pings.forEach(p => {
            p?.socket?.close();
        });
        console.log("DISCONNECT - stop ping", ws.webSocket, pings);
        ws.subscribers.clear();
        ws.webSocket?.close();

        console.log("DISCONNECT - close websocket", ws.webSocket, pings);
        clearInterval(ws.sendConsumer);
    },
}