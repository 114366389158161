import { Uuid } from "@common/models";

export enum ModifierType { Percent, Fixed }

export interface ITempId {
    tempId: number;
}

export class TempId {
    static lastTempId: number = 1;
    
    static next(): number {
        TempId.lastTempId += 1;
        return TempId.lastTempId;
    }
}

export interface INode extends ITempId {
    title: string;
    nodes: Array<INode>;
    low?: number | undefined;
    high?: number | undefined;
}

export interface IModifier {
    tempId: number,
    name: string,
    modifier_type: ModifierType,
    value: number,
}

export type NodeGridOptions = {
    show_sub_totals: boolean,
    default_rate: number,
    modifiers: Array<IModifier>
}

export interface IEstimate extends INode {
    id?: Uuid,
    options: NodeGridOptions,
}

export interface IProjectIndexRow {
    id: number,
    title: string,
}
