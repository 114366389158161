import MuiIconButton from "@mui/material/IconButton";

interface IProps {
    color?: "inherit" | "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined;
    size?: "small" | "medium" | "large" | undefined,
    onClick?: React.MouseEventHandler<HTMLButtonElement>,
    icon: JSX.Element,
    label?: string | undefined,
    disabled?: boolean | undefined,
    edge?: false | "start" | "end" | undefined,
}

export const IconButton = ({ disabled = false, onClick, color = "default", icon, label, size = "medium", edge = false }: IProps) => {
    return (
        <MuiIconButton edge={edge} disabled={disabled} color={color} size={size} onClick={onClick} aria-label={label}>
            {icon}
        </MuiIconButton>
    );
}

export default IconButton;
