import { INotification } from "@common/services/notification";
import { Notification } from "../notification";
import style from "./style.module.css";

interface IProps {
    notifications: Array<INotification>,
}

export const Notifications = ({ notifications }: IProps) => {
    return (
        <div className={style.notifications}>
            {notifications.map((p, i) => {
                return (
                    <Notification key={i} {...p} />
                );
            })}
        </div>
    );
};

export default Notifications;