import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { RejectReason } from "@common/models";
import { notificationService } from "@common/services/notification";
import { api, ITodoGetResult } from "@todos/services/api";
import { ITodo, ITodoList, newTodoList } from "src/todos/models";
import TextField from "@common/components/forms/textField";
import Button from "@common/components/forms/button";
import { errorFor, IVErrorsKind } from "@common/models/validation";
import { Breadcrumb } from "@common/components/elements/breadcrumb";
import { routes } from "@config/routes";
import style from "./style.module.css";
import { Form } from "@common/components/forms/form";
import { Checkbox } from "@common/components/forms/checkbox";
import { Tag } from "@common/components/elements/tag";
import { todoService } from "@todos/services/todoService";
import { ws, WSInbound, WSMessageCallback } from "@todos/services/ws";
import { BackButton } from "@common/components/elements/backButton";
import { SkeletonText, SkeletonTile } from "@common/components/elements/skeleton";
import DateField from "@common/components/forms/dateField";
import { Grid } from "@common/components/elements/grid";
import TimeField from "@common/components/forms/timeField";
import { HtmlTextArea } from "@common/components/forms/htmlTextArea";

interface IProps {
    id?: string,
}

interface IState {
    todo: ITodo,
    list: ITodoList,
    tags: Set<string>,
    validationErrors: IVErrorsKind | undefined,
    isLoading: boolean,
    isSubmitting: boolean,
}

class Todo extends React.Component<RouteComponentProps<IProps>, IState> {
    constructor(props: RouteComponentProps<IProps>) {
        super(props);

        this.state = {
            todo: todoService.newTodo(""),
            list: newTodoList(""),
            isLoading: true,
            isSubmitting: false,
            tags: new Set<string>(),
            validationErrors: undefined,
        };
    }

    todo_id: string = "";
    list_id: string = "";

    override componentDidMount = () => {
        this.todo_id = this.props.match?.params?.id || "";

        api.getTodo(this.todo_id)
            .then(data => {
                const { todo, list } = data as ITodoGetResult;
                const tagSet = new Set<string>();
                
                ws.subscribe(this.wsListener, list.list_id);

                this.list_id = list.list_id;
                todoService.extractTags(tagSet, todo);

                this.setState({
                    todo,
                    list,
                    tags: tagSet,
                    isLoading: false,
                    validationErrors: undefined,
                });
            })
            .catch((rejected: RejectReason) => {
                const validationErrors = notificationService.rejected(rejected);
                this.setState({
                    isLoading: false,
                    validationErrors: validationErrors || undefined,
                });
            });
    };

    override componentWillUnmount = () => {
        ws.close();
    }

    wsListener: WSMessageCallback = (message) => {
        const status = (message as unknown as WSInbound.ListChanged);
        if (status && status.ListChanged) {
            console.log("ListStatus", status);
            return;
        }
    }

    todoChanged = (diff: Partial<ITodo>, persist: boolean) => {
        const todo = this.state.todo;
        const mutatedTodo = Object.assign({}, todo, diff);

        if (!persist) {
            this.setState({
                todo: mutatedTodo,
            });
        } else {
            todoService.transformTags(mutatedTodo);

            this.setState({
                todo: mutatedTodo,
            });
        }
    }

    inputChanged = (value: any, field: keyof ITodo, persist: boolean) => {
        this.todoChanged({ [field]: value }, persist);
    };

    todoDescriptionBlurred = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => this.inputChanged(e.currentTarget.value, "description", true);
    todoDescriptionChanged = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => this.inputChanged(e.currentTarget.value, "description", false);
    statusChanged = (checked: boolean) => this.inputChanged(checked ? "Done" : "Pending", "status", true);

    submit: (event?: React.FormEvent<HTMLFormElement>) => void = e => {
        // Ensure the default form submission is suppressed
        e?.preventDefault();

        // Ensure any transformation/change gets applied to the state
        this.inputChanged(this.state.todo.description, "description", true);
        
        setTimeout(() => {
            // Fetch mutated todo from the state
            const { todo } = this.state;
            this.setState({
                isSubmitting: true,
            }, () => {
                api.postTodo(todo)
                    .then(() => {
                        notificationService.success("Success", "Task saved.");

                        this.setState({
                            validationErrors: undefined,
                        });
                    })
                    .catch((err: RejectReason) => {
                        const validationErrors = notificationService.rejected(err);
                        if (validationErrors) {
                            this.setState({ validationErrors });
                        }
                    })
                    .finally(() => {
                        this.setState({
                            isSubmitting: false,
                        });
                    });
            });
        }, 1);
    }

    override render = () => {
        const { history } = this.props;
        const { todo, list, isSubmitting, isLoading } = this.state;

        const tags = todo.tags || [];
        const removeTag = (tag: string) => {
            return () => this.todoChanged({ tags: tags.filter(p => p !== tag) }, true);
        };

        let content;
        if (!isLoading) {
            content = (
                <>
                    <Breadcrumb path={[
                        { href: routes.home, title: "Home" },
                        { href: routes.todos.list(list.list_id), title: list.description },
                        { href: "", title: todo.description || "Task" },
                    ]} />
                    <div className={style.form_outer}>
                        <Form id="edit-form" label="Edit Task" onSubmit={this.submit}>
                            <div style={{ flexGrow: 1, marginTop: "6px", marginRight: ".5rem" }}>
                                <TextField
                                    id="todo-description"
                                    label="Description"
                                    value={todo.description}
                                    rightLabel={`${todo.description.length}/255`}
                                    onChange={this.todoDescriptionChanged}
                                    onBlur={this.todoDescriptionBlurred}
                                    readonly={isLoading}
                                    autoCapitalize="sentences"
                                    errorMessage={errorFor(this.state, "description")}
                                />
                                <div className={style.todo_row_tags}>
                                    {tags.map(p => {
                                        const remove = removeTag(p);
                                        return (
                                            <Tag key={p} size="small" label={p} onDelete={remove} />
                                        );
                                    })}
                                </div>
                                <Grid container>
                                    <Grid xs={12} sm={6} md={3}>
                                        <DateField
                                            id="todo-due-date"
                                            label="Due date"
                                            value={todo.due_at}
                                            onChange={(value) => {
                                                console.log(value, value?.toISOString());
                                                this.inputChanged(value, "due_at", true);
                                            }}
                                            readonly={isLoading}
                                            errorMessage={errorFor(this.state, "due_at")}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6} md={3}>
                                        <TimeField
                                            id="todo-due-date"
                                            label="Due time"
                                            value={todo.due_at}
                                            onChange={(value) => {
                                                console.log(value, value?.toISOString());
                                                this.inputChanged(value, "due_at", true);
                                            }}
                                            readonly={isLoading}
                                            errorMessage={errorFor(this.state, "due_at")}
                                        />
                                    </Grid>
                                </Grid>
                                <div className={style.todo_done_at}>

                                </div>
                                <div className={style.todo_status}>
                                    <Checkbox
                                        id={todo.todo_id}
                                        label={"Task Complete"}
                                        checked={todo.status === "Done"}
                                        onChange={this.statusChanged}
                                    />
                                </div>
                                <div className={style.todo_detail}>
                                    <HtmlTextArea 
                                        label="Task Details" 
                                        initialValue={todo.details} 
                                        errorMessage={errorFor(this.state, "details")}
                                        onBlur={(value) => {
                                            this.inputChanged(value, "details", true);
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{ marginTop: 32 }}>
                                <Button id={"btnSave"} label="Save" isLoading={isSubmitting} onClick={() => this.submit(undefined)} />
                            </div>
                        </Form>
                    </div>
                </>
            );
        } else {
            content = (
                <>
                    <Breadcrumb isLoading={true} path={[
                        { href: routes.home, title: "Home" },
                        { href: routes.todos.list(list.list_id), title: list?.description, isLoading: true, loadingWidth: "4rem" },
                        { href: "", title: todo?.description || "Task" , isLoading: true, loadingWidth: "4rem" },
                    ]} />
                    <div className={style.form_outer}>
                        <SkeletonText className={style.todo_rows_h_skeleton} />
                        <div style={{ flexGrow: 1, marginTop: "6px", marginRight: ".5rem" }}>
                            <TextField
                                id="todo-description"
                                label="Description"
                                value={todo.description}
                                isLoading={true}
                            />
                            <div className={style.todo_row_tags}>
                                
                            </div>
                            <div className={style.todo_status}>
                                <SkeletonTile className={style.check_skeleton} />                                
                            </div>
                        </div>
                        <div style={{ marginTop: 32 }}>
                            <SkeletonTile className={style.button_skeleton} />
                        </div>
                    </div>
                </>
            );
        }

        // TODO implement form validation // validationErrors

        return (
            <>
                <BackButton onClick={() => {
                    history.replace(routes.todos.list(list.list_id));
                }} />
                {content}
            </>
        );
    }
}

export default withRouter(Todo);