/**
 * Check if v is not undefined and is not null
 * @param v 
 * @returns 
 */
export function hasValue(v: any) {
    return v !== undefined && v !== null;
}

/**
 * Attach a script element to the first element in the DOM that matches the selector.
 * @param selector 
 * @param url 
 * @param async 
 * @returns function to remove the script element from the DOM node selector resolves to.
 */
export function injectScript(selector: string, url: string, async?: boolean): undefined | (() => void) {
    const element = document.querySelector(selector);
    if (!element) {
        console.error("Selector did not resolve.");
        return undefined;
    }

    const script = document.createElement('script');

    script.src = url;
    script.async = typeof async === 'undefined' ? true : async;

    element.appendChild(script);

    return () => {
        element.removeChild(script);
    };
}