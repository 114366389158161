
import MuiList from "@mui/material/List";
import MuiListItem from "@mui/material/ListItem";
import MuiListSubheader from "@mui/material/ListSubheader";
import MuiListItemIcon from "@mui/material/ListItemIcon";
import MuiListItemText from "@mui/material/ListItemText";
import MuiDivider from '@mui/material/Divider';
import MuiListItemButton from '@mui/material/ListItemButton';

export const List = MuiList;
export const ListItem = MuiListItem;
export const ListSubheader = MuiListSubheader;
export const ListItemIcon = MuiListItemIcon;
export const ListItemText = MuiListItemText;
export const ListDivider = MuiDivider;
export const ListItemButton = MuiListItemButton;

export const ListButtonFn = (key: string, text: string, icon: JSX.Element, onclick: () => void) => (
    <ListItem key={key} disablePadding>
        <ListItemButton onClick={onclick}>
            <ListItemIcon style={{ minWidth: 36 }}>
                {icon}
            </ListItemIcon>
            <ListItemText primary={text} />
        </ListItemButton>
    </ListItem>
);