import { RouterLink } from "@common/components/elements/link";
import style from "./style.module.css";

interface IProps { 
    id: string,
    name: string,
    description: string,
    href: string,
    tileColor?: string,
    textColor?: string,
}

const TILE_HEIGHT = 120;

export const tileStyle = {
    minHeight: TILE_HEIGHT,
}

export const Application = ({ id, name, description, href, tileColor, textColor }: IProps) => {
    const st = tileColor
        ? Object.assign({ backgroundColor: tileColor }, tileStyle)
        : tileStyle;

    return (
        <RouterLink className={style.tile} to={href}>
            <div id={id} title={name} style={st}>
                <h2 style={{ color: textColor }}>{name}</h2>
                <p style={{ color: textColor }}>{description}</p>
            </div>
        </RouterLink>
    );
}

export default Application;