import React from "react";
import MuiBox from "@mui/material/Box";
import Title from "@common/components/elements/title";
import style from "./style.module.css";

interface IProps {
    id?: string,
    label?: string,
    onSubmit?: (event?: React.FormEvent<HTMLFormElement>) => void,
    overview?: JSX.Element,
}

export const Form: React.FC<React.PropsWithChildren<IProps>> = ({ id, onSubmit, label, overview, children }) => {
    return (
        <MuiBox id={id} component="form" onSubmit={onSubmit}>
            {label && 
                <Title text={label} />
            }
            {overview && 
                <div className={style.overview}>{overview}</div>
            }
            <div className="form-groups">
                {children}
            </div>
        </MuiBox>
    );
}

export default Form;
