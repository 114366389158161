import style from "./style.module.css";

interface IProps {
    text: string,
    size?: "sm" | "md" | "lg" | "xl",
}

export const Title = ({ text, size = "md" }: IProps) => {
    return (
        <h1 className={style[`size-${size}`]}>{text}</h1>
    );
};

export default Title;