import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

interface IProps {
    style?: React.CSSProperties,
    className?: string,
    size?: number,
}

export function Loader({ style, className, size = 40 }: IProps) {
    return (
        <CircularProgress size={size} style={style} className={className} />
    );
}
export default Loader;
