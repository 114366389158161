import * as React from "react";
import { Table, TBody, Td, TFooter, Th, THead, Tr } from "../table";

interface IClassName {
    className?: string,
}

export interface IDataTableHeader extends IClassName {
    header: string | JSX.Element,
    width?: string | number,
    rowValueKey: string,
    rowValueFn?: (row: any, rowKey: string, rowIndex: number) => string | JSX.Element,
    thClassName?: string,
    tdClassName?: string,
}

interface IDataTableProps extends IClassName {
    headers: IDataTableHeader[],
    rows: Array<any>,
}

function defaultRowValueFn(row: any, rowKey: string, _rowIndex: number): JSX.Element | string {
    return row[rowKey];
}

export const DataTable: React.FC<React.PropsWithChildren<IDataTableProps>> = ({ 
    headers = [],
    rows = [],
}) => {
    return (
        <Table stickyHeader>
            <THead>
                <Tr>
                    {headers.length > 0 && headers.map((p, i) => {
                        return (
                            <Th key={i} className={p.className} width={p.width}>{p.header}</Th>
                        );
                    })}
                </Tr>
            </THead>
            <TBody>
                {rows.length > 0 && rows.map((r, ri) => {
                    return (
                        <Tr key={ri}>
                            {headers.map((h, hi) => {
                                const vf = h.rowValueFn || defaultRowValueFn;
                                const v = vf(r, h.rowValueKey, ri);
                                return (
                                    <Td key={hi} className={h.tdClassName}>
                                        {v}
                                    </Td>
                                );
                            })}
                        </Tr>
                    );
                })}
            </TBody>
            <TFooter>
            </TFooter>
        </Table>
    );
}