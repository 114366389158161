import { apiBase } from "@config/settings";
import { apiGet, apiPost, expectEmpty, expectJson } from "@common/services/api";
import { RejectReason } from "@common/models";

const SERVER_PATH = `${apiBase}api/v0/profile`;

export interface IProfile {
    name: string,
}

/**
 * Api endpoints
 */
 export const api = {
    /**
     * GET User profile
     * @returns Promise<Array<IProfileResult | RejectReason>>
     */
    getProfile: () => apiGet(SERVER_PATH + "")
        .then<IProfile | RejectReason>(resp => expectJson(resp, "Failed to fetch profile.")),

    /**
     * POST a user profile
     * @returns Promise<Array<IProfile | RejectReason>>
     */
    postProfile: (profile: IProfile) => apiPost(SERVER_PATH + "", profile)
        .then<void | RejectReason>(resp => expectEmpty(resp, "Failed to save the profile.")),

};