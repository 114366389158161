import { apiBase } from "@config/settings";
import { apiGet, expectJson } from "@common/services/api";
import { RejectReason } from "@common/models";

const SERVER_PATH = `${apiBase}api/v0/`;

export interface IUuidResult {
    simple: string,
    hyphenated: string,
}

/**
 * Api endpoints
 */
 export const api = {
    /**
     * GET a new uuid
     * @returns Promise<Array<IUuidResult | RejectReason>>
     */
    getUuid: () => apiGet(SERVER_PATH + "uuid")
        .then<IUuidResult | RejectReason>(resp => expectJson(resp, "Failed to fetch a new uuid.")),
};