import { RejectReason } from "../models";
import { IVErrorsKind } from "../models/validation";
import { uuidService } from "./uuid";

export type NotificationId = string;
export type NotificationType = "error" | "info" | "success" | "warning";
export type FadeCallback = (id: NotificationId) => any;

const ERROR_OCCURRED = "An error occurred.";
const VALIDATION_FAILED = "Validation failed.";

export interface INotification {
    id: string;
    type: NotificationType;
    title: string;
    message: string;
    fadeTimeout: any;
    show: "show" | "hide" | "fade";
}

export type UpdatedCallback = (queue: INotification[]) => void;

class NotificationService {
    queue: INotification[] = [];
    sweepInterval: any;
    updatedCallback: UpdatedCallback | null = null;

    constructor() {
        this.sweepInterval = setInterval(this.sweeper, 200);
    }

    initialize(updatedCallback: UpdatedCallback) {
        this.updatedCallback = updatedCallback;
    }
    
    free() {
        if (this.sweepInterval) {
            clearInterval(this.sweepInterval);
        }
        if (this.updatedCallback) {
            this.updatedCallback = null;
        }
    }

    queueUpdated = () => {
        this.updatedCallback && this.updatedCallback(this.queue);
    }

    sweeper = () => {
        let found = false;
        for (let i = 0; i < this.queue.length; i++) {
            if (this.queue[i].show === "hide") {
                found = true;
                break;
            }
        }

        if (found) {
            this.queue = this.queue.filter(p => p.show !== "hide");
            this.queueUpdated();
        }
    }

    fade: FadeCallback = (notificationId: NotificationId): any => {
        const notification = this.queue.find(p => p.id === notificationId);
        if (!notification)
            return;

        notification.show = "fade";
        this.queueUpdated();

        return setTimeout(() => {
            notification.show = "hide";
            this.queueUpdated();
        }, 1000); //Must be >= the css transition time
    }

    notify = (title: string, message: string, type: NotificationType, fadeTime: number = 2500) => {
        const self = this;
        const id = uuidService.uuid();
        const notification: INotification = {
            id: id,
            type: type,
            title, 
            message: message,
            show: "show",
            fadeTimeout: setTimeout(() => {
                self.fade(id);
            }, fadeTime)
        };

        this.queue.push(notification);
        this.queueUpdated();
    }

    error = (title: string, message: string, fadeTime?: number) => {
        this.notify(title, message, "error", fadeTime);
    }

    maybeInvalid = (invalid: IVErrorsKind | null | undefined): IVErrorsKind | undefined => {
        if (invalid) {
            this.error("Error", VALIDATION_FAILED);
        }

        return invalid || undefined;
    }

    rejected = (rejected: RejectReason): IVErrorsKind | undefined => {
        console.error(rejected);
        
        let error: IVErrorsKind | null = null;
        let message: string | null = null;

        if (!rejected || !Array.isArray(rejected) || rejected.length !== 2) {
            message = (typeof rejected === "string") ? rejected : ERROR_OCCURRED;
        
        } else if (rejected[0] === "err") {
            message = (typeof rejected[1] === "string") ? rejected[1] : ERROR_OCCURRED;
        
        } else if (rejected[0] === "validation") {
            error = rejected[1] || null;
            if (error) {
                message = VALIDATION_FAILED;
            }
        }
        
        this.error("Error", message || ERROR_OCCURRED);
        return error || undefined;
    }

    info = (title: string, message: string, fadeTime?: number) => {
        this.notify(title, message, "info", fadeTime);
    }

    warning = (title: string, message: string, fadeTime?: number) => {
        this.notify(title, message, "warning", fadeTime);
    }
    
    success = (title: string, message: string, fadeTime?: number) => {
        this.notify(title, message, "success", fadeTime);
    }
}

export const notificationService = new NotificationService();
//window["notificationService"] = notificationService;