import * as React from "react";
import { Color } from "@common/components/elements/colors";

const DEFAULT_COLOR = "#ccc";
const DEFAULT_SIZE = 24;
type IconProps = {size?: number | undefined, color?: string | Color | undefined};

const Icon: React.FC<IconProps & {path: string}> = ({ size, color, path }) => {
    let ht = size || DEFAULT_SIZE;
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            height={`${ht}px`} viewBox="0 0 24 24"
            width={`${ht}px`} fill={color?.toString()?.toString() || DEFAULT_COLOR}>
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d={path} />
        </svg>
    );
}

const MultiPathIcon: React.FC<React.PropsWithChildren<IconProps>> = ({ size, color, children }) => {
    let ht = size || DEFAULT_SIZE;
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            height={`${ht}px`} viewBox="0 0 24 24"
            width={`${ht}px`} fill={color?.toString() || DEFAULT_COLOR}>
                {children}
        </svg>
    );
}

export const Back: React.FC<IconProps> = ({ size, color }) => 
    <Icon size={size || DEFAULT_SIZE} color={color?.toString() || DEFAULT_COLOR} path="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/>;

export const ManageAccounts: React.FC<IconProps> = ({ size, color }) => 
    <MultiPathIcon size={size || DEFAULT_SIZE} color={color?.toString() || DEFAULT_COLOR}>
        <g><path d="M0,0h24v24H0V0z" fill="none"/></g>
        <g><g>
            <path d="M4,18v-0.65c0-0.34,0.16-0.66,0.41-0.81C6.1,15.53,8.03,15,10,15c0.03,0,0.05,0,0.08,0.01c0.1-0.7,0.3-1.37,0.59-1.98 C10.45,13.01,10.23,13,10,13c-2.42,0-4.68,0.67-6.61,1.82C2.51,15.34,2,16.32,2,17.35V20h9.26c-0.42-0.6-0.75-1.28-0.97-2H4z"/>
            <path d="M10,12c2.21,0,4-1.79,4-4s-1.79-4-4-4C7.79,4,6,5.79,6,8S7.79,12,10,12z M10,6c1.1,0,2,0.9,2,2s-0.9,2-2,2 c-1.1,0-2-0.9-2-2S8.9,6,10,6z"/>
            <path d="M20.75,16c0-0.22-0.03-0.42-0.06-0.63l1.14-1.01l-1-1.73l-1.45,0.49c-0.32-0.27-0.68-0.48-1.08-0.63L18,11h-2l-0.3,1.49 c-0.4,0.15-0.76,0.36-1.08,0.63l-1.45-0.49l-1,1.73l1.14,1.01c-0.03,0.21-0.06,0.41-0.06,0.63s0.03,0.42,0.06,0.63l-1.14,1.01 l1,1.73l1.45-0.49c0.32,0.27,0.68,0.48,1.08,0.63L16,21h2l0.3-1.49c0.4-0.15,0.76-0.36,1.08-0.63l1.45,0.49l1-1.73l-1.14-1.01 C20.72,16.42,20.75,16.22,20.75,16z M17,18c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S18.1,18,17,18z"/>
        </g></g>
    </MultiPathIcon>

export const Apps: React.FC<IconProps> = ({ size, color }) => 
    <MultiPathIcon size={size || DEFAULT_SIZE} color={color?.toString() || DEFAULT_COLOR}>
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z"/>
    </MultiPathIcon>

export const RemoveDone: React.FC<IconProps> = ({ size, color }) => 
    <MultiPathIcon size={size || DEFAULT_SIZE} color={color?.toString() || DEFAULT_COLOR}>
        <g><rect fill="none" height="24" width="24"/></g>
        <g>
            <path d="M4.84,1.98L3.43,3.39l10.38,10.38l-1.41,1.41l-4.24-4.24l-1.41,1.41l5.66,5.66l2.83-2.83l6.6,6.6l1.41-1.41L4.84,1.98z M18.05,12.36L23,7.4L21.57,6l-4.94,4.94L18.05,12.36z M17.34,7.4l-1.41-1.41l-2.12,2.12l1.41,1.41L17.34,7.4z M1.08,12.35 l5.66,5.66l1.41-1.41l-5.66-5.66L1.08,12.35z"/>
        </g>
    </MultiPathIcon>

export const SwapVert: React.FC<IconProps> = ({ size, color }) => 
    <Icon size={size || DEFAULT_SIZE} color={color?.toString() || DEFAULT_COLOR} path="M16 17.01V10h-2v7.01h-3L15 21l4-3.99h-3zM9 3L5 6.99h3V14h2V6.99h3L9 3zm7 14.01V10h-2v7.01h-3L15 21l4-3.99h-3zM9 3L5 6.99h3V14h2V6.99h3L9 3z" />

export const ChevronLeft: React.FC<IconProps> = ({ size, color }) => 
    <Icon size={size || DEFAULT_SIZE} color={color?.toString() || DEFAULT_COLOR} path="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"/>;

export const ChevronRight: React.FC<IconProps> = ({ size, color }) => 
    <Icon size={size || DEFAULT_SIZE} color={color?.toString() || DEFAULT_COLOR} path="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"/>;

export const ChevronDown: React.FC<IconProps> = ({ size, color }) => 
    <Icon size={size || DEFAULT_SIZE} color={color?.toString() || DEFAULT_COLOR} path="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"/>;

export const ClearX: React.FC<IconProps> = ({ size, color }) => 
    <Icon size={size || DEFAULT_SIZE} color={color?.toString() || DEFAULT_COLOR} path="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/>;

export const DragIndicator: React.FC<IconProps> = ({ size, color }) => 
    <Icon size={size || DEFAULT_SIZE} color={color?.toString() || DEFAULT_COLOR} path="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>;

export const NorthEast: React.FC<IconProps> = ({ size, color }) => 
    <Icon size={size || DEFAULT_SIZE} color={color?.toString() || DEFAULT_COLOR} path="M9,5v2h6.59L4,18.59L5.41,20L17,8.41V15h2V5H9z"/>;

export const SouthEast: React.FC<IconProps> = ({ size, color }) => 
    <Icon size={size || DEFAULT_SIZE} color={color?.toString() || DEFAULT_COLOR} path="M19,9h-2v6.59L5.41,4L4,5.41L15.59,17H9v2h10V9z"/>;

export const SubArrowLeft: React.FC<IconProps> = ({ size, color }) => 
    <Icon size={size || DEFAULT_SIZE} color={color?.toString() || DEFAULT_COLOR} path="M11 9l1.42 1.42L8.83 14H18V4h2v12H8.83l3.59 3.58L11 21l-6-6 6-6z"/>

export const SubArrowRight: React.FC<IconProps> = ({ size, color }) => 
    <Icon size={size || DEFAULT_SIZE} color={color?.toString() || DEFAULT_COLOR} path="M19 15l-6 6-1.42-1.42L15.17 16H4V4h2v10h9.17l-3.59-3.58L13 9l6 6z"/>

export const Add: React.FC<IconProps> = ({ size, color }) => 
    <Icon size={size || DEFAULT_SIZE} color={color?.toString() || DEFAULT_COLOR} path="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>

export const Options: React.FC<IconProps> = ({ size, color }) => 
    <Icon size={size || DEFAULT_SIZE} color={color?.toString() || DEFAULT_COLOR} path="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/>

export const User: React.FC<IconProps> = ({ size, color }) => 
    <Icon size={size || DEFAULT_SIZE} color={color?.toString() || DEFAULT_COLOR} path="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>

export const People: React.FC<IconProps> = ({ size, color }) => 
    <Icon size={size || DEFAULT_SIZE} color={color?.toString() || DEFAULT_COLOR} path="M9 13.75c-2.34 0-7 1.17-7 3.5V19h14v-1.75c0-2.33-4.66-3.5-7-3.5zM4.34 17c.84-.58 2.87-1.25 4.66-1.25s3.82.67 4.66 1.25H4.34zM9 12c1.93 0 3.5-1.57 3.5-3.5S10.93 5 9 5 5.5 6.57 5.5 8.5 7.07 12 9 12zm0-5c.83 0 1.5.67 1.5 1.5S9.83 10 9 10s-1.5-.67-1.5-1.5S8.17 7 9 7zm7.04 6.81c1.16.84 1.96 1.96 1.96 3.44V19h4v-1.75c0-2.02-3.5-3.17-5.96-3.44zM15 12c1.93 0 3.5-1.57 3.5-3.5S16.93 5 15 5c-.54 0-1.04.13-1.5.35.63.89 1 1.98 1 3.15s-.37 2.26-1 3.15c.46.22.96.35 1.5.35z"/>

export const DragHandle: React.FC<IconProps> = ({ size, color }) => 
    <Icon size={size || 26} color={color?.toString() || DEFAULT_COLOR} path="M9,3H11V5H9V3M13,3H15V5H13V3M9,7H11V9H9V7M13,7H15V9H13V7M9,11H11V13H9V11M13,11H15V13H13V11M9,15H11V17H9V15M13,15H15V17H13V15M9,19H11V21H9V19M13,19H15V21H13V19Z"/>

export const Menu: React.FC<IconProps> = ({ size, color }) => 
    <Icon size={size || DEFAULT_SIZE} color={color?.toString() || DEFAULT_COLOR} path="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>

export const MoreH: React.FC<IconProps> = ({ size, color }) => 
    <Icon size={size || DEFAULT_SIZE} color={color?.toString() || DEFAULT_COLOR} path="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>

export const DeleteForever: React.FC<IconProps> = ({ size, color }) => 
    <svg xmlns="http://www.w3.org/2000/svg" height={size || DEFAULT_SIZE} fill={color?.toString() || DEFAULT_COLOR} viewBox="0 96 960 960" width={size || DEFAULT_SIZE}>
        <path d="m361 757 119-121 120 121 47-48-119-121 119-121-47-48-120 121-119-121-48 48 120 121-120 121 48 48ZM261 936q-24 0-42-18t-18-42V306h-41v-60h188v-30h264v30h188v60h-41v570q0 24-18 42t-42 18H261Zm438-630H261v570h438V306Zm-438 0v570-570Z"/>
    </svg>

export const Admin: React.FC<IconProps> = ({ size, color }) => 
    <svg xmlns="http://www.w3.org/2000/svg" height={size || DEFAULT_SIZE} fill={color?.toString() || DEFAULT_COLOR} viewBox="0 96 960 960" width={size || DEFAULT_SIZE}>
        <path d="M690.882 786q25.883 0 44-19Q753 748 753 722.118q0-25.883-18.118-44-18.117-18.118-44-18.118Q665 660 646 678.118q-19 18.117-19 44Q627 748 646 767q19 19 44.882 19ZM689.5 911q33.5 0 60.5-14t46-40q-26-14-51.962-21-25.961-7-54-7-28.038 0-54.538 7-26.5 7-51.5 21 19 26 45.5 40t60 14ZM480 976q-138-32-229-156.5T160 534V295l320-120 320 120v270q-14-7-30-12.5t-30-7.5V337l-260-96-260 96v197q0 76 24.5 140T307 786.5q38 48.5 84 80.5t89 46q6 12 18 27t20 23q-9 5-19 7.5t-19 5.5Zm212.5 0Q615 976 560 920.5T505 789q0-78.435 54.99-133.718Q614.98 600 693 600q77 0 132.5 55.282Q881 710.565 881 789q0 76-55.5 131.5t-133 55.5ZM480 577Z"/>
    </svg>

export const TrendingUp: React.FC<IconProps> = ({ size, color}) =>
    <svg xmlns="http://www.w3.org/2000/svg" height={size || DEFAULT_SIZE} fill={color?.toString() || DEFAULT_COLOR} viewBox="0 -960 960 960" width={size || DEFAULT_SIZE}>
        <path d="m123-240-43-43 292-291 167 167 241-241H653v-60h227v227h-59v-123L538-321 371-488 123-240Z"/>
    </svg>

export const TrendingDown: React.FC<IconProps> = ({ size, color}) =>
    <svg xmlns="http://www.w3.org/2000/svg" height={size || DEFAULT_SIZE} fill={color?.toString() || DEFAULT_COLOR} viewBox="0 -960 960 960" width={size || DEFAULT_SIZE}>
        <path d="M653-240v-60h127L539-541 372-374 80-665l43-43 248 248 167-167 283 283v-123h59v227H653Z"/>
    </svg>

export const Download: React.FC<IconProps> = ({ size, color}) =>
    <svg xmlns="http://www.w3.org/2000/svg" height={size || DEFAULT_SIZE} fill={color?.toString() || DEFAULT_COLOR} viewBox="0 -960 960 960" width={size || DEFAULT_SIZE}>
        <path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z" />
    </svg>

export const Refresh: React.FC<IconProps> = ({ size, color}) =>
    <svg xmlns="http://www.w3.org/2000/svg" height={size || DEFAULT_SIZE} fill={color?.toString() || DEFAULT_COLOR} viewBox="0 -960 960 960" width={size || DEFAULT_SIZE}>
        <path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z"/>
    </svg>