import { routes } from "@config/routes";
import style from "./style.module.css";
import { RouterLink } from "@common/components/elements/link";

const Notfound = () => {
    return (
        <div className={style.notfound}>
            <h1>Error 404 - Not found</h1>
            <p>That page doesn&apos;t exist.</p>
            <RouterLink to={routes.home}>
                <h4>Back to Home</h4>
            </RouterLink>
        </div>
    );
};

export default Notfound;
