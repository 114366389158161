import { Redirect } from 'react-router-dom';
import { isLoggedIn } from "@common/services/api";

interface IProps {
	signinUrl: string,
	children: JSX.Element
}
export function Secure({ signinUrl, children }: IProps) {
	if (!isLoggedIn()) {
		return (<Redirect to={signinUrl} />);
	} else {
		return children;
	}
}

export default Secure;