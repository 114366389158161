
import MuiTextField from "@mui/material/TextField";
import { IVErrorsKind, messageFor } from '@common/models/validation';
import { SkeletonTextbox } from '@common/components/elements/skeleton';

interface IProps {
    id: string,
    value: string,
    label: string,
    placeholder?: string,
    onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined,
    onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined,
    onKeyDown?: React.KeyboardEventHandler<HTMLDivElement> | undefined,
    errorMessage?: IVErrorsKind | null,
    isLoading?: boolean,
    readonly?: boolean,
    disabled?: boolean,
    className?: string,
    autoCapitalize?: "off" | "characters" | "words" | "sentences" | undefined,
    variant?: "filled" | "outlined" | "standard" | undefined,
    style?: React.CSSProperties,
    hiddenLabel?: boolean,
    rightLabel?: string | undefined,
    multiline?: boolean,
    rows?: number,
    minRows?: number,
    maxRows?: number,
}

function wrapWithRightLabel(rightLabel: string, el: React.ReactNode): React.ReactNode {
    if (rightLabel != null) {
        return (
            <div style={{ position: "relative", display: "flex", flexGrow: 1, }}>
                <div style={{
                    fontSize: ".75rem",
                    position: "absolute",
                    color: "rgba(255, 255, 255, 0.7)",
                    top: "1.4rem",
                    right: ".75rem",
                }}>{rightLabel}</div>
                {el}
            </div>
        )
    } else {
        return el;
    }
}

export const TextField = ({ 
        id, value, hiddenLabel = false, variant = "filled", label, rightLabel = "", 
        placeholder = "", isLoading, onKeyDown, onChange, errorMessage: validationError = null, 
        readonly = false, onBlur = undefined, className = undefined, disabled = undefined, 
        autoCapitalize = "off", multiline = false, rows = undefined, minRows = undefined, maxRows = undefined
    }: IProps) => {

    const invalidMessage = messageFor(validationError);
    const invalid = invalidMessage !== "";

    return (
        <>
        {isLoading && 
            <SkeletonTextbox fullwidth />
        }
        {!isLoading && !hiddenLabel &&
            wrapWithRightLabel(rightLabel, (
                <MuiTextField 
                    id={id}
                    margin="normal"
                    fullWidth={true}
                    variant={variant}
                    type="text"
                    label={label}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    error={invalid}
                    helperText={invalidMessage}
                    disabled={disabled}
                    InputProps={{
                        readOnly: readonly,
                        autoCapitalize: autoCapitalize
                    }}
                    className={className}
                    multiline={multiline}
                    rows={rows}
                    minRows={minRows}
                    maxRows={maxRows}
                />
            ))
        }
        {!isLoading && hiddenLabel &&
            <MuiTextField 
                hiddenLabel
                id={id}
                margin="normal"
                fullWidth={true}
                variant={variant}
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                error={invalid}
                helperText={invalidMessage}
                disabled={disabled}
                InputProps={{
                    readOnly: readonly,
                    autoCapitalize: autoCapitalize
                }}
                className={className}
                size="medium"
                multiline={multiline}
                rows={rows}
                minRows={minRows}
                maxRows={maxRows}
            />
        }
        </>
    );
};

export default TextField;
