import { Secure as SecureCommon } from "@common/components/security/secure";

interface ISecureProps {
	children: JSX.Element
}
function Secure({ children }: ISecureProps) {
	return (
		<SecureCommon signinUrl="/signin">
			{children}
		</SecureCommon>
	);
}

export default Secure;