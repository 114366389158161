import * as React from "react";
import { RejectReason } from "@common/models";
import { IVErrorsKind } from "@common/models/validation";
import { notificationService } from "@common/services/notification";
import { uuidService } from "@common/services/uuid";
import { ITodoList } from "@todos/models";
import { api } from "@todos/services/api";
import ListForm, { newList } from "../listForm";
import { routes } from "@config/routes";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Breadcrumb } from "@common/components/elements/breadcrumb";
import { RightHeaderMenu } from "@common/components/elements/rightHeaderToolbar";
import { IconButton } from "@common/components/forms/iconButton";
import { DeleteForever, Menu } from "@common/components/icons";
import { Drawer } from "@common/components/elements/drawer";
import { List, ListButtonFn } from "@common/components/elements/list";
import { Modal, ModalAction } from "@common/components/elements/modal";

interface IOwnProps {
    id: string,
}

type IProps = RouteComponentProps<IOwnProps>;

interface IState {
    list: ITodoList,
    validationErrors?: IVErrorsKind,
    isLoading: boolean,
    isSubmitting: boolean,
    drawerOpen: boolean,
    isPromptDeleteForever: boolean,
}

class ListEdit extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: true,
            isSubmitting: false,
            list: newList(),
            drawerOpen: false,
            isPromptDeleteForever: false,
        };
    }

    override componentDidMount = () => {
        const { match } = this.props;

        api.getTodoList(match.params.id)
            .then((data: ITodoList | RejectReason) => {
                this.setState({ list: data as ITodoList });
            })
            .catch((err: RejectReason) => {
                const validationErrors = notificationService.rejected(err);
                if (validationErrors) {
                    this.setState({ validationErrors });
                }
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    };

    postList = (changed: ITodoList) => {
        if (!changed.list_id || changed.list_id.length < uuidService.NIL_LENGTH) {
            changed.list_id = uuidService.NIL;
        }

        this.setState({ isSubmitting: true }, () => {
            api.postTodoList(changed)
                .then((data: ITodoList | RejectReason) => {
                    this.setState({ list: data as ITodoList });
                })
                .catch((err: RejectReason) => {
                    const validationErrors = notificationService.rejected(err);
                    if (validationErrors) {
                        this.setState({ validationErrors });
                    }
                })
                .finally(() => {
                    this.setState({ isSubmitting: false });
                });
        });
    };

    cancel = () => {
        const { history } = this.props;
        history.goBack();
    }

    setDrawerOpen = (open: boolean) => {
        this.setState({ drawerOpen: open });
    }

    promptDeleteForever = () => {
        this.setState({
            isPromptDeleteForever: true,
            drawerOpen: false,
        })
    }

    confirmDeleteForever = () => {
        const { list } = this.state;

        this.setState({ isLoading: true }, () => {
            api.deleteTodoList(list.list_id)
                .then(() => {
                    this.cancel();
                })
                .finally(() => {
                    this.setState({
                        isPromptDeleteForever: false,
                    })
                });
        });
    }

    cancelDeleteForever = () => {
        this.setState({
            isPromptDeleteForever: false,
        })
    }

    override render = (): React.ReactNode => {
        const { list, isSubmitting, isLoading, validationErrors, drawerOpen, isPromptDeleteForever } = this.state;

        return (
            <>
                <RightHeaderMenu>
                    <IconButton 
                        size="large" 
                        disabled={isLoading} 
                        icon={<Menu size={24} />}
                        onClick={() => this.setDrawerOpen(true)} 
                    />
                </RightHeaderMenu>
                <Breadcrumb path={[
                    { href: routes.home, title: "Home" },
                    { href: routes.todos.lists, title: "Lists" },
                    { href: "", title: list.description },
                ]} isLoading={isLoading} />
                <ListForm 
                    label="Edit list details" 
                    list={list} 
                    submit={this.postList} 
                    isSubmitting={isSubmitting} 
                    validationErrors={validationErrors} 
                    cancel={this.cancel}
                    isLoading={isLoading}
                />
                <Modal 
                    open={isPromptDeleteForever} 
                    heading={"Permanently delete the entire list?"}
                    primaryText={"Delete Forever"} 
                    danger={true}
                    secondaryText={"Cancel"} 
                    requestClose={(action: ModalAction) => {
                        switch (action) {
                            case "primary": {
                                this.confirmDeleteForever();
                                break;
                            }
                            case "secondary":
                            default: {
                                this.cancelDeleteForever();
                                break;
                            }
                        }
                        return Promise.resolve();
                    }}>
                </Modal>
                <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={() => this.setDrawerOpen(false)}
                >
                    <List>
                        {ListButtonFn("DeleteForever", "Delete entire list", <DeleteForever size={24} />, this.promptDeleteForever)}
                    </List>
                </Drawer>
            </>
        );
    }
}

export default withRouter(ListEdit);
