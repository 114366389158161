import MuiLoadingButton from "@mui/lab/LoadingButton";
// import style from "./style.module.css";


interface IProps {
    id?: string,
    className?: string,
    label: string,
    variant?: "contained" | "outlined" | "text",
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning",
    isLoading?: boolean,
    onClick?: React.MouseEventHandler<HTMLButtonElement>,
    disabled?: boolean,
    size?: "small" | "medium" | "large",
}

export const Button = ({ id, className, label, variant, onClick, isLoading, color, disabled, size = "medium" }: IProps) => {
    return (
        <MuiLoadingButton id={id} className={className} loading={isLoading} color={color || "primary"} size={size} variant={variant} disabled={disabled} onClick={onClick}>
            {label}
        </MuiLoadingButton>
    );
}

export default Button;
