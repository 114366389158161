import { apiBase } from "@config/settings";
import { apiGet, apiPost, expectJson } from "@common/services/api";
import { RejectReason } from "@common/models";

const SERVER_PATH = `${apiBase}api/v0/trade/`;

/**
 * Array<[
 *   exchange: string,
 *   symbol: string,
 *   qty: number,
 *   invested: number,
 *   value?: number | null,
 *   value_date?: string | null,
 * ]>
 */
export type SummaryRow = [string, string, string, string, string | null, string | null];

export interface ISummary {
  data: Array<SummaryRow>;
}


/**
 * Api endpoints
 */
 export const api = {
    /**
     * GET the list of holdings by the current user
     * @returns Promise<Array<ITodoIndexRow | RejectReason>>
     */
    getHoldings: () => apiGet(SERVER_PATH + "holdings")
        .then<ISummary | RejectReason>(resp => expectJson(resp, "Failed to fetch holdings.")),

    /**
     * POST 
     * @returns Promise<Array<ITodoIndexRow | RejectReason>>
     */
     postHoldings: () => apiPost(SERVER_PATH + "holdings")
        .then<ISummary | RejectReason>(resp => expectJson(resp, "Failed to refresh and fetch holdings.")),
};