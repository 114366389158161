import { routes } from "@config/routes";
import { RejectReason } from "../models";

export function isLoggedIn() {
    return !!localStorage.getItem("auth-token");
}

export function setToken(token: string) {
    localStorage.setItem("auth-token", token);
}

export function clearToken() {
    localStorage.removeItem("auth-token");
}

/**
 * Add the authorization header (if missing).
 * @param headers existing array of headers
 * @param noop when true, do not add the auth header
 * @returns the modified array of headers
 */
export function authorize(headers: Array<[string, string]>, noop: boolean = false) : Array<[string, string]> {
    if (noop) {
        return headers;
    }
    const auth = headers.find(p => p[0] === "Authorization");
    
    if (!auth) {
        const token = localStorage.getItem("auth-token") || "";
        if (token !== "") {
            headers.push(["Authorization", "Bearer " + token]);
        }
    }
        
    return headers;
}

/**
 * Url encode the object to a string.
 * @param data the object to pivot
 * @returns url encoded string with each object property as a parameter
 */
 export function objectToParams(data: any = {}): string {
    let params = "";
    if (data) {
        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                const value = data[key];
                if (params.length === 0) {
                    params += "?";
                } else {
                    params += "&";
                }
                params += encodeURIComponent(key) + "=" + encodeURIComponent(value);
            }
        }
    }

    return params;
}

/**
 * Execute a GET request to the url, encode the data as url parameters.
 * @param url the url to access
 * @param data an object whose properties will be encoded as parameters
 * @returns Promise<Response>`
 */
 export function apiGet(url: string, data: any = {}): Promise<Response> {
    return fetch(new Request(
        url + objectToParams(data), {
            method: "GET",
            headers: authorize([
                ["Accept", "application/json"],
            ])
        }
    ));
}

/**
 * Execute a DELETE request to the url, encode the data as url parameters.
 * @param url the url to access
 * @param data the data object whose properties will be encoded as parameters
 * @returns Promise<Response>
 */
 export function apiDelete(url: string, data: any = {}): Promise<Response> {
    return fetch(new Request(
        url + objectToParams(data), {
            method: "DELETE",
            headers: authorize([
                ["Accept", "application/json"]
            ])
        }
    ));
}

/**
 * Execute a POST request to the url, send data as a JSON content body.
 * @param url the url to access
 * @param data the data object to be serialized and sent
 * @returns Promise<Response>
 */
 export function apiPost(url: string, data: any = {}, withAuth: boolean = true): Promise<Response> {
    return fetch(new Request(
        url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: authorize([
                ["Accept", "application/json"],
                ["Content-Type", "application/json"],
            ], !withAuth),
        }
    ));
}

export function reject(resp: any, message: string): Promise<RejectReason> {
    if (resp.status === 401 || resp.status === 403) {
        clearToken();
        window.location.replace(routes.auth.signinAbsolute);
        return Promise.reject("Unauthorized.");
    }

    return resp.json().then((data: any) => {
        let res;
        if (data.error !== undefined) {
            if (typeof data.error === "string") {
                res = ["err", data.error || message || "An error occurred."];
            } else {
                res = ["validation", data.error]
            }
        } else {
            res = ["err", message || "An error occurred."];
        }
    
        return Promise.reject(res);

    }).catch((err: any) => {
        return Promise.reject(Array.isArray(err) 
            ? err 
            : ["err", message || "An error occurred."]);
    });    
}

/**
 * Interpret the response as json. Reject otherwise with error from the server, fallback to otherwiseMessage.
 * @param resp the fetch response
 * @param otherwiseMessage fallback error message
 * @returns 
 */
 export function expectEmpty(resp: any, otherwiseMessage: string): Promise<void> | Promise<RejectReason> {
    if (resp.ok) {
        return Promise.resolve();
    }

    return reject(resp, otherwiseMessage);
}

/**
 * Interpret the response as json. Reject otherwise with error from the server, fallback to otherwiseMessage.
 * @param resp the fetch response
 * @param otherwiseMessage fallback error message
 * @returns 
 */
 export function expectJson<T>(resp: any, otherwiseMessage: string): Promise<T> | Promise<RejectReason> {
    if (resp.ok) {
        return resp.json();
    }

    return reject(resp, otherwiseMessage);
}

/**
 * Interpret the response as text. Reject otherwise with error from the server, fallback to otherwiseMessage.
 * @param resp the fetch response
 * @param otherwiseMessage fallback error message
 * @returns 
 */
 export function expectText(resp: any, otherwiseMessage: string): Promise<any> | Promise<RejectReason> {
    if (resp.ok) {
        return resp.text().then((p: string) => {
            if (typeof p === "string" && p.length > 0) {
                return p.substring(1, p.length - 1);
            }
            return "";
        });
    }

    return reject(resp, otherwiseMessage);
}
