import * as React from "react";
import css from "./style.module.css";

interface IProps {
    style?: React.CSSProperties,
    align?: "left" | "right" | "center",
}

export const ButtonStrip: React.FC<React.PropsWithChildren<IProps>> = ({ children, align = "left", style }) => {
    let st = { textAlign: align };
    if (style)
        st = Object.assign(st, style);

    return (
        <div className={css.buttonstrip} style={st}>
            {children}
        </div>
    );
};

export default ButtonStrip;
