import { SkeletonText } from '@common/components/elements/skeleton';
import MuiTextField from '@mui/material/TextField';
import { IVErrorsKind, messageFor } from '../../../models/validation';

interface IProps {
    id: string,
    value: string,
    label: string,
    placeholder?: string,
    onChange?: React.ChangeEventHandler<HTMLInputElement>,
    onBlur?: React.FocusEventHandler<HTMLInputElement>,
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>,
    errorMessage?: IVErrorsKind | null,
    variant?: "filled",
    isLoading?: boolean,
    disabled?: boolean,
    readonly?: boolean,
    className?: string,
    autoCapitalize?: "off" | "characters" | "words" | "sentences",
}

export const PasswordField = ({ id, value, label, placeholder = "", isLoading, onKeyDown, onChange, errorMessage: validationError = null, readonly = false, onBlur = undefined, className = undefined, disabled = undefined, autoCapitalize = "off" }: IProps) => {
    const invalidMessage = messageFor(validationError)
    const invalid = invalidMessage !== "";

    return (
        <>
        {isLoading && 
            <SkeletonText />
        }
        {!isLoading && 
            <MuiTextField
                id={id} 
                margin="normal"
                fullWidth={true}
                variant="filled"
                type={"password"}
                label={label} 
                placeholder={placeholder} 
                value={value} 
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                error={invalid}
                helperText={invalidMessage}
                disabled={disabled}
                InputProps={{
                    readOnly: readonly,
                    autoCapitalize: autoCapitalize
                }}
                className={className}
            />
        }
        </>
    );
};
