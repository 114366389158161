import * as React from "react";
import ReactDOM from "react-dom";
import { APPBAR_MENU_END } from "@auth/components/header";

interface IProps {
    hidden?: boolean,
}

export const RightHeaderMenu: React.FC<React.PropsWithChildren<IProps>> = ({ hidden = false, children }) => {
    if (hidden || !children)
        return null;

    const container = document.getElementById(APPBAR_MENU_END);
    return container && ReactDOM.createPortal(children, container);
}