import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { RejectReason } from "@common/models";
import { notificationService } from "@common/services/notification";
import { Button } from "@common/components/forms/button";
import { api, IIPAddressResult } from "src/ipecho/services/api";
import { Breadcrumb } from "@common/components/elements/breadcrumb";
import { TextField } from "@common/components/forms/textField";
import { routes } from "@config/routes";
import { ButtonStrip } from "@common/components/forms/buttonStrip";
import { Form } from "@common/components/forms/form";
// import style from "./style.module.css";

interface IProps {
}

interface IState {
    isLoading: boolean,
    isSubmitting: boolean,
    ip: IIPAddressResult | null,
}

class IPEcho extends React.Component<RouteComponentProps<IProps>, IState> {
    constructor(props: RouteComponentProps<IProps>) {
        super(props);

        this.state = {
            ip: null,
            isLoading: true,
            isSubmitting: false,
        }
    }

    override componentDidMount = () => {
        this.getIP();
    }

    getIP= () => api.getIPAddress()
        .then(ip => {
            this.setState({ 
                ip: ip as IIPAddressResult,
                isLoading: false 
            });
        })
        .catch((rejected: RejectReason) => {
            this.setState({ 
                ip: null, 
                isLoading: false 
            });
            notificationService.rejected(rejected);
        });

    onSubmit = (): Promise<void> | void => {
        this.setState({ isSubmitting: true });
        return this.getIP()
            .finally(() => { 
                this.setState({ isSubmitting: false })
            });
    }

    override render = () => {
        const { ip, isLoading, isSubmitting } = this.state;
        const title = "IP Echo";

        return (
            <div>
                <Breadcrumb path={[
                    { href: routes.home, title: "Home" },
                    { href: routes.ipecho, title },
                ]} />
                
                <Form id="ip-form" label="IP Echo">
                    <TextField id="ipv4" 
                        label="IP v4" 
                        value={ip?.ip_v4 || ""} 
                        isLoading={isLoading}
                        readonly={true}
                        />
                    <TextField id="ipv6" 
                        label="IP v6" 
                        value={ip?.ip_v6 || ""} 
                        isLoading={isLoading}
                        readonly={true}
                        />
                    <ButtonStrip>
                        <Button key="submit" label="Echo my IP" onClick={this.onSubmit} isLoading={isSubmitting}></Button>
                    </ButtonStrip>
                </Form>
            </div>
        );
    }
};

export default withRouter(IPEcho);
