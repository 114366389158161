import * as React from "react";
import { SubTitle } from "../../elements/subtitle";
import style from "./style.module.css";

interface IProps {
    label?: string,
    overview?: JSX.Element,
}

export const FormGroup: React.FC<React.PropsWithChildren<IProps>> = ({ label, overview, children }) => {
    return (
        <div className={`form-group ${style.group}`}>
            {label && 
                <SubTitle text={label} />
            }
            {overview && 
                <div className={style.overview}>{overview}</div>
            }
            {children}
        </div>
    );
}

export default FormGroup;
