import * as React from "react";
import * as ReactDOM from "react-dom";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { estimatorDataFactory } from "@config/settings";
import { Button } from "@common/components/forms/button";
import { ButtonStrip } from "@common/components/forms/buttonStrip";
import { Checkbox} from "@common/components/forms/checkbox";
import { Form } from "@common/components/forms/form";
import { FormGroup } from "@common/components/forms/formGroup";
import { TextField } from "@common/components/forms/textField";
import { Modal, ModalAction } from "@common/components/elements/modal";
import { Breadcrumb } from "@common/components/elements/breadcrumb";
import { notificationService } from "@common/services/notification";
import { idResponse, RejectReason, Uuid } from "@common/models";
import { errorFor, IVErrorsKind } from "@common/models/validation";
import { Add, ChevronRight, ClearX, NorthEast, Options, SouthEast, SubArrowLeft, SubArrowRight } from "@common/components/icons";
import { IModifier, INode, IEstimate, ModifierType, NodeGridOptions, TempId } from "@estimator/models";
import { api } from "@estimator/services/api";
import { routes } from "@config/routes";

import style from "./style.module.css";
import { SkeletonDataTable } from "@common/components/elements/skeleton";

const ROOT_TITLE: string = "rootTitle";

enum NavResult {
    Impossible,
    OK,
}

enum NavRowType {
    Node = 0, 
    Modifier = 1,
}

enum NavDirection {
    Up,
    Down,
    Left,
    Right,
}

interface INavAttrs {
    columnIndex: number,
    type: NavRowType,
    tempId: number,
    collapsed: boolean,
}

/** UI operations */
class UI {
    static FIRST_FIELD_INDEX = 0;
    static LAST_FIELD_INDEX = 2;

    static FIRST_MODIFIER_FIELD_INDEX = 0;
    static LAST_MODIFIER_FIELD_INDEX = 2;

    /**
     * Extract the attributes from the specified html element.
     * @param el the element to extract the attributes from
     */
    private static navAttrs(el: HTMLElement): INavAttrs {
        try {
            return {
                columnIndex: parseInt(el.dataset["r_col"] || "0"),
                type: parseInt(el.dataset["r_type"] || "0") || NavRowType.Node,
                tempId: parseInt(el.dataset["r_ref"] || "0"),
                collapsed: !!el.dataset["r_collapsed"],
            }
        } catch (error) {
            console.log(el);
            throw error;
        }
    }

    /**
     * Select all the text of the html input
     * @param el the html input element
     */
    static selectAll(el: HTMLInputElement) {
        el &&  el.setSelectionRange(0, el.value.length, "forward");
    }

    /**
     * Returns the fieldId for tempId + fieldIndex
     */
    static fieldId(tempId: number, fieldIndex: number): string {
        return `${tempId}_${fieldIndex}`;
    }

    /**
     * Try set focus to the tempId + field index
     * @param tempId the tempId of the root/node/modifier
     * @param fieldIndex the current field index in the row
     * @returns True, if the focus could be set. False otherwise.
     */
    static tryFocusElement(el: HTMLInputElement): boolean {
        if (el && !el.disabled) {
            el.focus();
            return true;
        }

        return false;
    }

    /**
     * Try set focus to the tempId + field index
     * @param tempId the tempId of the root/node/modifier
     * @param fieldIndex the current field index in the row
     * @returns True, if the focus could be set. False otherwise.
     */
    static tryFocusFieldId(id: string): boolean {
        return UI.tryFocusElement(document.getElementById(id) as HTMLInputElement);
    }

    /**
     * Try set focus to the tempId + field index
     * @param tempId the tempId of the root/node/modifier
     * @param fieldIndex the current field index in the row
     * @returns True, if the focus could be set. False otherwise.
     */
    static tryFocusField(tempId: number, fieldIndex: number): boolean {
        return UI.tryFocusFieldId(UI.fieldId(tempId, fieldIndex));
    }

    /**
     * Navigate up one row. Preserve column index.
     * @param root the root object
     * @param from the html element we are moving away from
     */
    private static NavigateUp(root: IEstimate, from: HTMLInputElement | null): NavResult {
        if (!from) {
            return NavResult.Impossible;
        }

        const attrs = UI.navAttrs(from);
        return UI.NavigateUpImpl(root, NavDirection.Up, attrs.tempId, attrs.columnIndex);
    }

    /**
     * Navigate left one field with wrap to previous row.
     * @param root the root object
     * @param from the html element we are moving away from
     */
    private static NavigateLeft(root: IEstimate, from: HTMLInputElement): NavResult {
        if (!from) {
            return NavResult.Impossible;
        }

        // find next left
        const attrs = UI.navAttrs(from);
        if (attrs.columnIndex > UI.FIRST_FIELD_INDEX) {
            return UI.NavigateTo(root, NavDirection.Left, attrs.tempId, attrs.columnIndex - 1);
        }

        return UI.NavigateUpImpl(root, NavDirection.Left, attrs.tempId, attrs.columnIndex);
    }

    /**
     * Implementation to move up to the previous row
     * @param root the root object
     * @param travelling the direct to travel
     * @param fromTempId moving away from tempId
     * @param fromColumnIndex moving away from columnIndex
     */
    private static NavigateUpImpl(root: IEstimate, travelling: NavDirection, fromTempId: number, fromColumnIndex: number) {
        const el = document.getElementById(UI.fieldId(fromTempId, fromColumnIndex));
        if (!el) {
            return NavResult.Impossible;
        }

        const attrs = UI.navAttrs(el);
        const targetColumnIndex: number = travelling == NavDirection.Up
            ? fromColumnIndex
            : (attrs.type === NavRowType.Node) 
                ? UI.LAST_FIELD_INDEX
                : UI.LAST_MODIFIER_FIELD_INDEX;

        if (attrs.type === NavRowType.Node) {
            const refChain: Array<INode> = [];
            Root.findPath(refChain, root, fromTempId);

            if (refChain.length <= 1) { 
                return NavResult.Impossible; 
            }

            // if we hit the start, move up a row
            // Node navigation
            const current = refChain[refChain.length - 1];
            const parent = refChain[refChain.length - 2];
            const currentIndex = parent.nodes.findIndex(p => p.tempId === current.tempId);

            if (currentIndex === 0) {
                return this.NavigateTo(root, travelling, parent.tempId, targetColumnIndex);
            } else {
                return UI.NavigateDeepestExpanded(root, travelling, parent.nodes[currentIndex - 1], targetColumnIndex);
            }
        } else {
            const currentIndex = root.options.modifiers.findIndex(p => p.tempId === fromTempId);
            if (currentIndex === -1) {
                return NavResult.Impossible;

            } else if (currentIndex === 0) {
                if (root.nodes.length === 0) {
                    return NavResult.Impossible;
                }

                const tryNode = root.nodes[root.nodes.length - 1];
                const tryEl = document.getElementById(UI.fieldId(tryNode.tempId, fromColumnIndex)) as HTMLInputElement;
                if (!tryEl) {
                    return NavResult.Impossible;
                }

                const tryAttrs = UI.navAttrs(tryEl);
                
                if (tryNode.nodes.length === 0 || tryAttrs.collapsed) {
                    return this.NavigateTo(root, travelling, tryAttrs.tempId, targetColumnIndex);
                } else {
                    return UI.NavigateDeepestExpanded(root, travelling, tryNode.nodes[tryNode.nodes.length - 1], targetColumnIndex);
                }
            }

            const candidateId = root.options.modifiers[currentIndex - 1].tempId;
            return this.NavigateTo(root, travelling, candidateId, targetColumnIndex);
        }
    }

    /**
     * Navigate to the deepest expanded node
     * @param root the root object
     * @param travelling the direct being moved
     * @param node the target node
     * @param targetColumnIndex the target column index
     */
    private static NavigateDeepestExpanded(root: IEstimate, travelling: NavDirection, node: INode, targetColumnIndex: number): NavResult {
        const tryId = UI.fieldId(node.tempId, targetColumnIndex);
        const tryEl = document.getElementById(tryId) as HTMLInputElement;
        const tryAttrs = UI.navAttrs(tryEl);

        if (node.nodes.length === 0 || tryAttrs.collapsed) {
            return this.NavigateTo(root, travelling, tryAttrs.tempId, targetColumnIndex);
        } else {
            return UI.NavigateDeepestExpanded(root, travelling, node.nodes[node.nodes.length - 1], targetColumnIndex);
        }
    }

    /**
     * Navigate right one column with wrapping.
     * @param root the root object
     * @param from navigating from html element
     */
    private static NavigateRight(root: IEstimate, from: HTMLInputElement): NavResult {
        if (!from) {
            return NavResult.Impossible;
        }

        // find next right
        const attrs = UI.navAttrs(from);

        if (attrs.type === NavRowType.Node) {
            if (attrs.columnIndex < UI.LAST_FIELD_INDEX) {
                return UI.NavigateTo(root, NavDirection.Right, attrs.tempId, attrs.columnIndex + 1);
            }

            return UI.NavigateDownImpl(root, NavDirection.Right, attrs.tempId, attrs.columnIndex);
        } else {
            if (attrs.columnIndex < UI.LAST_MODIFIER_FIELD_INDEX) {
                return UI.NavigateTo(root, NavDirection.Right, attrs.tempId, attrs.columnIndex + 1);
            }

            return UI.NavigateDownImpl(root, NavDirection.Right, attrs.tempId, attrs.columnIndex);
        }
    }

    /**
     * Navigate down from the html element
     * @param root the root object
     * @param from moving from html element
     */
    private static NavigateDown(root: IEstimate, from: HTMLInputElement): NavResult {
        if (!from) {
            return NavResult.Impossible;
        }

        const attrs = UI.navAttrs(from);
        return UI.NavigateDownImpl(root, NavDirection.Down, attrs.tempId, attrs.columnIndex);
    }

    /**
     * The implementation for navigate down one row
     * @param root the root object
     * @param travelling the direct being moved 
     * @param fromTempId the temp id to move from
     * @param fromColumnIndex the column index to move from
     */
    private static NavigateDownImpl(root: IEstimate, travelling: NavDirection, fromTempId: number, fromColumnIndex: number) {
        const el = document.getElementById(UI.fieldId(fromTempId, fromColumnIndex));
        if (!el) {
            return NavResult.Impossible;
        }

        const attrs = UI.navAttrs(el);
        const targetColumnIndex: number = travelling == NavDirection.Down
            ? fromColumnIndex
            : (attrs.type === NavRowType.Node) 
                ? UI.FIRST_FIELD_INDEX
                : UI.FIRST_MODIFIER_FIELD_INDEX;

        if (attrs.type === NavRowType.Node) {
            const refChain: Array<INode> = [];
            Root.findPath(refChain, root, fromTempId);

            if (refChain.length <= 1) { 
                return NavResult.Impossible; 
            }

            // if we hit the end move down a row
            // Node navigation
            const current = refChain[refChain.length - 1] as INode;
            const parent = refChain[refChain.length - 2] as INode;
            const currentIndex = parent.nodes.findIndex(p => p.tempId === current.tempId);

            if (attrs.collapsed || current.nodes.length === 0) {
                if (currentIndex < parent.nodes.length - 1) {
                    const candidateId = parent.nodes[currentIndex + 1].tempId;
                    return this.NavigateTo(root, travelling, candidateId, targetColumnIndex);
                }

                for (let i = refChain.length - 1; i >= 0; i--) {
                    const childIndex = refChain[i].nodes.findIndex(p => p.tempId === refChain[i + 1].tempId);
                    if (childIndex !== -1 && childIndex !== refChain[i].nodes.length - 1) {
                        const candidateId = refChain[i].nodes[childIndex + 1].tempId;
                        return UI.NavigateTo(root, travelling, candidateId, targetColumnIndex);
                    }
                }
            } else {
                const candidateId = current.nodes[0].tempId;
                if (this.NavigateTo(root, travelling, candidateId, targetColumnIndex) !== NavResult.Impossible)
                    return NavResult.OK;
            }

            return UI.TryNavigateToModifiers(root, travelling, refChain[1].tempId, targetColumnIndex);

        } else if (attrs.type === NavRowType.Modifier) {
            const currentIndex = root.options.modifiers.findIndex(p => p.tempId === fromTempId);
            if (currentIndex === -1 || currentIndex === root.options.modifiers.length - 1) {
                return NavResult.Impossible;
            }

            const candidateId = root.options.modifiers[currentIndex + 1].tempId;
            return this.NavigateTo(root, travelling, candidateId, targetColumnIndex);
        }

        return NavResult.Impossible;
    }

    /**
     * Navigate to the modifiers from the nodes.
     * @param root the root object
     * @param travelling the direction of navigation
     * @param fromTempId the tempId moving away from
     * @param targetColumnIndex the target column index
     */
    private static TryNavigateToModifiers(root: IEstimate, travelling: NavDirection, fromTempId: number, targetColumnIndex: number) {
        const rootIndex = root.nodes.findIndex(p => p.tempId === fromTempId);
        if (rootIndex === root.nodes.length - 1 && root?.options?.modifiers?.length > 0) {
            const candidateId = root.options.modifiers[0].tempId;
            return this.NavigateTo(root, travelling, candidateId, targetColumnIndex);
        }

        return NavResult.Impossible;
    }

    /**
     * Navigate to the specified target. If navigation fails, continue navigating in the travelling direction.
     * @param root the root object
     * @param travelling the direction of navigation
     * @param targetTempId the target tempId to move to 
     * @param targetColumnIndex the columnIndex to move to
     */
    private static NavigateTo(root: IEstimate, travelling: NavDirection, targetTempId: number, targetColumnIndex: number): NavResult {
        // Target element
        const id = UI.fieldId(targetTempId, targetColumnIndex);
        const el = document.getElementById(id) as HTMLInputElement;
        if (!el) {
            return NavResult.Impossible;
        }

        if (UI.tryFocusElement(el)) {
            return NavResult.OK;
        }

        // Based on the direction, select the next target to try and recurse
        switch (travelling) {
            case NavDirection.Down: {
                return UI.NavigateDown(root, el);
            }
            case NavDirection.Up: { 
                return UI.NavigateUp(root, el);
            }
            case NavDirection.Left: {
                return UI.NavigateLeft(root, el);
            }
            case NavDirection.Right: { 
                return UI.NavigateRight(root, el);
            }
        }
    }

    private static onkeydownImpl(root: IEstimate, addBelow: (() => number|void) | undefined, isSelect: boolean = false) {
        const f: React.KeyboardEventHandler<any> = event => {
            const el = event.currentTarget;

            if (event.altKey || event.ctrlKey || event.shiftKey)
                return;

            // Preprocess F2
            if (event.key === "F2") {
                if (isSelect)
                    return;

                if (el.selectionStart === el.selectionEnd) {
                    UI.selectAll(el);
                } else {
                    el.selectionEnd = el.selectionStart;
                }
                return;
            }

            let handled = false;
            
            switch (event.key) {
                case "ArrowUp":
                {
                    handled = UI.NavigateUp(root, el) !== NavResult.Impossible;
                    break;
                }
                case "ArrowDown": 
                {
                    handled = UI.NavigateDown(root, el) !== NavResult.Impossible;
                    break;
                }
                case "ArrowLeft":
                {
                    if (isSelect || (el.selectionStart === el.selectionEnd && el.selectionStart === 0)) {
                        handled = UI.NavigateLeft(root, el) !== NavResult.Impossible;
                    }
                    break;
                }
                case "ArrowRight":
                {
                    if (isSelect || (el.selectionStart === el.selectionEnd && el.selectionStart === el.value.length)) {
                        handled = UI.NavigateRight(root, el) !== NavResult.Impossible;
                    }
                    break;
                }
                case "Enter":
                {
                    handled = true;
                    document.getElementById(ROOT_TITLE)?.focus();
                    addBelow && addBelow();
                    break;
                }
            }

            if (handled) {
                event.preventDefault();
                event.stopPropagation();
            }
            return handled;
        };

        return f;
    }

    /**
     * Keydown handler
     * @param root reference to the root
     * @param addBelow handler to add a new node row below this one for use on enter keydown
     * @returns void
     */
    static onkeydownSelect(root: IEstimate, addBelow: (() => number|void) | undefined): React.KeyboardEventHandler<HTMLSelectElement> {
        return UI.onkeydownImpl(root, addBelow, true) as React.KeyboardEventHandler<HTMLSelectElement>;
    }

    /**
     * Keydown handler
     * @param root reference to the root
     * @param addBelow handler to add a new node row below this one for use on enter keydown
     * @returns void
     */
    static onkeydown(root: IEstimate, addBelow: (() => number|void) | undefined): React.KeyboardEventHandler<HTMLInputElement> {
        return UI.onkeydownImpl(root, addBelow, false) as React.KeyboardEventHandler<HTMLInputElement>;
    }

    /**
     * Focus input handler
     * @param event 
     */
    static focused(event: React.FocusEvent<HTMLInputElement>) {
        const el = event.currentTarget;
        el.parentElement?.classList.add(style.focused);
        UI.selectAll(el);
    };

    /**
     * Blur event handler
     * @param event 
     */
    static blurred(event: React.FocusEvent<HTMLInputElement>) {
        event.currentTarget.parentElement?.classList.remove(style.focused)
    };

    /**
     * Format the number
     * @param n the number to format
     * @returns string
     */
    static formatNumber(n: number | string): string {
        if (typeof(n) === "string") {
            return "";
        }

        return n.toFixed(2);
    }

    /**
     * Focus handler for the select element
     * @param event 
     */
    static focusedSelect(event: React.FocusEvent<HTMLSelectElement>) {
        const el = event.currentTarget;
        el.parentElement?.classList.add(style.focused);
    }

    /**
     * Blur handler for the select element
     * @param event 
     */
    static blurredSelect(event: React.FocusEvent<HTMLSelectElement>) {
        event.currentTarget.parentElement?.classList.remove(style.focused)
    }

    /**
     * Click handler for input in the grid
     * @param event 
     */
    static onCellClick(event: React.MouseEvent<HTMLElement>) {
        (event.currentTarget.firstChild as HTMLInputElement)?.focus();
    }
}

/**
 * Modifier operations
 */
class Modifier {
    /**
     * Returns a new modifier with default values.
     * @returns new modifier with default values
     */
    static default(): IModifier {
        return {
            tempId: TempId.next(),
            name: "",
            modifier_type: ModifierType.Percent,
            value: 0,
        }
    }

    /**
     * Insert a new modifier before the specified modifier.
     * @param modifiers the nodes array to add the a new node into
     * @param modifier the node to insert a new node before
     * @returns the index of the new modifier
     */
     static insertBefore(modifiers: Array<IModifier>, modifier: IModifier): number {
        let index = modifiers.findIndex(p => p === modifier);
        modifiers.splice(index, 0, Modifier.default());
        return index;
    }

    /**
     * Insert a new modifier after the specified modifier.
     * @param modifiers the modifiers array to add the a new modifier into
     * @param modifier the modifier to insert a new modifier after
     * @returns the index of the new modifier
     */
    static insertAfter(modifiers: Array<IModifier>, modifier: IModifier): number {
        let index = modifiers.findIndex(p => p === modifier);
        if (index == modifiers.length - 1) {
            modifiers.push(Modifier.default());
        } else {
            modifiers.splice(index + 1, 0, Modifier.default());
        }
        return index + 1;
    }

    /**
     * Calculate the total for the node and apply the modifier
     * @param modifier the modifier to apply
     * @param node the node to compute for
     * @returns [totalLow, totalHigh]
     */
    static calcForNode(modifier: IModifier, node: INode): [number, number] {
        return Modifier.calcFor(modifier, Node.calcTotal(node))
    }

    /**
     * Calculate the value of the modifier for [low, high] e.g. 10% of [50, 60] => [5, 6].
     * @param modifier the modifier to apply
     * @param param1 array with values [low, high] to apply the modifier to
     * @returns the value of [low, high] with the modifier applied 
     */
    static calcFor(modifier: IModifier, [low, high]: [number, number]): [number, number] {
        switch (modifier.modifier_type) {
            case ModifierType.Fixed: {
                return [
                    modifier.value,
                    modifier.value
                ]
            }
            case ModifierType.Percent: {
                return [
                    low / 100.0 * modifier.value,
                    high / 100.0 * modifier.value
                ]
            }
            default: {
                return [0, 0];
            }
        }
    }

    /**
     * If tempId is undefined, mutate the modifier and apply a tempId.
     * @param modifier Recurse from
     */
     static ensureTempId(modifier: IModifier) {
        if (modifier.tempId === undefined) {
            modifier.tempId = TempId.next();
        }
    }
}

/**
 * Node operations
 */
class Node {
    /**
     * Returns a new node with default values.
     * @param low low estimate value
     * @param high high estimate value
     * @returns new node with default values
     */
    static default(low?: number | undefined, high?: number | undefined): INode {
        return {
            tempId: TempId.next(),
            title: "",
            low,
            high,
            nodes: [],
        }
    }

    /**
     * Delete the specified node from the nodes array
     * @param nodes the nodes array holding the node
     * @param node the node to remove from the nodes array
     */
    static delete(nodes: Array<INode>, node: INode): void {
        nodes.splice(nodes.findIndex(p => p === node), 1);
    }

    /**
     * Insert a new node before the specified node.
     * @param nodes the nodes array to add the a new node into
     * @param node the node to insert a new node before
     * @returns the index of the new node
     */
    static insertBefore(nodes: Array<INode>, node: INode): number {
        let index = nodes.findIndex(p => p === node);
        nodes.splice(index, 0, Node.default(0, 0));
        return index;
    }

    /**
     * Insert a new node after the specified node.
     * @param nodes the nodes array to add the a new node into
     * @param node the node to insert a new node after
     * @returns the index of the new node
     */
    static insertAfter(nodes: Array<INode>, node: INode): number {
        let index = nodes.findIndex(p => p === node);
        if (index == nodes.length - 1) {
            nodes.push(Node.default(0, 0));
        } else {
            nodes.splice(index + 1, 0, Node.default(0, 0));
        }
        return index + 1;
    }

    /**
     * Adds a new child default node into node.nodes
     * @param node the node to add a new default child node to
     */
    static addChild(node: INode): number {
        let low = 0, high = 0;
        if (node.nodes.length == 0) {
            low = node.low || 0;
            high = node.high || 0;
            node.low = undefined;
            node.high = undefined;
        }
        node.nodes.push(Node.default(low, high));
        return node.nodes.length - 1;
    }

    /**
     * Calculate the total [low, high] for the node and all children.
     * @param node the node to compue
     * @returns [low, high]
     */
    static calcTotal(node: INode): [number, number] {
        let acc: [number, number] = [+(node.low || 0), +(node.high || 0)];
        for (const p of node.nodes) {
            let temp = Node.calcTotal(p);
            acc[0] += +temp[0];
            acc[1] += +temp[1];
        }
    
        return acc;
    }

    /**
     * Recurse the node, if tempId is undefined, mutate the node and apply a tempId.
     * @param node Recurse from
     */
    static ensureTempId(node: INode) {
        if (node.tempId === undefined) {
            node.tempId = TempId.next();
        }
        if (Array.isArray(node.nodes)) {
            for (const p of node.nodes) {
                Node.ensureTempId(p);
            }
        }
    }
}

/** Root operations */
class Root {
    /**
     * Returns the item that matches the specified tempId
     * @param seekFrom 
     * @param tempId
     */
    static findPath(intoPath: Array<INode>, seekFrom: INode, seekTempId: number): boolean {
        intoPath.push(seekFrom);
        
        if (seekFrom.tempId === seekTempId) {
            return true;
        }

        if (Object.keys(seekFrom).findIndex(p => p === "options") !== -1) {
            const root = seekFrom as IEstimate;
            for (const child of root.options?.modifiers) {
                if (child.tempId === seekTempId) {
                    intoPath.push(seekFrom);
                    return true;
                }
            }
        }

        if (Object.keys(seekFrom).findIndex(p => p === "nodes") !== -1) {
            const node = seekFrom as INode;
            for (const child of node.nodes) {
                if (Root.findPath(intoPath, child, seekTempId)) {
                    return true;
                } else {
                    intoPath.pop();
                }
            }
        }

        return false;
    }

    /**
     * Compute the grand total for the root
     */
    static grandTotal(root: IEstimate): [number, number] {
        const sub = Node.calcTotal(root);
        const acc = [0, 0];
    
        for (let i = 0; i < root.options.modifiers.length; i++) {
            const modifier = Modifier.calcFor(root.options.modifiers[i], sub);
            acc[0] = acc[0] + +modifier[0];
            acc[1] = acc[1] + +modifier[1];
        }
    
        sub[0] += acc[0];
        sub[1] += acc[1];
        return sub;
    }

    /**
     * Recurse the nodes and modifiers, if tempId is undefined, mutate the item and apply a tempId.
     * @param root Recurse from
     */
     static ensureTempId(root: IEstimate) {
        Node.ensureTempId(root);
        root.options?.modifiers.forEach(Modifier.ensureTempId);
    }
}

type NodeGridProps = {
    root: IEstimate | null, 
    validationErrors?: IVErrorsKind | undefined,
    changed: (then?: () => void) => void, 
    isLoading?: boolean | undefined,
}
interface INodesGridState {
    show_sub_totals_temp: boolean,
    optionsOpen: boolean,
}
class NodesGrid extends React.Component<NodeGridProps, INodesGridState> {
    constructor(props: NodeGridProps) {
        super(props);

        this.state = {
            show_sub_totals_temp: props.root?.options.show_sub_totals || false,
            optionsOpen: false,
        };
    }

    /**
     * Add a new modifier at the end of the collection
     */
    addModifier = () => {
        const { root, changed } = this.props;
        if (!root) return;

        root.options.modifiers.push(Modifier.default())
        changed(() => this.setFocusModifier(root.options.modifiers.length - 1));
    }

    /**
     * Add a new modifier before the specified modifier
     * @param modifier the current modifier to action for
     * @returns the new index
     */
    insertModifierBefore = (modifier: IModifier): number => {
        const { root, changed } = this.props;
        if (!root) return 0;

        const index = Modifier.insertBefore(root.options.modifiers, modifier)
        changed(() => this.setFocusModifier(index));
        return index;
    }

    /**
     * Add a new modifier after the specified modifier
     * @param modifier the current modifier to action for
     * @returns the new index
     */
    insertModifierAfter = (modifier: IModifier): number => {
        const { root, changed } = this.props;
        if (!root) return 0;

        const index = Modifier.insertAfter(root.options.modifiers, modifier)
        changed(() => this.setFocusModifier(index));
        return index;
    }

    /**
     * Delete the specified modifier
     * @param modifier the modifier to delete
     */
    deleteModifier = (modifier: IModifier) => {
        const { root, changed } = this.props;
        if (!root) return;

        const index = root.options.modifiers.findIndex(p => p.tempId === modifier.tempId);
        
        if (index !== -1) {
            root.options.modifiers.splice(index, 1);
            changed(() => this.setFocusModifier(index));
        }
    }

    /**
     * Set focus to the first field of the modifier with the specified index.
     * @param index the node index to focus
     */
     setFocusModifier = (index: number) => {
        const { root } = this.props;
        if (!root) return;

        const modifiers = root.options.modifiers;

        if (index < modifiers.length && index >= 0) {
            const id = `${modifiers[index].tempId}_${UI.FIRST_FIELD_INDEX}`;
            const el = document.getElementById(id);
            el && el.focus();
        }
    }

    /**
     * Add a new child to the root.
     */
    addChild = () => {
        const { root, changed } = this.props;
        if (!root) return;

        const index = Node.addChild(root);
        changed(() => this.setFocus(index));
    }

    /**
     * Add a new child node to the root.
     * @param n the child to add above
     * @returns the index of the new node
     */
    addAboveChild = (n: INode) => {
        const { root, changed } = this.props;
        if (!root) return 0;

        const index = Node.insertBefore(root.nodes, n);
        changed(() => this.setFocus(index));
        return index;
    };

    /**
     * Add a new child to the root below the specified node
     * @param n the child to add below
     * @returns the index of the new node
     */
    addBelowChild = (n: INode) => {
        const { root, changed } = this.props;
        if (!root) return 0;

        const index = Node.insertAfter(root.nodes, n);
        changed(() => this.setFocus(index));
        return index;
    };

    /**
     * Set focus to the first field of the node with the specified index.
     * @param index the node index to focus
     */
    setFocus(index: number) {
        const { root } = this.props;
        if (!root) return;

        const el = document.getElementById(`${root.nodes[index].tempId}_${UI.FIRST_FIELD_INDEX}`);
        el && el.focus();
    }

    setShowSubTotalsTemp = (value: boolean) => {
        this.setState({ show_sub_totals_temp: value });
    }

    setOptionsOpen = (value: boolean) => {
        this.setState({ optionsOpen: value });
    }

    override render = () => {
        const { root, changed, isLoading } = this.props;
        
        if (isLoading) {
            return <SkeletonDataTable rowCount={5} />
        }

        if (!root) {
            return <div>An error occurred.</div>
        }
        const options = root.options;

        const [totalLow, totalHigh] = Node.calcTotal(root);
        const [grandLow, grandHigh] = Root.grandTotal(root);

        const { show_sub_totals_temp, optionsOpen } = this.state;
        const container = document.getElementById("modals");

        return (
            <div className={style.grid}>
                {container && ReactDOM.createPortal(
                    <Modal 
                        heading="Grid options"
                        primaryText="OK"
                        secondaryText="Cancel"
                        open={optionsOpen} 
                        requestClose={(p: ModalAction) => {
                            console.log("req close", p);
                            switch (p) {
                                case "primary":
                                    this.setOptionsOpen(false);
                                    console.log("OK");
                                    root.options.show_sub_totals = show_sub_totals_temp;
                                    changed();
                                    break;

                                case "secondary":
                                    this.setOptionsOpen(false);
                                    console.log("cancel");
                                    this.setShowSubTotalsTemp(root.options.show_sub_totals);
                                    break;
                            }
                            return Promise.resolve();
                        }}
                    >
                        <Checkbox id="show_sub_totals" label="Show sub-totals" checked={show_sub_totals_temp} onChange={(checked: boolean) => {
                            this.setShowSubTotalsTemp(checked);
                        }} />
                    </Modal>, container)}
                <div className={style.grid_header}>
                    <table>
                        <thead>
                            <tr className={style.header_row}>
                                <th>
                                    <span>Estimates</span>
                                </th>
                                <th className={style.buttons_right} style={{paddingRight: 0}}>
                                    <button type="button" onClick={() => this.setOptionsOpen(true)} title="Grid options">
                                        <Options />
                                    </button>
                                    <button type="button" onClick={this.addChild} title="Add estimate row">
                                        <Add />
                                    </button>
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className={style.grid_header}>
                    <table>
                        <thead>
                            <tr className={style.header_row}>
                                <th className={`${style.cell} ${style.cell_menu} ${style.cell_menu_first}`}>
                                </th>
                                <th className={`${style.cell} ${style.cell_menu} ${style.buttons_left}`}>
                                </th>
                                <th className={style.name_column}>
                                    Description
                                </th>
                                <th className={`${style.value_cell}`}>
                                    Low
                                </th>
                                <th className={`${style.value_cell}`}>
                                    High
                                </th>
                                <th className={style.buttons_right}>
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className={style.grid_body}>
                    {root.nodes.length > 0 && 
                    <React.Fragment>
                        {root.nodes.map(p => <NodeRow 
                            key={p.tempId}
                            root={root}
                            node={p} 
                            depth={1} 
                            changed={changed} 
                            options={options}
                            deleted={p => Node.delete(root.nodes, p)} 
                            addAbove={() => this.addAboveChild(p)}
                            addBelow={() => this.addBelowChild(p)}
                        />)}
                    </React.Fragment>
                    }
                </div>
                <div className={style.grid_footer}>
                    <table className={style.node}>
                        <tbody>
                            <tr>
                                <td className={`${style.cell_inner}`}>
                                    <span>Total</span>
                                </td>
                                <td className={`${style.value_cell} ${style.cell} ${style.cell_disabled}`}>
                                    <input disabled className={style.value} type="text" value={totalLow} />
                                </td>
                                <td className={`${style.value_cell} ${style.cell} ${style.cell_disabled}`}>
                                    <input disabled className={style.value} type="text" value={totalHigh} />
                                </td>
                                <td className={`${style.cell} ${style.cell_menu} ${style.buttons_right}`}>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={style.grid_header}>
                    <table className={style.modifier_header}>
                        <tbody>
                            <tr className={style.header_row}>
                                <th>
                                    <span>Estimate Modifiers</span>
                                </th>
                                <th className={style.buttons_right} style={{ paddingRight: 0 }}>
                                    <button type="button" onClick={this.addModifier} title="Add modifier">
                                        <Add />
                                    </button>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <ModifierGrid root={root} 
                    changed={changed} 
                    deleted={this.deleteModifier} 
                    insertBefore={this.insertModifierBefore} 
                    insertAfter={this.insertModifierAfter} 
                />
                <div className={style.grid_footer}>
                    <table className={style.node}>
                        <tbody>
                            <tr>
                                <td className={`${style.cell_inner}`}>
                                    <span>Grand total</span>
                                </td>
                                <td className={`${style.value_cell} ${style.cell} ${style.cell_disabled}`}>
                                    <input disabled className={style.value} type="text" value={grandLow} />
                                </td>
                                <td className={`${style.value_cell} ${style.cell} ${style.cell_disabled}`}>
                                    <input disabled className={style.value} type="text" value={grandHigh} />
                                </td>
                                <td className={`${style.cell} ${style.cell_menu} ${style.buttons_right}`}>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

interface NodeRowProps {
    root: IEstimate,
    node: INode, 
    depth: number, 
    changed: (then?: () => void) => void, 
    options: NodeGridOptions,
    deleted: null | ((node: INode) => void),
    addAbove: (() => number|void),
    addBelow: (() => number|void),
};
class NodeRow extends React.Component<NodeRowProps, { collapsed: boolean }> {
    constructor(props: NodeRowProps) {
        super(props);
        this.state = {
            collapsed: false,
        };
    }

    static setFocus (node: INode, index: number) {
        let el = document.getElementById(`${node.nodes[index].tempId}_0`);
        el && el.focus();
    }

    override render = () => {
        const {
            root,
            node, 
            depth, 
            changed, 
            options,
            deleted,
            addAbove,
            addBelow,
        } = this.props;

        const { collapsed } = this.state;
        
        let addChild = () => {
            Node.addChild(node);
            changed(() => NodeRow.setFocus(node, node.nodes.length - 1));
        };
        let addAboveChild = (n: INode) => {
            let index = Node.insertBefore(node.nodes, n);
            changed(() => NodeRow.setFocus(node, index));
        };
        let addBelowChild = (n: INode) => {
            let index = Node.insertAfter(node.nodes, n);
            changed(() => NodeRow.setFocus(node, index));
        };
        let [totalLow, totalHigh] = Node.calcTotal(node);
        let kd = UI.onkeydown(root, addBelow);

        return (
            <table className={`${style.node} ${depth > 1 ? style.node_inner : ""}`}>
                <tbody>
                    <tr id={`${node.tempId}_tr`} className={style.node_row}>
                        <td className={`${style.cell} ${style.cell_menu} ${style.cell_menu_first}`}>
                            <button type="button" tabIndex={-1} onClick={() => {
                                this.setState({collapsed: !collapsed});
                            }} title="Toggle child rows" style={{position: "relative"}}>
                                {!collapsed &&
                                <ChevronRight />
                                }
                                {collapsed &&
                                <span title={`${node.nodes.length} child row${node.nodes.length > 1 ? "s" : ""}`}>+{node.nodes.length}</span>
                                }
                            </button>
                        </td>
                        <td className={`${style.cell} ${style.cell_menu} ${style.buttons_left}`}>
                            <button type="button" tabIndex={-1} onClick={addAbove} title="Add row above">
                                <NorthEast />
                            </button>
                            <button type="button" tabIndex={-1} onClick={addBelow} title="Add row below">
                                <SouthEast />
                            </button>
                            <button type="button" tabIndex={-1} onClick={addChild} title="Add child">
                                <SubArrowRight />
                            </button>
                        </td>
                        <td className={`${style.title} ${style.cell}`} colSpan={node.nodes.length != 0 ? 1 : 3} onClick={UI.onCellClick}>
                            <input id={`${node.tempId}_0`} className={style.title_value} type="text" 
                                data-r_col={0} data-r_type={NavRowType.Node} data-r_ref={node.tempId} data-r_collapsed={collapsed}
                                value={node.title} 
                                onChange={e => { 
                                    node.title = ((e.target as HTMLInputElement)?.value) || ""; 
                                    changed(); 
                                }} 
                                onFocus={UI.focused} onBlur={UI.blurred} onKeyDown={kd} />
                        </td>
                        {node.nodes.length == 0 && 
                        <React.Fragment>
                            <td className={`${style.value_cell} ${style.cell}`} onClick={UI.onCellClick}>
                                <input id={`${node.tempId}_1`} className={style.value} type="text" 
                                    data-r_col={1} data-r_type={NavRowType.Node} data-r_ref={node.tempId} data-r_collapsed={collapsed}
                                    value={node.low} 
                                    onChange={e => { 
                                        node.low = Number.parseFloat((e.target as HTMLInputElement)?.value) || 0; 
                                        changed(); 
                                    }} 
                                    onFocus={UI.focused} onBlur={UI.blurred} onKeyDown={kd} />
                            </td>
                            <td className={`${style.value_cell} ${style.cell}`} onClick={UI.onCellClick}>
                                <input id={`${node.tempId}_2`} className={style.value} type="text" 
                                    data-r_col={2} data-r_type={NavRowType.Node} data-r_ref={node.tempId} data-r_collapsed={collapsed}
                                    value={node.high} 
                                    onChange={e => { 
                                        node.high = Number.parseFloat((e.target as HTMLInputElement)?.value) || 0; 
                                        changed(); 
                                    }} 
                                    onFocus={UI.focused} onBlur={UI.blurred} onKeyDown={kd} />
                            </td>
                        </React.Fragment>
                        }
                        {node.nodes.length > 0 && 
                        <React.Fragment>
                            <td className={`${style.value_cell} ${style.cell} ${style.cell_disabled}`} onClick={UI.onCellClick}>
                                <input id={`${node.tempId}_1`} disabled className={style.value} type="text" 
                                    data-r_col={1} data-r_type={NavRowType.Node} data-r_ref={node.tempId} data-r_collapsed={collapsed}
                                    value={totalLow} 
                                    onKeyDown={kd} />
                            </td>
                            <td className={`${style.value_cell} ${style.cell} ${style.cell_disabled}`} onClick={UI.onCellClick}>
                                <input id={`${node.tempId}_2`} disabled className={style.value} type="text" 
                                    data-r_col={2} data-r_type={NavRowType.Node} data-r_ref={node.tempId} data-r_collapsed={collapsed}
                                    value={totalHigh} 
                                    onKeyDown={kd} />
                            </td>
                        </React.Fragment>
                        }
                        <td className={`${style.buttons_right} ${style.cell} ${style.cell_menu}`}>
                            <button type="button" tabIndex={-1} onClick={addBelow} title="Add row below">
                                <SubArrowLeft />
                            </button>
                            {deleted && 
                            <button type="button" tabIndex={-1} title="Delete row" onClick={() => {
                                deleted(node);
                                changed();
                            }}>
                                <ClearX />
                            </button>
                            }
                        </td>
                    </tr>
                    {node.nodes.length > 0 && !collapsed &&
                    <React.Fragment>
                    <tr>
                        <td colSpan={6} className={`${style.cell} ${style.cell_disabled} ${style.cell_inner}`}>
                            {node.nodes.map(p => <NodeRow 
                                key={p.tempId}
                                root={root}
                                node={p} 
                                depth={depth + 1}
                                options={options} 
                                changed={changed}
                                deleted={p => Node.delete(node.nodes, p)} 
                                addAbove={() => addAboveChild(p)}
                                addBelow={() => addBelowChild(p)}
                            />)}
                        </td>
                    </tr>
                    {options.show_sub_totals && node.nodes.length > 1 &&
                    <tr className={style.node_footer}>
                        <td></td>
                        <td colSpan={2} className={`${style.cell} `}>
                            <span>Sub-total for {node.title != "" ? node.title : "[no title]"}</span>
                        </td>
                        <td className={`${style.value_cell} ${style.cell} ${style.cell_disabled}`}>
                            <input disabled className={style.value} type="text" value={totalLow} />
                        </td>
                        <td className={`${style.high} ${style.cell} ${style.cell_disabled}`}>
                            <input disabled className={style.value} type="text" value={totalHigh} />
                        </td>
                        <td className={`${style.cell} ${style.cell_disabled}`}>
                            
                        </td>
                    </tr>}
                    </React.Fragment>}
                </tbody>
            </table>
        );
    }
}

interface ModifierGridProps {
    root: IEstimate,
    changed: () => void,
    insertBefore: (modifier: IModifier) => number,
    insertAfter: (modifier: IModifier) => number,
    deleted: (m: IModifier) => void;
}
const ModifierGrid: React.FC<ModifierGridProps> = (props) => {
    const { root } = props;
    const modifiers = root && root.options && root.options.modifiers || [];
    return (
        <React.Fragment>
            <div className={style.grid_header}>
                <table className={style.modifier_table}>
                    <thead>
                        <tr className={style.header_row}>
                            <th className={`${style.cell} ${style.cell_menu}`}></th>
                            <th className={style.buttons_left}></th>
                            <th className={style.name_column}>Description</th>
                            <th className={style.type_column}>Type</th>
                            <th className={style.value_cell}>Value</th>
                            <th className={`${style.value_cell}`}>
                                Low
                            </th>
                            <th className={`${style.value_cell}`}>
                                High
                            </th>
                            <th className={style.buttons_right}></th>
                        </tr>
                    </thead>
                </table>
            </div>
            <div className={style.grid_body}>
                <table className={`${style.node} ${style.modifier_table}`}>
                    <tbody>
                        {modifiers.map((modifier, i) => 
                            <ModifierRow key={i} modifier={modifier} {...props} />
                        )}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
}

interface ModifierRowProps extends ModifierGridProps {
    modifier: IModifier,
}
const ModifierRow: React.FC<ModifierRowProps> = ({ modifier, root, changed, insertBefore, insertAfter, deleted}) => {
    const kd = UI.onkeydown(root, () => insertAfter(modifier));
    const kdSelect = UI.onkeydownSelect(root, () => insertAfter(modifier));

    const [modLow, modHigh] = Modifier.calcForNode(modifier, root);
    return (
        <tr>
            <td className={`${style.cell} ${style.cell_menu} ${style.cell_menu_first}`}>
                <button type="button" tabIndex={-1} style={{position: "relative"}}>
                    <ChevronRight />
                </button>
            </td>
            <td className={`${style.cell} ${style.cell_menu} ${style.buttons_left}`}>
                <button type="button" tabIndex={-1} onClick={() => insertBefore(modifier)} title="Add row above">
                    <NorthEast />
                </button>
                <button type="button" tabIndex={-1} onClick={() => insertAfter(modifier)} title="Add row below">
                    <SouthEast />
                </button>
            </td>
            <td className={`${style.cell} ${style.name_column}`}>
                <input className={style.value} type="text" value={modifier.name}
                    id={`${modifier.tempId}_0`}
                    data-r_col={0} data-r_type={NavRowType.Modifier} data-r_ref={modifier.tempId} data-r_collapsed={true}
                    onChange={e => { 
                        modifier.name = ((e.target as HTMLInputElement)?.value) || ""; 
                        changed(); 
                    }} 
                    onFocus={UI.focused} onBlur={UI.blurred} onKeyDown={kd} 
                    />
            </td>
            <td className={`${style.cell} ${style.type_column}`}>
                <select
                    id={`${modifier.tempId}_1`}
                    data-r_col={1} data-r_type={NavRowType.Modifier} data-r_ref={modifier.tempId} data-r_collapsed={true}
                    onFocus={UI.focusedSelect} onBlur={UI.blurredSelect} onKeyDown={kdSelect}
                    onChange={e => {
                        const selected = e.currentTarget.options[e.currentTarget.selectedIndex];
                        modifier.modifier_type = parseInt(selected.value) as ModifierType;
                        changed();
                    }}>
                    <option value={ModifierType.Fixed}>Fixed</option>
                    <option value={ModifierType.Percent}>Percentage</option>
                </select>
            </td>
            <td className={`${style.value_cell} ${style.cell}`}>
                <input className={style.value} type="text" value={modifier.value}
                    id={`${modifier.tempId}_2`}
                    data-r_col={2} data-r_type={NavRowType.Modifier} data-r_ref={modifier.tempId} data-r_collapsed={true}
                    onChange={e => { 
                        modifier.value = parseFloat((e.target as HTMLInputElement)?.value) || 0; 
                        changed();
                    }} 
                    onFocus={UI.focused} onBlur={UI.blurred} onKeyDown={kd}
                />
            </td>
            <td className={`${style.value_cell} ${style.cell} ${style.cell_disabled}`}>
                <input disabled className={style.value} type="text" value={UI.formatNumber(modLow)} />
            </td>
            <td className={`${style.value_cell} ${style.cell} ${style.cell_disabled}`}>
                <input disabled className={style.value} type="text" value={UI.formatNumber(modHigh)} />
            </td>
            <td className={`${style.cell} ${style.cell_menu} ${style.buttons_right}`}>
                <button type="button" tabIndex={-1} onClick={() => insertAfter(modifier)} title="Add modifier below">
                    <SubArrowLeft />
                    </button>
                <button type="button" tabIndex={-1} title="Delete row" onClick={() => {
                    deleted(modifier);
                    changed();
                }}>
                    <ClearX />
                </button>
            </td>
        </tr>
    );
}

interface IEstimatorProps extends RouteComponentProps<any> {
}

interface IEstimatorState {
    estimate: IEstimate | null,
    validationErrors: IVErrorsKind | undefined,
    isLoading: boolean,
    isSubmitting: boolean,
}
class Estimator extends React.Component<IEstimatorProps, IEstimatorState> {
    scrollTop: number = 0;
    scrollingThrottle: any = null;

    constructor(props: IEstimatorProps) {
        super(props);
        scrollY = window.scrollY;

        this.state = {
            estimate: null,
            isLoading: true,
            isSubmitting: false,
        } as IEstimatorState;
    }

    onScroll = (_: any) => {
        if (!this.scrollingThrottle) {
            this.scrollingThrottle = setTimeout(() => {
                const el = document.scrollingElement as HTMLElement;
                if (el) {
                    this.scrollTop = el.scrollTop;
                }
                this.scrollingThrottle = null;
            }, 100);
        }
    }

    override componentDidMount = () => {
        document.addEventListener("scroll", this.onScroll);

        const { match } = this.props;
        const id: Uuid | undefined = match?.params?.id;

        if (id) {
            api.getEstimate(id)
                .then((data: IEstimate | RejectReason) => {
                    if (typeof data === "object") {
                        const estimate = data as IEstimate;
                        Root.ensureTempId(estimate);

                        return this.setState({ 
                            estimate: data as IEstimate 
                        });
                    } else {
                        this.setState({ estimate: null });
                        notificationService.rejected(data as RejectReason);
                    }                
                })
                .catch(err => {
                    this.setState({ estimate: null });
                    notificationService.rejected(err);
                })
                .finally(() => { 
                    this.setState({ isLoading: false });
                });
        } else {
            this.setState({
                estimate: estimatorDataFactory(TempId.next),
                isLoading: false
            });            
        }
        
    };

    override componentWillUnmount = () => {
        document.removeEventListener("scroll", this.onScroll);
    }

    override componentDidUpdate = <P, S>(_prevProps: P, _prevState: S) => {
        window.requestAnimationFrame(() => {
            document.documentElement.scrollTop = this.scrollTop || 0;
        });
    }

    changed = (then?: () => void) => {
        const { estimate } = this.state;
        if (then)
            this.setState({ estimate }, then);
        else
            this.setState({ estimate });
    }

    projectChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { estimate } = this.state;
        if (estimate) {
            (estimate as IEstimate).title = e.currentTarget.value;
            this.changed();
        }
    }

    onSubmit = (): Promise<void> | void => {
        const { estimate } = this.state;
        if (estimate) {
            this.setState({ isSubmitting: true });

            return api.postEstimate(estimate)
                .then((data: idResponse<Uuid> | RejectReason) => {
                    if (typeof data === "object") {
                        (estimate as IEstimate).id = (data as idResponse<Uuid>).id;
                        this.setState({ estimate, validationErrors: undefined }, 
                            () => notificationService.success("Success", "Record saved.")
                        );
                    } else {
                        notificationService.rejected(data as RejectReason);
                    }                
                })
                .catch(err => {
                    const validationErrors = notificationService.rejected(err);
                    if (validationErrors) {
                        console.log("validationErrors", validationErrors);

                        this.setState({ validationErrors });
                    }
                })
                .finally(() => { 
                    this.setState({ isSubmitting: false })
                });
        }
    }

    errorFor = (field: string) => errorFor(this.state, field);

    override render = () => {
        const { estimate, validationErrors, isLoading, isSubmitting } = this.state;
        const { changed } = this;

        return (
            <div>
                <Breadcrumb path={[
                    { href: routes.home, title: "Home" },
                    { href: routes.estimator.projects, title: "Projects" },
                    { href: "", title: "Estimate" },
                ]} />

                {!estimate && !isLoading && <div>An error occurred.</div>}

                <Form id="est-form" label="Estimate">
                    <FormGroup>
                        <TextField id={ROOT_TITLE} 
                            label="Title" 
                            value={estimate?.title || ""} 
                            onChange={this.projectChanged} 
                            errorMessage={this.errorFor("title")}
                            isLoading={isLoading}
                        />
                    </FormGroup>
                    <FormGroup>
                        <NodesGrid 
                            root={estimate} 
                            changed={changed} 
                            validationErrors={validationErrors}
                            isLoading={isLoading}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ButtonStrip>
                            <Button key="save" label="Save" onClick={this.onSubmit} isLoading={isSubmitting}></Button>
                        </ButtonStrip>
                    </FormGroup>
                </Form>
            </div>
        );
    }
};

export default withRouter(Estimator);
