import * as React from "react";
import { ITodoList } from "src/todos/models";
import TextField from "@common/components/forms/textField";
import Button from "@common/components/forms/button";
import { errorFor, IVErrorsKind } from "@common/models/validation";
import { Form } from "@common/components/forms/form";
import ButtonStrip from "@common/components/forms/buttonStrip";
import { BackButton } from "@common/components/elements/backButton";

interface IProps { 
    label: string, 
    cancel?: (() => void) | undefined, 
    list: ITodoList, 
    isSubmitting: boolean, 
    submit: (list: ITodoList) => void,
    validationErrors: IVErrorsKind | undefined,
    isLoading?: boolean,
}

interface IState extends ITodoList {
}

class ListForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = { ...props.list };
    }

    override componentDidMount = () => {
        this.setState({...this.props.list});
    }

    override componentDidUpdate = (prevProps: IProps, _prevState: IState) => {
        if (this.props.list !== prevProps.list) {
            this.setState({...this.props.list});
        }
    }

    submitClicked = (e: any) => {
        const { list, submit } = this.props;
        const { description } = this.state;

        document.getElementById("add-actions")?.querySelectorAll("button")[0]?.focus();
        const changed = Object.assign({}, list, { description });

        submit(changed);
        e.preventDefault();
    };

    override render(): React.ReactNode {
        const { label, isLoading, isSubmitting, cancel, validationErrors } = this.props;
        const { description } = this.state;

        return (
            <Form id="list-form" label={label} onSubmit={isLoading ? undefined : this.submitClicked}>
                {cancel !== undefined && <BackButton onClick={cancel} disabled={isSubmitting} />}
                <TextField
                    id="list-description"
                    label="List name"
                    value={description}
                    rightLabel={`${description.length}/255`}
                    onChange={e => this.setState({ description: e.target.value })}
                    onBlur={e => this.setState({ description: e.target.value })}
                    errorMessage={errorFor({validationErrors}, "description")}
                    isLoading={isLoading}
                />
                <ButtonStrip>
                    <Button label="Save" isLoading={isSubmitting || isLoading} onClick={this.submitClicked} />
                </ButtonStrip>
            </Form>
        );
    }
}

export const newList = () => ({ 
    description: "", 
    list_id: "", 
    is_owner: true, 
    is_shared: false, 
});

export default ListForm;