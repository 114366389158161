import * as React from "react";
import MuiFormControlLabel from "@mui/material/FormControlLabel";
import MuiSwitch from "@mui/material/Switch"
import style from "./style.module.css";

interface IProps {
    id?: string | undefined,
    label?: string | undefined,
    disabled?: boolean,
    checked: boolean,
    onChange: (checked: boolean) => void,
    isLoading?: boolean,
}

export const SwitchToggle: React.FC<IProps> = ({ id, label, onChange, disabled, checked, isLoading }) => {
    if (isLoading)
        return null;

    const switchControl = (
        <MuiSwitch 
            className={style.checkbox} 
            id={id} 
            checked={checked} 
            onChange={e => onChange(e.target.checked)} />
    );

    if (label !== undefined && label !== "") {
        return (
            <MuiFormControlLabel 
                disabled={disabled} 
                control={switchControl} 
                label={label} 
                labelPlacement="start"/>
        );
    }

    return switchControl;
};

export default SwitchToggle;