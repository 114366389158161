import { apiBase } from "@config/settings";
import { apiDelete, apiGet, apiPost, expectEmpty, expectJson } from "@common/services/api";
import { IProjectIndexRow } from "@estimator/models";
import { IEstimate } from "@estimator/models";
import { RejectReason, Uuid } from "@common/models";

const SERVER_PATH = `${apiBase}api/v0/estimator/`;

/**
 * Api endpoints
 */
 export const api = {
    /**
     * GET the list of estimates for the current user.
     * @returns Promise<Array<IEstimateIndexRow | RejectReason>>
     */
    getEstimates: () => apiGet(SERVER_PATH + "estimates")
        .then<Array<IProjectIndexRow> | RejectReason>(resp => expectJson(resp, "Failed to fetch estimates.")),
    
    /**
     * GET the specified estimate for the current user
     * @param id the estimate id
     * @returns Promise<IEstimate | RejectReason>
     */
    getEstimate: (id: string) => apiGet(SERVER_PATH + "estimate", { id })
        .then<IEstimate | RejectReason>(resp => expectJson(resp, "Failed to fetch the specified estimate.")),

    /**
     * POST the specified estimate for the current user
     * @param estimate the estimate the persist
     * @returns Promise<IEstimate | RejectReason>
     */
    postEstimate: (estimate: IEstimate) => apiPost(SERVER_PATH + "estimate", estimate)
        .then<{id: Uuid} | RejectReason>(resp => expectJson(resp, "Failed to save project estimate.")), 

    /**
     * DELETE the specified estimate for the current user
     * @param id the estimate id
     * @returns Promise<IEstimate | RejectReason>
     */
    deleteEstimate: (id: string) => apiDelete(SERVER_PATH + "estimate", { id })
        .then<void | RejectReason>(resp => expectEmpty(resp, "Failed to delete the specified estimate.")),
};