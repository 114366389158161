import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { RejectReason } from "@common/models";
import { notificationService } from "@common/services/notification";
import { Button } from "@common/components/forms/button";
import { api, IUuidResult } from "@uuid/services/api";
import { Breadcrumb } from "@common/components/elements/breadcrumb";
import { TextField } from "@common/components/forms/textField";
import { routes } from "@config/routes";
import { ButtonStrip } from "@common/components/forms/buttonStrip";
import { Form } from "@common/components/forms/form";

interface IProps {
}

interface IState {
    isLoading: boolean,
    isSubmitting: boolean,
    uuid: IUuidResult | null,
}

class UuidGenerator extends React.Component<RouteComponentProps<IProps>, IState> {
    constructor(props: RouteComponentProps<IProps>) {
        super(props);

        this.state = {
            uuid: null,
            isLoading: true,
            isSubmitting: false,
        }
    }

    override componentDidMount = () => {
        this.getUuid();
    }

    getUuid = () => api.getUuid()
        .then(uuid => {
            this.setState({ 
                uuid: uuid as IUuidResult,
                isLoading: false 
            });
        })
        .catch((rejected: RejectReason) => {
            this.setState({ 
                uuid: null, 
                isLoading: false 
            });
            notificationService.rejected(rejected);
        });

    onSubmit = (): Promise<void> | void => {
        this.setState({ isSubmitting: true });
        return this.getUuid()
            .finally(() => { 
                this.setState({ isSubmitting: false })
            });
    }

    override render = () => {
        const { uuid, isLoading, isSubmitting } = this.state;
        const title = "UUID Generator";

        return (
            <div>
                <Breadcrumb path={[
                    { href: routes.home, title: "Home" },
                    { href: routes.uuid, title },
                ]} />

                <Form id="uuid-form" label="UUID Generator">
                    <TextField id="uuidText" 
                        label="Hyphenated" 
                        value={uuid?.hyphenated || ""} 
                        isLoading={isLoading}
                        readonly={true}
                        />
                    <TextField id="uuidText" 
                        label="Simple" 
                        value={uuid?.simple || ""} 
                        isLoading={isLoading}
                        readonly={true}
                        />
                    <ButtonStrip>
                        <Button key="generate" label="Generate" onClick={this.onSubmit} isLoading={isSubmitting}></Button>
                    </ButtonStrip>
                </Form>
            </div>
        );
    }
};

export default withRouter(UuidGenerator);
