import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { RejectReason } from "@common/models";
import { notificationService } from "@common/services/notification";
import { api } from "@todos/services/api";
import { ITodoList, ITodoListUser, ITodoListUserIndexResult, newTodoList } from "src/todos/models";
import TextField from "@common/components/forms/textField";
import Button from "@common/components/forms/button";
import { errorFor, IVErrorsKind } from "@common/models/validation";
import { Breadcrumb } from "@common/components/elements/breadcrumb";
import { routes } from "@config/routes";
import style from "./style.module.css";
import { Form } from "@common/components/forms/form";
import { SkeletonDataTable } from "@common/components/elements/skeleton";
import Fab from "@common/components/elements/fab";
import { Add } from "@common/components/icons";
import { List, ListItem, ListItemIcon, ListItemText } from "@common/components/elements/list";
import { useTheme } from "@mui/material";
import ReactDOM from "react-dom";
import { Modal, ModalAction } from "@common/components/elements/modal";

interface IProps {
    id?: string,
}

interface IState {
    list: ITodoList,
    listUsers: ITodoListUser[],
    validationErrors: IVErrorsKind | undefined,
    isLoading: boolean,
    isSubmitting: boolean,
    //
    showInviteUser: boolean,
}

class ShareTodoList extends React.Component<RouteComponentProps<IProps>, IState> {
    constructor(props: RouteComponentProps<IProps>) {
        super(props);

        this.state = {
            list: newTodoList(""),
            listUsers: [],
            isLoading: true,
            isSubmitting: false,
            validationErrors: undefined,
            showInviteUser: false,
        };
    }

    list_id: string = "";

    override componentDidMount = () => {
        this.list_id = this.props.match?.params?.id || "";
        api.getTodoListUsers(this.list_id)
            .then(data => {
                const { list_users, list } = data as ITodoListUserIndexResult;

                this.setState({
                    listUsers: list_users,
                    list,
                    isLoading: false,
                    validationErrors: undefined,
                });
            })
            .catch((rejected: RejectReason) => {
                const validationErrors = notificationService.rejected(rejected);
                this.setState({
                    isLoading: false,
                    validationErrors: validationErrors || undefined,
                });
            });
    };

    showInviteUserModal = () => {
        this.setState({
            showInviteUser: true,
        });
    }

    deleteListUser = (email: string) => {
        const { listUsers } = this.state;

        return api.deleteInviteUser(this.list_id, email)
            .then(() => {
                this.setState({
                    listUsers: listUsers.filter(p => p.email !== email),
                })
                notificationService.success("Usert removed", "User removed from this list.");
            });
    }

    deleteListUserNoOp = (_email: string) => {
        return Promise.reject("No action taken");
    }

    inviteUser = (email: string) => {
        const { listUsers } = this.state;

        return api.postInviteListUser(this.list_id, email)
            .then((data) => {
                const user = data as ITodoListUser;
                this.setState({
                    listUsers: [...listUsers, user].sort((a, b) => a.email.localeCompare(b.email)),
                }, () => {
                    notificationService.success("", "Invitation sent.")
                });
            });
    }

    override render = () => {
        const { list, listUsers, isSubmitting, isLoading, showInviteUser } = this.state;

        const owner = listUsers.find(p => p.is_owner );
        const collaborators = listUsers.filter(p => !p.is_owner );

        return (
            <>
                <Breadcrumb path={[
                    { href: routes.home, title: "Home" },
                    { href: routes.todos.lists, title: "Lists" },
                    { href: routes.todos.list(this.list_id), title: list.description || "" },
                    { href: "", title: "Permissions" },
                ]} />
                <div className={style.form_outer}>
                    {isLoading && 
                        <Form id="permissions-form" label="Permissions">
                            <SkeletonDataTable rowCount={2} />
                        </Form>
                    }
                    {!isLoading && 
                        <Form id="permissions-form" label="Permissions" onSubmit={this.showInviteUserModal}>
                            { owner &&
                            <div>
                                <List className={style.rows}>
                                    {([owner]).map((user, index) => {
                                        return (
                                            <ListUser key={index} index={index} user={user} onDeleteConfirm={this.deleteListUserNoOp} />
                                        );
                                    })}
                                </List>
                            </div>
                            }
                            <div style={{ marginTop: 16 }}>
                                <Button label="Invite a user" isLoading={isSubmitting} onClick={this.showInviteUserModal} />
                            </div>
                            <div style={{ flexGrow: 1, marginTop: "32px", marginRight: ".5rem" }}>
                                The following users have access to this list.
                            </div>
                            <div>
                                <List className={style.rows}>
                                    {(collaborators || []).map((user, index) => {
                                        return (
                                            <ListUser key={index} index={index} user={user} onDeleteConfirm={this.deleteListUser} />
                                        );
                                    })}
                                </List>
                            </div>
                            <div className={style.fab_bar}>
                                <Fab 
                                    className={style.fab} 
                                    label="Invite user" 
                                    icon={<Add color="#222" />} 
                                    color="secondary"
                                    onClick={() => this.showInviteUserModal()} />
                            </div>
                        </Form>
                    }
                </div>
                <InviteUser 
                    open={showInviteUser} 
                    onClose={() => this.setState({ showInviteUser: false })} 
                    onSubmit={this.inviteUser} />
            </>
        );
    }
}

interface IInviteUserProps {
    open: boolean,
    onClose: () => void,
    onSubmit: (email: string) => Promise<any>,
}
const InviteUser: React.FC<IInviteUserProps> = ({ open, onClose, onSubmit }) => {
    const container = document.getElementById("modals");
    const [submitting, setSubmitting] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [validationErrors, setValidationErrors] = React.useState({} as IVErrorsKind);

    const onDone = () => {
        setEmail("");
        setValidationErrors({});
        onClose();
    }
    const onAction = (p: ModalAction) => {
        switch (p) {
            case "primary": {
                setSubmitting(true);
                return onSubmit(email.toLowerCase())
                    .then(data => {
                        onDone();
                        return Promise.resolve(data);
                    })
                    .catch((err: RejectReason) => {
                        const validationErrors = notificationService.rejected(err);
                        if (validationErrors) {
                            setValidationErrors(validationErrors);
                        }
                        return Promise.reject(err);
                    })
                    .finally(() => setSubmitting(false));
            }
            case "close":
            case "secondary": {
                onDone();
                return Promise.resolve();
            }
        }
    }

    if (!container) {
        return null;
    }

    return ReactDOM.createPortal(
        <Modal 
            heading="Invite user"
            primaryText="OK"
            secondaryText="Cancel"
            open={open} 
            requestClose={onAction}
        >
            <TextField 
                errorMessage={errorFor({validationErrors}, "email")} 
                disabled={submitting} id="email" 
                label="Email" 
                value={email} 
                onChange={e => setEmail(e.target.value.toLowerCase())} />
        </Modal>, container);
    }

interface IListProps {
    index: number,
    user: ITodoListUser,
    onDeleteConfirm: (user_id: string) => Promise<void>,
}
const ListUser: React.FC<IListProps> = ({ index, user, onDeleteConfirm }: IListProps) => {
    const theme = useTheme();
    const [deletePrompt, setDeletePrompt] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);

    return (
        <ListItem 
            key={index} 
            className={style.row}
            style={{ 
                backgroundColor: (index % 2 === 0) ? theme.palette.background.default : "#191919",
                paddingRight: 0,
            }}>
                <ListItemText primary={user.email} secondary={user.is_owner ? "Owner" : "Collaborator"} style={{ flexGrow: 1 }} />
                <ListItemIcon style={{ minWidth: 36 }}>
                    {(!user.is_owner && user) &&
                        <>
                        {!deletePrompt && 
                            <Button label="Delete" variant="text" color="error" onClick={() => { setDeletePrompt(true) }} />
                        }
                        {deletePrompt && 
                            <>
                                <Button label="Cancel" variant="text" onClick={() => { setDeletePrompt(false) }} />
                                <Button isLoading={deleting} label="Delete" variant="contained" color="error" onClick={() => { 
                                    setDeleting(true);
                                    onDeleteConfirm(user.email)
                                        .catch(err => notificationService.rejected(err))
                                        .finally(() => setDeleting(false));
                                }} />
                            </>
                        }
                        </>
                    }
                </ListItemIcon>
        </ListItem>
    );
}

export default withRouter(ShareTodoList);