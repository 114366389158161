import MuiFab from "@mui/material/Fab";
import Loader from "../loader";

interface IProps {
    label: string,
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning",
    isLoading?: boolean,
    onClick?: any,
    disabled?: boolean,
    icon: JSX.Element,
    size?: "small" | "medium" | "large",
    className?: string,
}

export const Fab = ({ className, label, onClick, isLoading, color, disabled, icon, size = "medium" }: IProps) => {
    return (
        <MuiFab className={className} disabled={disabled || isLoading} aria-label={label} color={color} onClick={onClick} size={size}>
            {isLoading && <Loader />}
            {!isLoading && icon}
        </MuiFab>
    );
}

export default Fab;
