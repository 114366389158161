import { uuidService } from "@common/services/uuid";
import { ITodo } from "@todos/models";

const TAG_REGEX = /#\w*/gm;

export const todoService = {
    transformTags: function (todo: ITodo) {
        const uniqueTags = new Set<string>(todo.tags);
        const matches = todo.description?.match(TAG_REGEX);

        if (matches) {
            matches.map(p => p).forEach(p => uniqueTags.add(p));
        }

        todo.tags = [...uniqueTags].sort();
        todo.description = (todo.description || "").replace(TAG_REGEX, "").trim();
    },
    extractTags: function (into: Set<string>, todo: ITodo): Set<string> {
        todo.tags?.forEach(p => into.add(p));
        return into;
    },
    newTodo: function (list_id: string, description: string = ""): ITodo {
        return {
            todo_id: uuidService.NIL,
            list_id,
            description,
            details: "",
            status: "Pending",
        };
    }
}