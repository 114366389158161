import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { api, IApp, IApps } from "@auth/services/api";
import { History } from "history";
import { routes } from "@config/routes";
import MuiAppBar from "@mui/material/AppBar";
import MuiToolbar from "@mui/material/Toolbar";
import MuiIconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { ListDivider } from "@common/components/elements/list";
import style from "./style.module.css";
import { Apps } from "@common/components/icons";
import { APP_NAME } from "@config/settings";
import { RejectReason } from "@common/models";
import { notificationService } from "@common/services/notification";

export const APPBAR_MENU_START: string = "appbar-menu-start";
export const APPBAR_MENU_END: string = "appbar-menu-end";

function logout(history: History, setIsActive: (isActive: boolean) => void) {
	return api.postLogout()
		.then(() => {
			console.log("LOGGED OUT");
			history.push(routes.auth.signin);
		})
		.finally(() => setIsActive(false));
}

interface IProps extends RouteComponentProps<any> {
}

const Header = ({ history }: IProps) => {
	const [apps, setApps] = React.useState<Array<IApp> | null>(null);
	const [isActive, setIsActive] = React.useState<boolean>(false);
	const [isLoading, setIsLoading] = React.useState<boolean>(true);

	const logoutFn = () => logout(history, setIsActive);
	const hideMenuFn = () => setIsActive(false);
	const toggleMenuFn = () => setIsActive(!isActive);
	const menuItem = (text: string, action: () => void) => (
		<ListItem key={text} disablePadding>
			<ListItemButton onClick={action}>
				<ListItemText primary={text}/>
			</ListItemButton>
		</ListItem>
	);
	const drawerWidth = 240;
	const isLoggedIn = api.isLoggedIn();

	React.useEffect(() => {
		if (apps === null && isLoggedIn) {
			api.getApps()
				.then(res => {
					setApps((res as IApps)?.apps);
				}).catch((rejected: RejectReason) => {
					setApps([]); // don't retry
					console.error(rejected);
					notificationService.rejected(rejected);

				}).finally(() => {
					setIsLoading(false);
				});
		}
	});

	return (
		<React.Fragment>
			<MuiAppBar position="sticky" aria-label="header">
				<MuiToolbar>
					<div id={APPBAR_MENU_START}></div>
					<Typography
						className={style.logo}
						noWrap
						component="a"
						href={routes.home}
						sx={{
							display: { xs: 'flex', md: 'flex' },
						}}
					>
						{APP_NAME}
					</Typography>
					<Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
						<div id={APPBAR_MENU_END}></div>
						{isLoggedIn && !isLoading && apps !== null && apps.length > 0 &&
							<MuiIconButton size="large" edge="end" aria-label="menu" onClick={toggleMenuFn}>
								<Apps />
							</MuiIconButton>
						}
					</Box>
				</MuiToolbar>
			</MuiAppBar>
			{isLoggedIn &&
			<Box component="nav">
				<Drawer
					variant="temporary"
					anchor="right"
					open={isActive}
					onClose={hideMenuFn}
					ModalProps={{
						keepMounted: true,
					}}
					sx={{
						display: { xs: 'block', sm: 'block' },
						'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
					}}
					>
					<Box onClick={toggleMenuFn} sx={{ textAlign: 'center' }}>
						<List>
							{menuItem("Home", () => history.push(routes.home))}
							<ListDivider key="d1" />
							{apps?.map(p => menuItem(p.name, () => history.push(routes.getById(p.app))))}
							<ListDivider key="d2" />
							{menuItem("Logout", logoutFn)}
						</List>
					</Box>
				</Drawer>
			</Box>
			}
		</React.Fragment>
	);
};

export default withRouter(Header);
