import * as React from "react";
import { Button } from '@common/components/forms/button';
import { ButtonStrip } from '@common/components/forms/buttonStrip';
import { PasswordField } from '@common/components/forms/passwordField';
import { TextField } from "@common/components/forms/textField";
import { api } from '@auth/services/api';
import { routes } from "@config/routes";
import { Form } from '@common/components/forms/form';
import { notificationService } from '@common/services/notification';
import { errorFor as errorForImpl, errorMessageWith, IVErrorsKind, IVErrors } from '@common/models/validation';
import { RejectReason } from '@common/models';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouterLink } from "@common/components/elements/link";
import { Grid } from "@common/components/elements/grid";

interface ISignup {
    email: string, 
    password: string,
    repeat: string,
}
const defaultSignup: ISignup = {
    email: "", 
    password: "",
    repeat: "",
};

interface IProps extends RouteComponentProps<any> {
}

interface IState extends ISignup {
    isLoading: boolean,
    validationErrors?: IVErrorsKind,
}

class Signup extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = { ...defaultSignup, isLoading: false };
    }
    
    errorFor = (field: string) => {
        return errorForImpl(this.state, field);
    }

    validate = (): IVErrorsKind => {
        let { email, password, repeat } = this.state;
        const validationErrors: IVErrors = {
        };

        email = (email || "").trim();
        if (email.length === 0) {
            validationErrors["email"] = errorMessageWith("Email is required.");
        }

        password = password || "";
        if (password.length === 0) {
            validationErrors["password"] = errorMessageWith("Password is required.");
        }

        repeat = repeat || "";
        if (password !== repeat) {
            validationErrors["password"] = errorMessageWith("Password and Repeat password must match.");
            validationErrors["repeat"] = errorMessageWith("Password and Repeat password must match.");
        }

        this.setState({ validationErrors });
        return validationErrors;
    }

    /**
     * Displays any validation error message and sets the state.
     * Return true when valid, false otherwise.
     */
    isValid = (validationErrors: IVErrorsKind | undefined): boolean => {
        const invalid = notificationService.maybeInvalid(validationErrors);
        this.setState({ validationErrors: invalid });

        return !invalid || Object.keys(invalid).length > 0;
    }
    
    onSubmit = (e: any) => {
        e.preventDefault();
        const { email, password } = this.state;
        const { history } = this.props;

        const validationErrors = this.validate();
        if (this.isValid(validationErrors)) {
            return false;
        }

        this.setState({
            isLoading: true,
        }, () => {
            api.postSignup(email, password)
                .then((data: string | RejectReason) => {
                    if (typeof data === "string") {
                        notificationService.success("Success", "User created. Please sign in.");
                        this.setState({ isLoading: false }, () => history.push(routes.auth.signin));
                    } else {
                        this.isValid(notificationService.rejected(data as RejectReason));
                    }                
                })
                .catch(err => {
                    this.isValid(notificationService.rejected(err));
                })
                .finally(() => { 
                    this.setState({ isLoading: false })
                });
        });

        return false;
    }

    emailChange = (e: any) => {
        this.setState({ email: e.currentTarget.value })
    }

    passwordChange = (e: any) => {
        this.setState({ password: e.currentTarget.value })
    }

    repeatChange = (e: any) => {
        this.setState({ repeat: e.currentTarget.value })
    }

    override render = () => {
        const { email, password, repeat, isLoading } = this.state || {};

        return (
            <Grid container spacing={2}>
                <Grid sm></Grid>
                <Grid xs={12} sm={12} md={6} lg={4} xl={3}
                    sx={{ maxWidth: {
                        sm: 400
                    } }}
                >
                    <Form onSubmit={this.onSubmit} label="Create account" overview={
                        <p>Please enter your new account details or <RouterLink to={routes.auth.signin}>sign in</RouterLink>.</p>
                    }>
                        <TextField id="email" label="Email" value={email || ""} errorMessage={this.errorFor("email")} onChange={this.emailChange} />
                        <PasswordField id="password" label="Password" value={password || ""} errorMessage={this.errorFor("password")} onChange={this.passwordChange} />
                        <PasswordField id="repeat" label="Repeat password" value={repeat || ""} errorMessage={this.errorFor("repeat")} onChange={this.repeatChange} />
                        <ButtonStrip>
                            <Button key="submit" label="Create" isLoading={isLoading} onClick={this.onSubmit} />
                        </ButtonStrip>
                    </Form>
                </Grid>
                <Grid sm></Grid>
            </Grid>
        );
    }
}

export default withRouter(Signup);