import * as React from "react";
import Application, { tileStyle } from "@auth/components/application";
import { routes } from "@config/routes";
import { api, IApps } from "@auth/services/api";
import { RejectReason } from "@common/models";
import { notificationService } from "@common/services/notification";
import { SkeletonTile } from "@common/components/elements/skeleton";
import { Grid } from "@common/components/elements/grid";
// import style from "./style.module.css";

interface IProps {
}

interface IState {
    isLoading: boolean,
    apps: IApps | null,
}

export class Home extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = { isLoading: true, apps: null };
    }

    override componentDidMount() {
        api.getApps()
            .then(res => {
                const apps = res as IApps;
                this.setState({ apps });

            }).catch((rejected: RejectReason) => {
                console.error(rejected);
                notificationService.rejected(rejected);

            }).finally(() => {
                this.setState({ isLoading: false });
            });
    }

    override render = () => {
        const { apps, isLoading } = this.state || {};

        if (isLoading) {
            return (
                <Grid container spacing={2}>
                    {[0,1,2,3].map(p => 
                    <Grid key={p} xs={12} sm={6} md={4} lg={3}>
                        <SkeletonTile key={p} style={tileStyle} />
                    </Grid>
                    )}
                </Grid>
            );
        }

        return (
            <Grid container spacing={2}>
                {apps?.apps.map((p, i) => {
                    const href = routes.getById(p.app);
                    return (
                        <Grid key={i} xs={12} sm={6} md={4} lg={3}>
                            <Application 
                                key={p.app}
                                id={p.app}
                                name={p.name} 
                                description={p.description} 
                                tileColor={p.tile_color}
                                textColor={p.text_color}
                                href={href}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        );
    }
}

export default Home;
