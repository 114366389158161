import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { RejectReason } from "@common/models";
import { notificationService } from "@common/services/notification";
import { api } from "@todos/services/api";
import { ITodoList } from "src/todos/models";
import { Breadcrumb } from "@common/components/elements/breadcrumb";
import { routes } from "@config/routes";
import style from "./style.module.css";
import { SkeletonText, SkeletonTile } from "@common/components/elements/skeleton";
import Title from "@common/components/elements/title";
import { LinkButton } from "@common/components/elements/link";
import { BackButton } from "@common/components/elements/backButton";
import { List, ListItem, ListItemIcon, ListItemText, ListSubheader } from "@common/components/elements/list";
import { Add, ChevronRight, People } from "@common/components/icons";
import { useTheme } from "@mui/material";
import IconButton from "@common/components/forms/iconButton";
import Fab from "@common/components/elements/fab";

interface IProps {
}

interface IState {
    lists: ITodoList[],
    isLoading: boolean,
    //rows
    confirmDeleteIds: Set<string>,
    isDeletingIds: Set<string>,
    loadingIds: Set<string>,
}

class TodoLists extends React.Component<RouteComponentProps<IProps>, IState> {
    constructor(props: RouteComponentProps<IProps>) {
        super(props);

        this.state = {
            lists: [],
            isLoading: true,
            confirmDeleteIds: new Set(),
            isDeletingIds: new Set(),
            loadingIds: new Set(),
        };
    }

    override componentDidMount = () => {
        api.getTodoLists()
            .then(data => {
                this.setState({
                    lists: data as ITodoList[],
                    isLoading: false,
                });
            })
            .catch((rejected: RejectReason) => {
                this.setState({
                    lists: [],
                    isLoading: false,
                });
                notificationService.rejected(rejected);
            });
    };

    promptDeleteList = (listId: string) => {
        const { confirmDeleteIds } = this.state;
        confirmDeleteIds.add(listId);

        this.setState({
            confirmDeleteIds: new Set(confirmDeleteIds),
        })
    }

    confirmDeleteList = (listId: string) => {
        const { isDeletingIds } = this.state;
        isDeletingIds.add(listId);

        this.setState({ 
            isDeletingIds: new Set(isDeletingIds) 
        }, () => {
            api.deleteTodoList(listId)
                .then((_: void | RejectReason) => {
                    const { lists, isDeletingIds, confirmDeleteIds } = this.state;
                    const newLists = lists.filter(p => p.list_id !== listId);
                    
                    confirmDeleteIds.delete(listId);
                    isDeletingIds.delete(listId);

                    this.setState({
                        lists: newLists,
                        isDeletingIds: new Set(isDeletingIds),
                        confirmDeleteIds: new Set(confirmDeleteIds),
                    });
                })
                .catch((_rejected: RejectReason) => {
                    isDeletingIds.delete(listId);
                    this.setState({
                        isDeletingIds: new Set(isDeletingIds),
                    });
                });
            }
        );
    }

    cancelDeleteList = (listId: string) => {
        const { confirmDeleteIds } = this.state;
        confirmDeleteIds.delete(listId);

        this.setState({
            confirmDeleteIds: new Set(confirmDeleteIds),
        })
    }

    newList = () => ({ description: "", list_id: "", is_owner: true, is_shared: false, });

    addList = () => {
        this.props.history.push(routes.todos.listAdd);
    }

    editList = (listId: string) => {
        this.props.history.push(routes.todos.listEdit(listId));
    }

    descriptionComparer(a: ITodoList, b: ITodoList) {
        if ( a.description < b.description ) {
            return -1;
        }
        if ( a.description > b.description ) {
            return 1;
        }
        return 0;
    } 

    viewList = (listId: string) => {
        this.props.history.push(routes.todos.list(listId));
    }

    override render = () => {
        const { isLoading, lists, loadingIds } = this.state;
        const { history } = this.props;

        let content: JSX.Element;
        if (isLoading) {
            content = (
                <>
                    <BackButton onClick={() => { history.replace(routes.home); }} />
                    <SkeletonText className={style.rows_h_skeleton} />
                    <List className={style.rows_skeleton}>
                        {[0, 1, 2, 3, 4].map((p) => 
                        <ListItem 
                            key={p} 
                            className={style.row_skeleton}
                            dense={true}>
                            <SkeletonTile className={style.cell_skeleton} />
                        </ListItem>)}
                    </List>
                </>
            );
        } else {
            content = (
                <>
                    <BackButton onClick={() => { history.replace(routes.home); }} />
                    <List 
                        className={style.rows} 
                        subheader={
                            <ListSubheader id="todo_lists" className={style.sort_header} component="div">
                                <div className={style.sort_title}>
                                    <Title size="md" text="Lists" />
                                </div>
                            </ListSubheader>
                        }>
                        {lists.map((p, i) => {
                            return (
                                <DefaultRow data-id={p.list_id} key={p.list_id} index={i}
                                    list={p} 
                                    onView={() => this.viewList(p.list_id)} 
                                    onEdit={() => this.editList(p.list_id)} 
                                    isLoading={loadingIds.has(p.list_id)}
                                />
                            );
                        })}
                    </List>
                    <div style={{ 
                            position: "fixed", 
                            bottom: "1rem", 
                            right: "1rem",
                            zIndex: 101,
                        }}>
                        <Fab
                            disabled={isLoading}
                            icon={<Add color="#000" />}
                            onClick={() => this.addList()} 
                            label="Add an item"
                            color="primary"
                        />  
                    </div>
                </>
            );
        }

        return (
            <>
                <Breadcrumb path={[
                    { href: routes.home, title: "Home" },
                    { href: "", title: "Lists" },
                ]} isLoading={isLoading} />
                {content}
            </>
        );
    }
}

interface IDefaultRowProps {
    index: number,
    list: ITodoList,
    isLoading?: boolean,
    onEdit: (listId: string) => void,
    onView: (listId: string) => void,
}

const DefaultRow: React.FC<IDefaultRowProps> = ({ index, list, isLoading, onEdit, onView }) => {
    const theme = useTheme();
    const isAlt = index % 2 === 0;

    return (
        <ListItem 
            data-id={list.list_id} 
            key={list.list_id} 
            className={style.todo_row}
            dense={true}
            style={{ 
                backgroundColor: !isAlt ? theme.palette.background.default : "#191919",
                paddingTop: 8,
                paddingLeft: 0,
                paddingRight: 0,
            }}>
                <ListItemText
                    primary={<LinkButton underline="hover">{list.description}</LinkButton>}
                    style={{ 
                        flexGrow: 1,
                        paddingLeft: "1rem",
                    }}
                    onClick={() => {
                        onView && onView(list.list_id);
                    }}
                />
                {list.is_shared && 
                <ListItemText
                    title="This is a shared list"
                    primary={
                        <People />
                        //<Tag label="Shared" />
                    }
                    style={{ 
                        flexGrow: 0,
                        paddingRight: ".5rem",
                    }}
                    onClick={() => {
                        onView && onView(list.list_id);
                    }}
                />
                }
                <ListItemIcon style={{ minWidth: 36 }}>
                    <IconButton size="medium" disabled={isLoading} 
                        icon={<ChevronRight />}
                        onClick={() => { 
                            onEdit && onEdit(list.list_id);
                        }}/>
                </ListItemIcon>
        </ListItem>
    );
}

export default withRouter(TodoLists);