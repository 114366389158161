import { uuidService } from "@common/services/uuid";
import { IEstimate, ModifierType } from "@estimator/models";

// DEFAULTS

export const defaultLogin = {
    email: "",
    password: "",
}

// CONFIG SETTINGS

export const APP_NAME = "BLACK TIGER APPS";
export const ZEBRA_ROWS_ENABLED = true;
export const GOOGLE_AUTH_CLIENT_ID = "514342963316-ufnosius82935316n3ghqe45rt4ruc9m.apps.googleusercontent.com";
export const GOOGLE_AUTH_SCRIPT_URI = "https://accounts.google.com/gsi/client";

// ROUTING

export const apiBase = "https://api.blacktiger.digital/";
export const appBase = "https://blacktiger.digital/";
export const wsBase = "wss://api.blacktiger.digital/";

const routeBase = "";
export const routerAbsoluteBase = appBase + routeBase;

// FACTORIES

export const estimatorDataFactory = (nextId: () => number): IEstimate => ({
    tempId: nextId(),
    id: uuidService.NIL,
    title: "",
    options: {
        show_sub_totals: true,
        default_rate: 0.0,
        modifiers: [{
            tempId: nextId(),
            name: "",
            modifier_type: ModifierType.Fixed,
            value: 0,
        }]
    },
    nodes: [{
        tempId: nextId(),
        title: "",
        high: 0,
        low: 0,
        nodes: [
        ],
    }],
});