import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { RejectReason, Uuid } from "@common/models";
import { notificationService } from "@common/services/notification";
import { api } from "@todos/services/api";
import { IFilterType, ITodo, ITodoId, ITodoIndex, ITodoList, ITodoOrder } from "src/todos/models";
import TextField from "@common/components/forms/textField";
import { errorFor, IVErrorsKind } from "@common/models/validation";
import { Breadcrumb } from "@common/components/elements/breadcrumb";
import { routes } from "@config/routes";
import { Form } from "@common/components/forms/form";
import { Add, Admin, ManageAccounts, RemoveDone, SwapVert } from "@common/components/icons";
import { Tag } from "@common/components/elements/tag";
import { Modal, ModalAction } from "@common/components/elements/modal";
import { todoService } from "@todos/services/todoService";
import { List, ListButtonFn } from "@common/components/elements/list";
import { Drawer } from "@common/components/elements/drawer";
import { SortList } from "./SortList";
import { DropResult, ResponderProvided } from "react-beautiful-dnd";
import style from "./style.module.css";
import { ws, WSInbound, WSMessageCallback } from "@todos/services/ws";
import { Fab } from "@common/components/elements/fab";
import { DefaultList } from "./DefaultList";
import Checkbox from "@common/components/forms/checkbox";

function friendlyUserName(forUserInfo: WSInbound.UserInfo) {
    const current = ws.userInfo?.user_name ?? ws.userInfo?.user_email ?? "";
    const forUserName = forUserInfo?.user_name ?? forUserInfo?.user_email ?? "";

    return forUserName === current ? "you" : forUserName;
}

function displayInitial(forUserInfo: WSInbound.UserInfo) {
    return displayName(forUserInfo).substring(0, 1);
}

function displayName(forUserInfo: WSInbound.UserInfo) {
    return (forUserInfo?.user_name ?? forUserInfo?.user_email ?? "");
}

type TodoLookup = { [key: string]: ITodo };

interface IProps {
    id?: string,
}

interface IState {
    todos: TodoLookup,
    todoOrders: Array<ITodoOrder>,
    todoLists: Array<ITodoList>,
    tags: Set<string>,
    filter: IFilterType,
    changed: Set<string>,
    submitting: Set<string>,
    isLoading: boolean,
    drawerOpen: boolean,
    // new
    showAdd: boolean,
    addDescription: string,
    isAdding: boolean,
    validationErrors: IVErrorsKind | undefined,
    addAnother: boolean,
    // remove completed
    isPromptRemove: boolean,
    isRemoving: boolean,
    // reordering
    isReordering: boolean,
    pendingReorder: boolean,
    mode: "default" | "reorder", 
    usersOnline: Array<WSInbound.UserInfo>,
}

class Todos extends React.Component<RouteComponentProps<IProps>, IState> {
    constructor(props: RouteComponentProps<IProps>) {
        super(props);

        this.state = {
            mode: "default",
            todos: {},
            todoOrders: [],
            todoLists: [],
            filter: { tag: "" },
            tags: new Set(),
            changed: new Set(),
            submitting: new Set(),
            isLoading: true,
            addDescription: "",
            isAdding: false,
            addAnother: true,
            validationErrors: undefined,
            isRemoving: false,
            isPromptRemove: false,
            isReordering: false,
            pendingReorder: false,
            drawerOpen: false,
            usersOnline: [],
            showAdd: false,
        };
    }

    list_id: Uuid = "";

    wsListener: WSMessageCallback = (message) => {
        console.log("Todos RECV", message);

        {
            const cast = (message as unknown as WSInbound.ListChanged);
            if (cast && cast.ListChanged) {
                console.log("ListChanged", cast);
                this.setState({ 
                    usersOnline: cast.ListChanged.users, 
                })
                return;
            }
        }

        {
            const cast = (message as unknown as WSInbound.TodoAdded);
            if (cast && cast.TodoAdded) {
                // Add to todos and notify user of external change
                const { todo, user_info } = cast.TodoAdded;
                const { todos } = this.state;
                const partialState = this.todosToPartialState([...Object.entries(todos).map(p => p[1]), todo]);
                this.setState(partialState as IState, () => notificationService.info("Info", `'${todo.description}' was added by ${friendlyUserName(user_info)}.`));
                return;
            }
        }

        {
            const cast = (message as unknown as WSInbound.TodoUpdated);
            if (cast && cast.TodoUpdated) {
                // Add to todos and notify user of external change
                const { todo, user_info } = cast.TodoUpdated;
                const { todos } = this.state;
                todos[todo.todo_id] = todo;

                const partialState = this.todosToPartialState([...Object.entries(todos).map(p => p[1])]);
                this.setState(partialState as IState, () => notificationService.info("Info", `'${todo.description}' was updated by ${friendlyUserName(user_info)}.`));
                return;
            }
        }

        {
            const cast = (message as unknown as WSInbound.TodoRemoveDone);
            if (cast && cast.TodoRemoveDone) {
                // Add to todos and notify user of external change
                const { todo_ids, user_info } = cast.TodoRemoveDone || [];
                let { todos } = this.state;

                todo_ids.forEach(p => delete(todos[p]));

                const partialState = this.todosToPartialState([...Object.entries(todos).map(p => p[1])]);
                this.setState(partialState as IState, () => notificationService.info("Info", `${todo_ids.length} completed task${todo_ids.length > 1 ? "s were" : " was"} removed by ${friendlyUserName(user_info)}.`));
                return;
            }
        }
    }

    todosToPartialState = (todosArray: ITodo[]): Partial<IState> => {
        const tags = new Set<string>();
        todosArray.forEach(todo => todoService.extractTags(tags, todo));

        const todos = this.toLookup(todosArray);
        return { todos, tags };
    }

    override componentDidMount = () => {
        this.list_id = this.props.match?.params?.id || "";

        api.getTodos(this.list_id)
            .then(data => {
                ws.subscribe(this.wsListener, this.list_id);

                const root = data as ITodoIndex;
                const partialState = this.todosToPartialState(root.todos);
                
                this.setState(Object.assign(partialState, {
                    todoOrders: root.todo_orders,
                    todoLists: root.todo_lists,
                    isLoading: false,
                } as IState), () => {
                    setTimeout(function () {
                        document.getElementById("add-todo")?.focus();
                    }, 1)
                });
            })
            .catch((rejected: RejectReason) => {
                const validationErrors = notificationService.rejected(rejected);
                this.setState({
                    todos: {},
                    isLoading: false,
                    validationErrors: validationErrors || undefined,
                }, () => {
                    setTimeout(function () {
                        document.getElementById("add-todo")?.focus();
                    }, 1)
                });
            });
    };

    override componentWillUnmount = () => {
        ws.close();
    }

    toLookup(todos: ITodo[]) {
        const lookup: TodoLookup = {};
        for (const p of todos) {
            lookup[p.todo_id] = p;
        }
        return lookup;
    }

    reorder = (list: Array<ITodo>, startIndex: number, endIndex: number): Array<ITodo> => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
    
        return result;
    };

    todoChanged = (todo: ITodo, persist: boolean) => {
        const { todos, submitting, changed, tags } = this.state;

        const foundTodo = todos[todo.todo_id];
        if (foundTodo) {
            if (!changed.has(todo.todo_id)) {
                const oldJson = JSON.stringify(foundTodo);
                const newJson = JSON.stringify(todo);

                if (oldJson !== newJson) {
                    changed.add(todo.todo_id);
                }
            }

            todos[todo.todo_id] = todo;
        }

        if (!persist || !changed.has(todo.todo_id)) {
            this.setState({
                todos: Object.assign({}, todos),
            });
        } else {
            const newSet = new Set(submitting);
            newSet.add(todo.todo_id);

            todoService.transformTags(todo);

            this.setState({
                todos: Object.assign({}, todos),
                tags: todoService.extractTags(new Set(tags), todo),
                submitting: newSet,
            }, () => {
                    api.postTodo(todo)
                        .then(() => {
                            const changed = new Set(this.state.changed);
                            changed.delete(todo.todo_id);

                            this.setState({
                                changed: changed,
                            });
                        })
                        .catch((err: RejectReason) => {
                            const validationErrors = notificationService.rejected(err);
                            if (validationErrors) {
                                this.setState({ validationErrors });
                            }
                        })
                        .finally(() => {
                            const completed = new Set(this.state.submitting);
                            completed.delete(todo.todo_id);

                            this.setState({
                                submitting: completed,
                            });
                        });
            });
        }
    };

    descriptionChanged = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ addDescription: e.currentTarget.value });
    };

    add = (event?: React.FormEvent) => {
        const { addDescription } = this.state;
        event?.preventDefault();
        event?.stopPropagation();

        this.setState({ isAdding: true });
        
        const todo: ITodo = todoService.newTodo(this.list_id, addDescription);
        todoService.transformTags(todo);

        return api.postTodo(todo)
            .then((data: ITodo | RejectReason) => {
                const { todos, tags } = this.state;
                const todo = data as ITodo;

                this.setState({
                    todos: Object.assign({}, todos, { [todo.todo_id]: todo }),
                    tags: todoService.extractTags(new Set(tags), todo),
                    addDescription: "",
                    validationErrors: undefined,
                });
            }).catch((err: RejectReason) => {
                const validationErrors = notificationService.rejected(err);
                if (validationErrors) {
                    console.log(validationErrors);
                    this.setState({ validationErrors });
                }
                return Promise.reject();
            }).finally(() => {
                this.setState({
                    isAdding: false,
                }, () => {
                    setTimeout(function () {
                        document.getElementById("add-todo")?.focus();
                    }, 1);
                });
            });
    }

    promptRemove = () => {
        this.setState({
            isPromptRemove: true,
            drawerOpen: false,
        })
    }

    confirmRemove = () => {
        this.setState({ isRemoving: true }, () => {
            api.deleteTodosDone(this.list_id)
                .then((ids: ITodoId[] | RejectReason) => {
                    const { todos } = this.state;
                    const newTodos = Object.assign({}, todos);
                    const todo_ids = ids as ITodoId[];

                    for (const key in newTodos) {
                        if (Object.prototype.hasOwnProperty.call(todos, key)) {
                            const todo = newTodos[key];
                            if (todo_ids.findIndex(p => p.todo_id === todo.todo_id) !== -1) {
                                delete newTodos[key];
                            }
                        }
                    }

                    this.setState({
                        todos: newTodos,
                        isRemoving: false,
                        isPromptRemove: false,
                    });
                })
                .finally(() => {
                    this.setState({
                        isPromptRemove: false,
                    })
                });
        });
    }

    cancelRemove = () => {
        this.setState({
            isPromptRemove: false,
        })
    }

    filtersEqual(a: IFilterType, b: IFilterType): boolean {
        return a.tag === b.tag || (a.tag === undefined && b.tag === undefined);
    }

    onDragEnd = (result: DropResult, _provided: ResponderProvided) => {
        const destination = result.destination;
        if (!destination)
            return;

        const sourceIndex = result.source.index; 
        const destinationIndex = destination.index;

        const { filter, todoOrders } = this.state;
        const maybeFiltered = this.filterTodos();
        const reordered = this.reorder(maybeFiltered, sourceIndex, destinationIndex);
        
        let todoOrder = todoOrders.find(p => this.filtersEqual(p, filter));
        if (todoOrder) {
            todoOrder.todo_ids = reordered.map(p => p.todo_id);
        } else {
            todoOrder = {
                list_id: this.list_id,
                tag: filter.tag,
                todo_ids: reordered.map(p => p.todo_id),
            }
        }
        
        this.setState({ 
            todoOrders: [todoOrder, ...todoOrders.filter(p => !this.filtersEqual(p, filter))],
            pendingReorder: true 
        });
    }

    saveOrder = () => {
        const { filter } = this.state;
        const reordered = this.filterTodos();

        this.setState({ 
            pendingReorder: true, 
            isReordering: true 
        }, () => {
            api.postTodoOrder(this.list_id, reordered, filter)
                .then(() => {
                    this.setState({ 
                        isReordering: false, 
                        pendingReorder: false,
                        mode: "default",
                    });
                })
                .catch(err => {
                    notificationService.rejected(err);
                    this.setState({ 
                        isReordering: false, 
                    });
                });
        });
    }

    filterByTag = (selected: string) => {
        const { filter } = this.state;
        const tag = (filter?.tag === selected) ? "" : selected;

        this.setState({ filter: { tag } });
    }

    matchFilter = (todo: ITodo) => {
        const { filter } = this.state;

        if (filter === undefined || filter.tag === undefined || filter.tag === "")
            return true;

        const filterTag = filter?.tag || "";
        const tags = todo.tags;
        return tags && tags.findIndex(p => p === filterTag) !== -1;
    }

    filterTodos = () => {
        const { filter, todos, todoOrders } = this.state;
        const ordered: Array<ITodo> = [];
        
        let filtered: TodoLookup = {};

        // Apply filter
        const filterTag = filter?.tag || "";
        if (filterTag !== "") {
            for (const todo_id in todos) {
                if (Object.prototype.hasOwnProperty.call(todos, todo_id)) {
                    const todo = todos[todo_id];
                    if (this.matchFilter(todo)) {
                        filtered[todo_id] = todo;
                    }
                }
            }
        } else {
            filtered = Object.assign({}, todos);
        }

        // Apply order
        const order = todoOrders.find(p => this.filtersEqual(p, filter));
        if (order) {
            for (const todo_id of order?.todo_ids) {
                const inFiltered = filtered[todo_id];
                if (inFiltered) {
                    ordered.push(inFiltered);
                    delete filtered[todo_id];
                }
            }
        }
        for (const key in filtered) {
            if (Object.prototype.hasOwnProperty.call(filtered, key)) {
                ordered.push(filtered[key]);
            }
        }

        return ordered;
    }

    viewDetails = (todoId: string) => {
        this.props.history.push(routes.todos.todo(todoId));
    }

    defaultMode = () => {
        this.setState({ mode: "default" });
    }

    reorderMode = () => {
        this.setState({ mode: "reorder", drawerOpen: false });
    }

    setDrawerOpen = (open: boolean) => {
        this.setState({ drawerOpen: open });
    }

    administerList = () => {
        this.props.history.push(routes.todos.listEdit(this.list_id));
    }

    sharelist = () => {
        this.props.history.push(routes.todos.sharelist(this.list_id));
    }

    override render = () => {
        const { history } = this.props;
        const { 
            usersOnline, drawerOpen, filter, tags, 
            todoLists, submitting, isLoading, addDescription, 
            isPromptRemove, isReordering, pendingReorder, mode, 
            showAdd, addAnother 
        } = this.state;
        const maybeTags = [...tags].sort();
        const maybeFiltered = this.filterTodos();
        
        const users = (usersOnline || []);
        const list = todoLists.find(p => p.list_id === this.list_id);
        const listName = list?.description || "...";
        const listIsShared = list?.is_shared || false;

        return (
            <>
                <div className={style.lead}>
                    <Breadcrumb isLoading={isLoading} path={[
                        { href: routes.home, title: "Home" },
                        { href: routes.todos.lists, title: "Lists" },
                        { href: "", title: "Entries" },
                    ]} />

                    {users.length > 1 && 
                        <div className={style.connections}>
                            {users.map((p, i) => <span key={i} title={`${displayName(p)} is online`}>&nbsp;<Tag label={displayInitial(p)} /></span>)}
                        </div>
                    }
                </div>

                {maybeTags.length > 0 && 
                    <div className={style.todo_tags}>
                        {maybeTags.map(p => {
                            const filterTag = filter?.tag || "";
                            const colour = filterTag.length > 0 && filterTag === p ? "secondary" : "default";
                            return (
                                <Tag key={p} label={p} color={colour} onClick={() => this.filterByTag(p)} />
                            );
                        })}
                    </div>
                }

                {mode == "default" && 
                    <>
                        <Modal heading="Add new"
                            open={showAdd}
                            primaryText="Add"
                            secondaryText=""
                            requestClose={(action) => {
                                switch (action) {
                                    case "primary": {
                                        return this.add()
                                            .then(() => this.setState({ showAdd: addAnother }))
                                            .catch(() => {})
                                    }
                                    case "close":
                                    case "secondary": {
                                        this.setState({ showAdd: false, addDescription: "", validationErrors: undefined });
                                        return Promise.resolve();
                                    }
                                }
                            }}>
                            <div className={style.add_todo}>
                                <Form id="add-form" label="" onSubmit={this.add}>
                                    <div style={{ display: "flex", flexGrow: 1, marginTop: "6px", marginRight: ".5rem" }}>
                                        <TextField
                                            id="add-todo"
                                            label="Description"
                                            rightLabel={`${addDescription.length}/255`}
                                            value={addDescription}
                                            onChange={this.descriptionChanged}
                                            onBlur={this.descriptionChanged}
                                            readonly={isLoading}
                                            autoCapitalize="sentences"
                                            isLoading={isLoading}
                                            errorMessage={errorFor(this.state, "description")}                                            
                                        />
                                    </div>
                                    <div style={{ display: "flex", flexGrow: 1, position: "absolute", bottom: 6, marginTop: "6px", marginRight: ".5rem" }}>
                                        <Checkbox id="add-more" label="Add another" onChange={p => this.setState({ addAnother: p })} checked={addAnother} />
                                    </div>
                                </Form>
                            </div>
                        </Modal>
                        <DefaultList 
                            isLoading={isLoading}
                            listName={listName}
                            isShared={listIsShared}
                            onItemChange={this.todoChanged}
                            onItemDetails={this.viewDetails}
                            onShowOptions={() => this.setDrawerOpen(true)}
                            onShowSharing={this.sharelist}
                            items={maybeFiltered} 
                            loadingIds={submitting}
                            onBack={() => { history.replace(routes.todos.lists); }}
                        />
                        <div style={{ 
                                position: "fixed", 
                                bottom: "1rem", 
                                right: "1rem",
                                zIndex: 101,
                            }}>
                            <Fab
                                disabled={isLoading}
                                icon={<Add color="#000" />}
                                onClick={() => this.setState(
                                    { showAdd: true }, 
                                    () => setTimeout(() => {
                                        document.getElementById("add-todo")?.focus();
                                    }, 10))} 
                                label="Add an item"
                                color="primary"
                            />  
                        </div>
                    </>
                }
                {mode == "reorder" && 
                    <SortList 
                        listName={listName}
                        onDragEnd={this.onDragEnd}
                        items={maybeFiltered}
                        onSave={this.saveOrder}
                        isPending={pendingReorder}
                        isSaving={isReordering}
                        onBack={this.defaultMode}
                    />
                }
                <Modal 
                    open={isPromptRemove} 
                    heading={"Remove all items marked completed?"} 
                    primaryText={"Remove completed"} 
                    danger={true}
                    secondaryText={"Cancel"} 
                    requestClose={(action: ModalAction) => {
                        switch (action) {
                            case "primary": {
                                this.confirmRemove();
                                break;
                            }
                            case "secondary":
                            default: {
                                this.cancelRemove();
                                break;
                            }
                        }
                        return Promise.resolve();
                    }}>
                </Modal>
                <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={() => this.setDrawerOpen(false)}
                >
                    <List>
                        {ListButtonFn("administer", "Administer", <Admin/>, this.administerList)}
                        {ListButtonFn("remove", "Remove completed", <RemoveDone/>, this.promptRemove)}
                        {ListButtonFn("reorder", "Reorder entries", <SwapVert/>, this.reorderMode)}
                        {ListButtonFn("permissions", "Permissions", <ManageAccounts/>, this.sharelist)}
                    </List>
                </Drawer>
            </>
        );
    }
}

export default withRouter(Todos);