import { apiBase } from "@config/settings";
import { apiDelete, apiGet, apiPost, expectEmpty, expectJson } from "@common/services/api";
import { RejectReason } from "@common/models";
import { IFitnessDiaryEntry, IFitnessDiaryEntryRow } from "@fitness/models";

const SERVER_PATH = `${apiBase}api/v0/fitness/`;

/**
 * Api endpoints
 */
 export const api = {
    /**
     * GET a fitness diary entry
     * @returns Promise<Array<IFitnessDiaryEntry | RejectReason>>
     */
    getDiaryEntry: () => apiGet(SERVER_PATH + "diary/entry")
        .then<IFitnessDiaryEntry | RejectReason>(resp => expectJson(resp, "Failed to fetch the fitness diary entry.")),
    
    /**
     * POST a fitness diary entry
     * @returns Promise<Array<IFitnessDiaryEntry | RejectReason>>
     */
     postDiaryEntry: (entry: IFitnessDiaryEntry) => apiPost(SERVER_PATH + "diary/entry", entry)
        .then<IFitnessDiaryEntry | RejectReason>(resp => expectJson(resp, "Failed to save the fitness diary entry.")),

    /**
     * DELETE a fitness diary entry
     * @param id the fitness diary entry id
     * @returns Promise<Array<() | RejectReason>>
     */
    deleteDiaryEntry: (id: string) => apiDelete(SERVER_PATH + "diary/entry", { id })
        .then<void | RejectReason>(resp => expectEmpty(resp, "Failed to delete the fitness diary entry.")),

    /**
     * GET the list of fitness diary entries for the current user.
     * @returns Promise<Array<IFitnessDiaryEntryRow | RejectReason>>
     */
    getDiaryEntryList: () => apiGet(SERVER_PATH + "diary/recent", {})
        .then<Array<IFitnessDiaryEntryRow> | RejectReason>(resp => expectJson(resp, "Failed to fetch fitness diary entries.")),
};