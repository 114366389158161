import React from "react"
import ReactDOM from "react-dom"
import { RouteComponentProps } from "react-router";
import { BrowserRouter, HashRouter, Route, Switch } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { routes } from "@config/routes";

import Home from "@auth/routes/home";
import Signin from "@auth/routes/signin";
import Signup from "@auth/routes/signup";
import Secure from "@auth/components/secure";
import Header from "@auth/components/header";
import Main from "@auth/components/main";
import { INotification, notificationService } from "@common/services/notification";
import Notifications from "@common/components/elements/notifications";
import Estimator from "@estimator/routes/estimate";
import Projects from "@estimator/routes/projects";
import UuidGenerator from "@uuid/routes/uuid_generator";
import IPEcho from "@ipecho/routes/ipecho";
import Todos from "@todos/routes/todos";
import TodoLists from "@todos/routes/lists";
import Todo from "@todos/routes/todo";
import ShareTodoList from "@todos/routes/sharelist";
import Fitness from "@fitness/routes/home";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import "./assets/styles.scss"
import './main.module.css';
import { api } from "@auth/services/api";
import Notfound from "@common/routes/notfound";
import Profile from "./profile/routes/profile";
import ListEdit from "@todos/routes/list/edit";
import ListAdd from "@todos/routes/list/add";
import Holdings from "./trade/routes/holdings";

function router(component: JSX.Element) {
    if ((window as any).cordova) {
        return <HashRouter basename="">{component}</HashRouter>
    } else {
        return <BrowserRouter>{component}</BrowserRouter>
    }
}

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

interface IProps {
}
interface IState {
    notifications: Array<INotification>,
    isLoggedIn: boolean,
}
class App extends React.Component<IProps, IState> {
    constructor(props: RouteComponentProps<IProps>) {
        super(props);
        this.state = { notifications: [], isLoggedIn: false };
    }

    mounted = false;
    isLoggedIn = false;

    override componentDidMount = () => {
        notificationService.initialize(q => this.setState({ notifications: q }));
        this.mounted = true;
    }

    override componentWillUnmount = () => {
        notificationService.free();
        this.mounted = false;
    }

    onRouterChange = (history: any) => {
        const old = this.isLoggedIn;
        this.isLoggedIn = api.isLoggedIn();
        
        if (!this.isLoggedIn && history.location.pathname !== routes.auth.signin && history.location.pathname !== routes.auth.signup) {
            console.log(`UNAUTHORIZED redirect to ${routes.auth.signin}`);
            history.replace(routes.auth.signin);
        }

        if (this.mounted && old !== this.isLoggedIn) {
            this.setState({ isLoggedIn: this.isLoggedIn });
        }
    }

    override render = () => {
        const { notifications } = this.state;

        return (
            <React.Fragment>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <ThemeProvider theme={darkTheme}>
                        <CssBaseline />
                        {router(
                        <>
                            <React.Fragment>
                                <Header />
                                <Main>
                                    <Switch>
                                        <Route exact key="root" path="/">
                                            <Secure><Home /></Secure>
                                        </Route>
                                        <Route key={routes.auth.signin} path={routes.auth.signin}><Signin /></Route>
                                        <Route key={routes.auth.signup} path={routes.auth.signup}><Signup /></Route>
                                        <Route key={routes.estimator.projects} path={routes.estimator.projects}>
                                            <Secure>
                                                <Projects />
                                            </Secure>
                                        </Route>
                                        <Route key={routes.estimator.addEstimate} path={routes.estimator.addEstimate}>
                                            <Secure>
                                                <Estimator />
                                            </Secure>
                                        </Route>
                                        <Route key={routes.estimator.estimatePath} path={routes.estimator.estimatePath}>
                                            <Secure>
                                                <Estimator />
                                            </Secure>
                                        </Route>
                                        <Route key={routes.uuid} path={routes.uuid}>
                                            <Secure>
                                                <UuidGenerator />
                                            </Secure>
                                        </Route>
                                        <Route key={routes.todos.listAdd} path={routes.todos.listAdd}>
                                            <Secure>
                                                <ListAdd />
                                            </Secure>
                                        </Route>
                                        <Route key={routes.todos.listEditPath} path={routes.todos.listEditPath}>
                                            <Secure>
                                                <ListEdit />
                                            </Secure>
                                        </Route>
                                        <Route key={routes.todos.sharelistPath} path={routes.todos.sharelistPath}>
                                            <Secure>
                                                <ShareTodoList />
                                            </Secure>
                                        </Route>
                                        <Route key={routes.todos.listPath} path={routes.todos.listPath}>
                                            <Secure>
                                                <Todos />
                                            </Secure>
                                        </Route>
                                        <Route key={routes.todos.lists} path={routes.todos.lists}>
                                            <Secure>
                                                <TodoLists />
                                            </Secure>
                                        </Route>
                                        <Route key={routes.todos.todoPath} path={routes.todos.todoPath}>
                                            <Secure>
                                                <Todo />
                                            </Secure>
                                        </Route>
                                        <Route key={routes.ipecho} path={routes.ipecho}>
                                            <Secure>
                                                <IPEcho />
                                            </Secure>
                                        </Route>
                                        <Route key={routes.profile} path={routes.profile}>
                                            <Secure>
                                                <Profile />
                                            </Secure>
                                        </Route>
                                        <Route key={routes.fitness.home} path={routes.fitness.home}>
                                            <Secure>
                                                <Fitness />
                                            </Secure>
                                        </Route>
                                        <Route key={routes.trade.holdings} path={routes.trade.holdings}>
                                            <Secure>
                                                <Holdings />
                                            </Secure>
                                        </Route>
                                        {/* <Route key={routes.mealplanner.home} path={routes.mealplanner.home}>
                                            <Secure>
                                                <MealPlanner />
                                            </Secure>
                                        </Route> */}
                                        <Route path="*">
                                            <Notfound />
                                        </Route>
                                    </Switch>
                                </Main>
                            </React.Fragment>
                            <div id="modals"></div>
                            <div id="footer"></div>
                            <Notifications notifications={notifications} />
                        </>
                        )}
                    </ThemeProvider>
                </LocalizationProvider>
            </React.Fragment>
        );
    }
};

if ((window as any).cordova) {
    document.addEventListener('deviceready', function () {
        ReactDOM.render(<React.StrictMode><App/></React.StrictMode>, document.getElementById("root"));
    }, false);
} else {
    ReactDOM.render(<React.StrictMode><App/></React.StrictMode>, document.getElementById("root"));
}
