import { apiBase } from "@config/settings";
import { apiDelete, apiGet, apiPost, expectEmpty, expectJson } from "@common/services/api";
import { RejectReason } from "@common/models";
import { IFilterType, ITodo, ITodoId, ITodoIndex, ITodoList, ITodoListUser, ITodoListUserIndexResult } from "../models";

const SERVER_PATH = `${apiBase}api/v0/tasks/`;

export interface ITodoGetResult { 
    todo: ITodo, 
    list: ITodoList,
}

/**
 * Api endpoints
 */
 export const api = {
     /**
     * GET the list of todos for the current user.
     * @returns Promise<Array<ITodoIndexRow | RejectReason>>
     */
    getTodoLists: () => apiGet(SERVER_PATH + "lists")
        .then<ITodoList[] | RejectReason>(resp => expectJson(resp, "Failed to fetch task lists.")),
    /**
     * GET the specified list for the current user
     * @param id the list id
     * @returns Promise<ITodoList | RejectReason>
     */
    getTodoList: (id: string) => apiGet(SERVER_PATH + "list", { id })
         .then<ITodoList | RejectReason>(resp => expectJson(resp, "Failed to fetch the specified list.")),

    /**
     * POST a task list definition
     * @returns Promise<Array<ITodoList | RejectReason>>
     */
    postTodoList: (list: ITodoList) => apiPost(SERVER_PATH + "list", list)
        .then<ITodoList | RejectReason>(resp => expectJson(resp, "Failed to save the list.")),

    /**
     * DELETE a task list definition + all entries
     * @param id the list id
     * @returns Promise<Array<() | RejectReason>>
     */
    deleteTodoList: (id: string) => apiDelete(SERVER_PATH + "list", { id })
         .then<void | RejectReason>(resp => expectEmpty(resp, "Failed to delete the list.")),

    /**
     * GET the list of todos for the current user.
     * @returns Promise<Array<ITodoIndexRow | RejectReason>>
     */
    getTodos: (listId: string) => apiGet(SERVER_PATH + "index", { id: listId })
        .then<ITodoIndex | RejectReason>(resp => expectJson(resp, "Failed to fetch tasks.")),
    
    /**
     * GET the specified todo for the current user
     * @param id the todo id
     * @returns Promise<ITodo | RejectReason>
     */
    getTodo: (id: string) => apiGet(SERVER_PATH + "task", { id })
        .then<ITodoGetResult | RejectReason>(resp => expectJson(resp, "Failed to fetch the specified task.")),

    /**
     * POST the specified todo for the current user
     * @param todo the todo the persist
     * @returns Promise<ITodo | RejectReason>
     */
    postTodo: (todo: ITodo) => apiPost(SERVER_PATH + "task", todo)
        .then<ITodo | RejectReason>(resp => expectJson(resp, "Failed to save task.")), 

    /**
     * DELETE the specified todo for the current user
     * @param id the todo id
     * @returns Promise<ITodo | RejectReason>
     */
    deleteTodo: (id: string) => apiDelete(SERVER_PATH + "task", { id })
        .then<void | RejectReason>(resp => expectEmpty(resp, "Failed to delete the specified task.")),

    /**
     * DELETE the todos marked done for the current user list
     * @param id the todo list id
     * @returns Promise<ITodoId[] | RejectReason>
     */
    deleteTodosDone: (listId: string) => apiDelete(SERVER_PATH + "done", { id: listId })
        .then<ITodoId[] | RejectReason>(resp => expectJson(resp, "Failed to delete the completed tasks.")),

    /**
     * POST the specified todo order for the current user
     * @param todos the todos whose order is to be persisted
     * @returns Promise<void | RejectReason>
     */
    postTodoOrder: (list_id: string, todos: ITodo[], filter?: IFilterType) => apiPost(SERVER_PATH + "order", { 
            list_id,
            todo_ids: (todos || []).map(p => ({ todo_id: p.todo_id })),
            tag: filter?.tag ?? "", 
        })
        .then<void | RejectReason>(resp => expectEmpty(resp, "Failed to save task order.")), 

    /**
     * GET the list of users with permissions to the specified user.
     * @returns Promise<Array<ITodoListUserIndexResult | RejectReason>>
     */
    getTodoListUsers: (id: string) => apiGet(SERVER_PATH + "list_users", { id })
        .then<ITodoListUserIndexResult | RejectReason>(resp => expectJson(resp, "Failed to fetch task list users.")),

    /**
     * POST the specified email invitation to the list
     * @param list_id 
     * @param email
     * @returns Promise<void | RejectReason>
     */
    postInviteListUser: (list_id: string, email: string) => apiPost(SERVER_PATH + "list_user", { 
            list_id,
            email,
        })
        .then<ITodoListUser | RejectReason>(resp => expectJson(resp, "Failed to invite the user to the list.")), 

    /**
     * DELETE the specified email user from the list
     * @param list_id 
     * @param email
     * @returns Promise<void | RejectReason>
     */
     deleteInviteUser: (list_id: string, email: string) => apiDelete(SERVER_PATH + "list_user", { 
            list_id,
            email,
        })
        .then<void | RejectReason>(resp => expectEmpty(resp, "Failed to delete the user from the list.")), 
};