interface IProps {
    text: string,
}

export const SubTitle = ({ text }: IProps) => {
    return (
        <h2 className="subtitle">{text}</h2>
    );
};

export default SubTitle;