import { apiBase } from "@config/settings";
import { apiGet, expectJson } from "@common/services/api";
import { RejectReason } from "@common/models";

const SERVER_PATH = `${apiBase}api/v0/`;

export interface IIPAddressResult {
    ip_v4: string,
    ip_v6: string,
}

/**
 * Api endpoints
 */
 export const api = {
    /**
     * GET IP from the IP address echo service
     * @returns Promise<Array<IIPAddressResult | RejectReason>>
     */
    getIPAddress: () => apiGet(SERVER_PATH + "ip_echo")
        .then<IIPAddressResult | RejectReason>(resp => expectJson(resp, "Failed to echo IP.")),
};