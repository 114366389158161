export interface IVError {
    code: string,
    message: string | undefined,
    params?: any,
}

export type IVErrorsKind = IVErrors | IVErrorMap | IVError[];

export interface IVErrors {
    [key: string]: IVErrorsKind
}

export interface IVErrorMap {
    [key: number]: IVErrors
}

/**
 * Conveniently create an IVErrorsKind with the specified message
 * @param message 
 * @returns IVErrorsKind
 */
export function errorMessageWith(message: string): IVErrorsKind {
    return [{
        code: "",
        message,
    } as IVError];
}

/**
 * Check a validation object
 * @param from error object to check
 * @returns true when no error is present, false otherwise
 */
export function isOk(from: IVErrorsKind) {
    if (Array.isArray(from)) {
        const array = from as IVError[];
        return array.length === 0;
    } else {
        return Object.keys(from).length === 0;
    }
}

/**
 * Extract the validation error from the state.validationErrors.
 * @param field field name to extract from the top level
 * @returns IVErrorsKind
 */
export function errorFor(state: { validationErrors?: IVErrorsKind | undefined }, field: string): IVErrorsKind {
    const errors = state.validationErrors as any;
    return errors && errors[field];
}

/**
 * Extract the validation error message from the IVErrorsKind.
 * @param errKind extract from the top level of the IVErrorsKind.
 * @returns string
 */
 export function messageFor(errKind: IVErrorsKind | null): string {
    if (Array.isArray(errKind)) {
        const errs = errKind as IVError[];
        if (errs.length > 0) {
            return errs[0].message || "";
        }
    }
        
    return "";
}

/**
 * Extract the validation error message from the state.validationErrors.
 * @param field field name to extract from the top level
 * @returns string
 */
 export function errorMessageFor(state: { validationErrors?: IVErrorsKind }, field: string): string {
    return messageFor(errorFor(state, field));
}
