import * as React from "react";
import MuiCheckbox from "@mui/material/Checkbox";
import MuiSkeleton from "@mui/material/Skeleton";
import MuiFormControlLabel from "@mui/material/FormControlLabel";
import style from "./style.module.css";

interface IProps {
    id: string,
    label: string,
    disabled?: boolean,
    checked: boolean,
    onChange: (checked: boolean) => void,
    isLoading?: boolean,
}

export const Checkbox: React.FC<IProps> = ({ id, label, onChange, disabled, checked, isLoading }) => {
    if (isLoading)
        return (
            <MuiSkeleton variant="text" />
        );

    return (
        <MuiFormControlLabel disabled={disabled} control={<MuiCheckbox className={style.checkbox} id={id} checked={checked} onChange={e => onChange(e.target.checked)} />} label={label} />
    );
};

export default Checkbox;