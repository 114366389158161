import { Grid } from "@common/components/elements/grid";
import React from "react"

interface IProps {
}

export const Main = ({ children }: React.PropsWithChildren<IProps>) => {
	return (
        <Grid container sx={{ flexGrow: 1, paddingLeft: "24px", paddingRight: "24px", paddingTop: "2rem" }}>
            <Grid lg></Grid>
            <Grid id="main" container spacing={2} maxWidth="lg" xs={12}>
                <Grid xs={12}>
                    {children}
                </Grid>
            </Grid>
            <Grid lg></Grid>
        </Grid>
	);
};

export default Main;
