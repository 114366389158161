import { DragDropContext, Droppable, Draggable, DropResult, ResponderProvided } from "react-beautiful-dnd";
import { List, ListItem, ListItemIcon, ListItemText, ListSubheader } from "@common/components/elements/list";
import { ITodo } from "src/todos/models";
import { useTheme } from '@mui/material/styles';
import style from "./style.module.css";
import Title from "@common/components/elements/title";
import { DragIndicator } from "@common/components/icons";
import Button from "@common/components/forms/button";
import { BackButton } from "@common/components/elements/backButton";

interface ISortListProps {
    isPending: boolean,
    isSaving: boolean,
    listName: string,
    items: ITodo[],
    onDragEnd: (result: DropResult, provided: ResponderProvided) => void,
    onSave: () => void,
    onBack: () => void,
}

export function SortList({ listName, onDragEnd, items, isSaving, onSave, onBack, isPending }: ISortListProps,) {
    const theme = useTheme();

    return (
        <>
            <BackButton onClick={onBack} />
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, _snapshot) => (
                        <List 
                            {...provided.droppableProps} 
                            className={style.todo_rows} 
                            ref={provided.innerRef}
                            subheader={
                                <ListSubheader id="todo_entries" className={style.sort_header} component="div">
                                    <div className={style.sort_title}>
                                        <Title size="md" text={listName + " entries"} />
                                    </div>
                                    <div className={style.sort_buttons}>
                                        <Button disabled={!isPending} isLoading={isSaving} onClick={onSave} label={"Save order"} />
                                    </div>
                                </ListSubheader>
                            }
                        >
                            {items.map((p, i) => (
                                <Draggable key={p.todo_id} draggableId={p.todo_id} index={i}>
                                    {(provided, snapshot) => (
                                        <div ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                        >
                                            <ListItem 
                                                data-id={p.todo_id} 
                                                key={p.todo_id} 
                                            >
                                                <ListItemIcon style={{ minWidth: 36 }}>
                                                    <DragIndicator />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={p.description}
                                                    className={p.status === "Done" ? style.item_done : undefined}
                                                    style={{ color: p.status === "Done" 
                                                        ? theme.palette.text.disabled
                                                        : theme.palette.text.primary 
                                                    }}
                                                />
                                            </ListItem>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
}

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: "none",
    background: isDragging ? "rgb(57, 57, 57)" : "transparent",
    borderTop: isDragging ? "1px solid #333333" : "none",
    borderBottom: isDragging ? "1px solid #333333" : "none",
    ...draggableStyle
});
