import { BackButton } from "@common/components/elements/backButton";
import { List, ListItem, ListItemIcon, ListItemText, ListSubheader } from "@common/components/elements/list";
import Loader from "@common/components/elements/loader";
import { RightHeaderMenu } from "@common/components/elements/rightHeaderToolbar";
import { SkeletonText, SkeletonTile } from "@common/components/elements/skeleton";
import Tag from "@common/components/elements/tag";
import { Title } from "@common/components/elements/title";
import Checkbox from "@common/components/forms/checkbox";
import IconButton from "@common/components/forms/iconButton";
import { ChevronRight, Menu, People } from "@common/components/icons";
import { formatDateTimeAsLong } from "@common/services/date";
import { useTheme } from "@mui/material";
import { ITodo } from "@todos/models";

import style from "./style.module.css";
import SwitchToggle from "@common/components/forms/switchToggle";
import React from "react";

interface IDefaultListProps {
    listName: string,
    isShared: boolean,
    isLoading: boolean,
    loadingIds: Set<string>,
    items: ITodo[],
    onItemChange: (todo: ITodo, persist: boolean) => void,
    onItemDetails: (todoId: string) => void,
    onShowOptions: () => void,
    onShowSharing: () => void,
    onBack: () => void,
}

export function DefaultList({ isLoading, onBack, listName, loadingIds, items, onItemChange, onItemDetails, onShowOptions, isShared, onShowSharing }: IDefaultListProps,) {
    
    const [showCompleted, setShowCompleted] = React.useState(false);

    let gridControl;
    if (isLoading) {
        gridControl = (
            <>
                <SkeletonText className={style.todo_rows_h_skeleton} />
                <List className={style.todo_rows_skeleton}>
                    {[0, 1, 2, 3, 4].map((p) => 
                    <ListItem 
                        key={p} 
                        className={style.todo_row_skeleton}
                        dense={true}>
                        <SkeletonTile className={style.check_skeleton} />
                        <SkeletonTile className={style.cell_skeleton} />
                    </ListItem>)}
                </List>
            </>
        );
    }

    const filteredItems = showCompleted 
        ? items 
        : items.filter(p => p.status !== "Done" || loadingIds.has(p.todo_id));

    gridControl = (
            <List 
                className={style.todo_rows} 
                subheader={
                    <ListSubheader id="todo_entries" className={style.sort_header} component="div">
                        <div className={style.sort_title}>
                            <Title size="md" text={listName} />
                            {isShared &&
                                <div className={style.show_sharing_icon} title="This is a shared list">
                                    <IconButton icon={<People />} onClick={() => onShowSharing()} />
                                </div>
                            }
                        </div>
                    </ListSubheader>
                }>
                {filteredItems.map((p, i) => {
                    return (
                        <DefaultRow data-id={p.todo_id} key={p.todo_id} index={i}
                            todo={p} onChange={onItemChange} onDetails={onItemDetails} 
                            isLoading={loadingIds.has(p.todo_id)}
                        />
                    );
                })}
            </List>
        );

    return (
        <>
            <BackButton onClick={onBack} />
            <RightHeaderMenu>
                <div className={style.show_done_container}>
                    <SwitchToggle 
                        label={"Show Completed"}
                        isLoading={isLoading}
                        checked={showCompleted}
                        onChange={p => setShowCompleted(p)}
                    />
                </div>
                <IconButton 
                    size="large" 
                    disabled={isLoading ||loadingIds.entries.length > 0} 
                    icon={<Menu size={24} />}
                    onClick={() => { onShowOptions() }} 
                />
            </RightHeaderMenu>
            {gridControl}
        </>
    );
}

interface IDefaultRowProps {
    index: number,
    todo: ITodo,
    isLoading?: boolean,
    onChange: (todo: ITodo, persist: boolean) => void,
    onDetails: (todoId: string) => void,
}

const DefaultRow: React.FC<IDefaultRowProps> = ({ index, todo, isLoading, onChange, onDetails }) => {
    const updated = (diff: Partial<ITodo>, persist: boolean) => {
        onChange && onChange({ ...todo, ...diff }, persist);
    }
    const statusChanged = (checked: boolean) => updated({ status: checked ? "Done" : "Pending" }, true);
    const tags = todo.tags || [];
    const removeTag = (tag: string) => {
        return () => updated({ tags: tags.filter(p => p !== tag) }, true);
    };
    const isDone = todo.status === "Done";
    const theme = useTheme();
    const isAlt = index % 2 === 0;

    const inkStyle: React.CSSProperties = { minWidth: 36, maxWidth: 36, minHeight: 36, maxHeight: 36 };

    return (
        <ListItem 
            data-id={todo.todo_id} 
            key={todo.todo_id} 
            className={style.todo_row}
            dense={true}
            style={{ 
                backgroundColor: !isAlt ? theme.palette.background.default : "#191919",
                paddingLeft: 0,
                paddingRight: 0,
                flexFlow: "column"
            }}>
                <div className={style.todo_detail_row}>
                    <div className={style.todo_detail_1} style={inkStyle}>
                        <ListItemIcon className={style.todo_status}>
                            {!isLoading &&
                                <Checkbox
                                    id={todo.todo_id}
                                    label={""}
                                    checked={todo.status === "Done"}
                                    onChange={statusChanged}
                                />
                            }
                            {isLoading &&
                                <div className={style.loaderDiv}>
                                    <Loader size={24} className={style.loader} />
                                </div>
                            }
                        </ListItemIcon>
                    </div>
                    <ListItemText
                        primary={todo.description}
                        className={isDone ? style.item_done : undefined}
                        style={{ 
                            color: isDone
                                ? theme.palette.text.disabled
                                : theme.palette.text.primary,
                            flexGrow: 1,
                            flexDirection: "row",
                            marginTop: 12,
                        }}
                    />
                    <div style={inkStyle}>
                        <ListItemIcon>
                            <IconButton size="medium" disabled={isLoading} 
                                icon={<ChevronRight />}
                                onClick={() => { 
                                    onDetails && onDetails(todo.todo_id);
                                }}/>
                        </ListItemIcon>
                    </div>
                </div>
                {todo.due_at && 
                <div className={style.todo_detail_row}>
                    <div className={style.todo_detail_1} style={inkStyle}></div>
                    <ListItemText
                        primary={`Due: ${formatDateTimeAsLong(todo.due_at)}`}
                        className={isDone ? style.item_done : undefined}
                        style={{ 
                            textAlign: "left",
                            fontSize: "1rem",
                            color: isDone
                                ? theme.palette.text.disabled
                                : theme.palette.text.primary,
                            flexGrow: 1,
                            flexDirection: "row",
                            marginTop: 6,
                            marginBottom: 6,
                        }}
                    />
                    <div style={inkStyle}></div>
                </div>
                }
                <div className={`${style.todo_detail_row} ${style.todo_detail_lastrow}`}>
                    <div className={style.todo_row_tags}>
                        {tags.map(p => {
                            const remove = removeTag(p);
                            return (
                                <Tag key={p} size="small" label={p} onDelete={remove} />
                            );
                        })}
                    </div>
                </div>
        </ListItem>
    );
}
