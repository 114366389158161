import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { RejectReason } from "@common/models";
import { notificationService } from "@common/services/notification";
import { Button } from "@common/components/forms/button";
import { api } from "@fitness/services/api";
import { Breadcrumb } from "@common/components/elements/breadcrumb";
import { TextField } from "@common/components/forms/textField";
import { routes } from "@config/routes";
import { ButtonStrip } from "@common/components/forms/buttonStrip";
import Title from "@common/components/elements/title";
import DateTimeField from "@common/components/forms/dateTimeField";
import dayjs from "dayjs";
import { IFitnessDiaryEntry, IFitnessDiaryEntryRow } from "@fitness/models";
import { errorFor, IVErrorsKind } from "@common/models/validation";
import { uuidService } from "@common/services/uuid";

//import style from "./style.module.css";
import { List, ListButtonFn } from "@common/components/elements/list";

interface IProps {
}

interface IState {
    isLoadingRecent: boolean,
    isSubmitting: boolean,
    model: IFitnessDiaryEntry,
    validationErrors: IVErrorsKind | undefined,
    recent: Array<IFitnessDiaryEntryRow>,
}

function createModel() {
    return {
        diary_id: uuidService.NIL,
        date: dayjs().toDate(),
        name: "",
        result: "",
    };
}

class Fitness extends React.Component<RouteComponentProps<IProps>, IState> {
    constructor(props: RouteComponentProps<IProps>) {
        super(props);

        this.state = {
            isLoadingRecent: true,
            isSubmitting: false,
            model: createModel(),
            validationErrors: undefined,
            recent: [],
        }
    }

    override componentDidMount = () => {
        api.getDiaryEntryList()
            .then(data => {
                const recent = data as Array<IFitnessDiaryEntry>;
                this.setState({
                    recent,
                    isLoadingRecent: false,
                });
            })
            .catch((rejected: RejectReason) => {
                const validationErrors = notificationService.rejected(rejected);
                this.setState({
                    isLoadingRecent: false,
                    validationErrors: validationErrors || undefined,
                });
            });
    }

    onSubmit = (): Promise<void> | void => {
        const { model, recent } = this.state;

        this.setState({
            isSubmitting: true,
        }, () => {
            api.postDiaryEntry(model)
                .then((data) => {
                    notificationService.success("Success", "Task saved.");

                    const entry = data as IFitnessDiaryEntry;
                    this.setState({
                        model: createModel(),
                        validationErrors: undefined,
                        recent: [{ diary_id: entry.diary_id, date: entry.date, name: entry.name }, ...recent]
                    });
                })
                .catch((err: RejectReason) => {
                    const validationErrors = notificationService.rejected(err);
                    if (validationErrors) {
                        this.setState({ validationErrors });
                    }
                })
                .finally(() => {
                    this.setState({
                        isSubmitting: false,
                    });
                });
        });
    }

    modelChanged = (diff: Partial<IFitnessDiaryEntry>) => {
        const model = this.state.model;
        const mutatedModel = Object.assign({}, model, diff);

        this.setState({
            model: mutatedModel,
        });
    }

    inputChanged = (value: any, field: keyof IFitnessDiaryEntry) => {
        this.modelChanged({ [field]: value });
    };

    override render = () => {
        const { isSubmitting, model, validationErrors, recent } = this.state;
        const { date, name, result } = model;
        
        const title = "Fitness";

        return (
            <div>
                <Breadcrumb path={[
                    { href: routes.home, title: "Home" },
                    { href: routes.fitness.home, title },
                ]} />

                <div>
                    <Title size="md" text="Add Workout" />
                    <DateTimeField 
                        id="Date" label="Workout Name" value={date} 
                        errorMessage={errorFor({validationErrors}, "date")}
                        onChange={p => this.inputChanged(p?.toDate(), "date")} 
                    />
                    <TextField 
                        id="name" label="Workout Name" value={name} 
                        rightLabel={`${name.length}/255`}
                        errorMessage={errorFor({validationErrors}, "name")}
                        onChange={p => this.inputChanged(p.target.value, "name")} 
                        onBlur={p => this.inputChanged(p.target.value.trim(), "name")} 
                    />
                    <TextField 
                        id="result" label="Result" value={result} multiline minRows={8} 
                        rightLabel={`${result.length}/5000`}
                        errorMessage={errorFor({validationErrors}, "result")}
                        onChange={p => this.inputChanged(p.target.value, "result")} 
                        onBlur={p => this.inputChanged(p.target.value.trim(), "result")} 
                    />
                    <ButtonStrip>
                        <Button key="submit" label="Add" onClick={this.onSubmit} isLoading={isSubmitting}></Button>
                    </ButtonStrip>
                </div>
                <div>
                    <Title size="md" text="Recent" />
                    <List>
                        {recent.map(p => ListButtonFn(p.diary_id, dayjs(p.date).format("YYYY-MM-DD").toString() + ": " + p.name, <></>, () => { console.log(p.diary_id) }))}
                    </List>
                </div>
            </div>
        );
    }
};

export default withRouter(Fitness);
