import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { RejectReason } from "@common/models";
import { notificationService } from "@common/services/notification";
import { Button } from "@common/components/forms/button";
import { api, IProfile } from "src/profile/services/api";
import { Breadcrumb } from "@common/components/elements/breadcrumb";
import { TextField } from "@common/components/forms/textField";
import { routes } from "@config/routes";
import { ButtonStrip } from "@common/components/forms/buttonStrip";
import { Form } from "@common/components/forms/form";
import { IVErrorsKind } from "@common/models/validation";
// import style from "./style.module.css";

interface IProps {
}

interface IState {
    isLoading: boolean,
    isSubmitting: boolean,
    profile: IProfile | null,
    validationErrors: IVErrorsKind | undefined,
}

class IPEcho extends React.Component<RouteComponentProps<IProps>, IState> {
    constructor(props: RouteComponentProps<IProps>) {
        super(props);

        this.state = {
            profile: null,
            isLoading: true,
            isSubmitting: false,
            validationErrors: undefined,
        }
    }

    override componentDidMount = () => {
        this.getProfile();
    }

    getProfile = () => api.getProfile()
        .then(profile => {
            this.setState({ 
                profile: profile as IProfile,
                isLoading: false
            });
        })
        .catch((rejected: RejectReason) => {
            this.setState({ 
                profile: null, 
                isLoading: false 
            });
            notificationService.rejected(rejected);
        });

    onSubmit = (): Promise<void> | void => {
        const { profile } = this.state;

        this.setState({ isSubmitting: true });

        return api.postProfile(profile || { name: "" })
            .then(() => {
                notificationService.success("Success", "Profile saved.");

                this.setState({
                    isSubmitting: false,
                    validationErrors: undefined,
                });
            })
            .catch((rejected: RejectReason) => {
                const validationErrors = notificationService.rejected(rejected);
                this.setState({
                    isSubmitting: false,
                    validationErrors: validationErrors || undefined,
                });
            });
    }

    profileChanged = (diff: Partial<IProfile>) => {
        const profile = this.state.profile;
        const mutated = Object.assign({}, profile, diff);

        this.setState({
            profile: mutated,
        });
    }

    inputChanged = (value: any, field: keyof IProfile) => {
        this.profileChanged({ [field]: value });
    };

    profileNameChanged = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => this.inputChanged(e.currentTarget.value, "name");

    override render = () => {
        const { profile, isLoading, isSubmitting } = this.state;
        const title = "User profile";
        const profileName = profile?.name || "";
        return (
            <div>
                <Breadcrumb path={[
                    { href: routes.home, title: "Home" },
                    { href: "", title },
                ]} />
                
                <Form id="profile-form" label="User profile">
                    <TextField id="name" 
                        label="User display name" 
                        value={profileName} 
                        rightLabel={`${profileName.length}/64`}
                        isLoading={isLoading}
                        onChange={this.profileNameChanged}
                        />
                    <ButtonStrip>
                        <Button key="submit" label="Save" onClick={this.onSubmit} isLoading={isSubmitting}></Button>
                    </ButtonStrip>
                </Form>
            </div>
        );
    }
};

export default withRouter(IPEcho);
