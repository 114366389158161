import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import { INotification } from '@common/services/notification';

interface IProps extends INotification {
}

export const Notification = ({ type, title, message }: IProps) => {
    const [open, setOpen] = React.useState(true);

    const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <MuiAlert style={{ display: open ? "flex" : "none" }} severity={type} title={title} onClick={handleClose}>
            {message}
        </MuiAlert>
    );
};

export default Notification;