import { apiBase } from "@config/settings";
import { apiGet, apiPost, clearToken, expectJson, expectText, isLoggedIn, setToken } from "@common/services/api";
import { RejectReason } from "@common/models";

const SERVER_PATH = `${apiBase}api/v0/`;
const APPS = "apps";

export interface IApp {
    app: string,
    name: string,
    description: string,
    tile_color?: string,
    text_color?: string,
    icon_key?: string,
}

export interface IApps {
    apps: Array<IApp>,
}

/**
 * Api endpoints
 */
export const api = {
    /**
     * Returns true if the user has a token. The token may not be valid.
     * @returns true if there's a current token
     */
    isLoggedIn: () => isLoggedIn(),

    /**
     * GET the apps for the current user
     * @returns Promise<IApps | RejectReason>
     */
    getApps: (): Promise<IApps | RejectReason> => {
        const cached = sessionStorage.getItem(APPS);
        if (cached) {
            return Promise.resolve(JSON.parse(cached));
        }

        return apiGet(SERVER_PATH + "auth/apps")
            .then(resp => {
                return expectJson<IApps | RejectReason>(resp, "Failed to fetch applications.")
                    .then(maybeApps => {
                        const asApps = maybeApps as IApps;
                        if (asApps.apps) {
                            sessionStorage.setItem(APPS, JSON.stringify(asApps));
                            return asApps;
                        } else {
                            return maybeApps;
                        }
                    });
            });
    },

    postGoogleLogin: (credential: string) => apiPost(SERVER_PATH + "auth/g_signin", { credential }, false).then(resp => {
        if (resp.status === 200) {
            return resp.json().then(data => {
                setToken(data.token);
                return [200, "ok"];
            });
        } else {
            return resp.text().then(_ => Promise.reject("Signin failed. The credentials supplied are not valid."));
        }
    }),

    /**
     * Attempt to signin, on success: sets the token and returns [200, "ok"], rejects otherwise.
     * @param email the user email
     * @param password the user password
     * @returns Promise<[200, "ok"]> on success, rejects otherwise.
     */
    postLogin: (email: string, password: string) => apiPost(SERVER_PATH + "auth/signin", { email, password }, false).then(resp => {
        if (resp.status === 200) {
            return resp.json().then(data => {
                setToken(data.token);
                return [200, "ok"];
            });
        } else {
            return resp.text().then(_ => Promise.reject("Signin failed. Invalid username or password."));
        }
    }),

    /**
     * Log the user out, clear the current token.
     * The token is cleared even if the call fails.
     * @returns Promise<Response>
     */
    postLogout: () => apiPost(SERVER_PATH + "logout", {})
        .finally(() => {
            clearToken();
            sessionStorage.removeItem(APPS);
        }),

    /**
     * Sign the new user up.
     * @param email the user email
     * @param password the user password
     * @returns Promise<[200, ""]> on success, rejects otherwise.
     */
    postSignup: (email: string, password: string) => apiPost(SERVER_PATH + "auth/signup", { email, password }, false)
        .then<string | RejectReason>(resp => expectText(resp, "User creation failed.")), 

    // getHoldings: () => apiGet("holdings").then(resp => resp.json()),
    // getPrices: (exchangeName: string, symbolCode: string) => apiGet("prices", { exchangeName, symbolCode }).then(resp => resp.json()),
    // getAccounts: () => apiGet("lookups/accounts", {}).then(resp => resp.json()),
    // getExchanges: () => apiGet("lookups/exchanges", {}).then(resp => resp.json()),
    // getTrade: (tradeId: number) => apiGet("trade", { tradeId }).then(resp => resp.json()),
    // getTrades: (page: IPage) => apiGet("trades", page).then(resp => resp.json()),
    // postTrade: (trade: ITradeRow) => apiPost("trade", trade).then(resp => resp.status === 200),
    // deleteTrade: (tradeId: number) => apiDelete("trade", { tradeId }).then(resp => resp.status === 200),
}
