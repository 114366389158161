import Chip from "@mui/material/Chip";

interface IProps {
    label: string,
    size?: "small" | "medium",
    variant?: "filled" | "outlined" | undefined,
    color?: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning",
    onDelete?(event: React.MouseEvent<HTMLButtonElement>): void,
    onClick?(event: React.MouseEvent<HTMLDivElement>): void,
}

export const Tag = ({ label, size = "medium", color = "default", variant = "filled", onDelete, onClick }: IProps) => {
    return (
        <Chip label={label} size={size} variant={variant} color={color} onClick={onClick} onDelete={onDelete} />
    );
};

export default Tag;