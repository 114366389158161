import * as React from "react";
import MuiPaper from '@mui/material/Paper';
import MuiTableContainer from '@mui/material/TableContainer';
import MuiTable from '@mui/material/Table';
import MuiTableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import MuiTableHead from '@mui/material/TableHead';
import MuiTableFooter from '@mui/material/TableFooter';
import MuiTableRow from '@mui/material/TableRow';

export const TableContainer: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    return (
        <MuiTableContainer component={MuiPaper}>
            {children}
        </MuiTableContainer>
    );
}

interface IClassName {
    className?: string,
}

interface ITableProps {
    padding?: 'checkbox' | 'none' | 'normal',
    size?: 'medium' | 'small',
    stickyHeader?: boolean,
}

export const Table: React.FC<React.PropsWithChildren<IClassName & ITableProps>> = ({ children, padding = 'normal', size = 'medium', stickyHeader = false }) => {
    return (
        <MuiTable padding={padding} size={size} stickyHeader={stickyHeader} >
            {children}
        </MuiTable>
    );
}

interface ITRowProps {
    hover?: boolean,
    selected?: boolean,
}
export const Tr: React.FC<React.PropsWithChildren<IClassName & ITRowProps>> = ({ children, className, hover = true, selected = false }) => {
    return (
        <MuiTableRow className={className} hover={hover} selected={selected}>
            {children}
        </MuiTableRow>
    );
}

export const TBody: React.FC<React.PropsWithChildren<IClassName>> = ({ children, className }) => {
    return (
        <MuiTableBody className={className}>
            {children}
        </MuiTableBody>
    );
}

export const THead: React.FC<React.PropsWithChildren<IClassName>> = ({ children, className }) => {
    return (
        <MuiTableHead className={className}>
            {children}
        </MuiTableHead>
    );
}

export const TFooter: React.FC<React.PropsWithChildren<IClassName>> = ({ children, className }) => {
    return (
        <MuiTableFooter className={className}>
            {children}
        </MuiTableFooter>
    );
}

interface ITableCellProps {
    className?: string,
    align?: 'center' | 'inherit' | 'justify' | 'left' | 'right',
    padding?: 'checkbox' | 'none' | 'normal',
    size?: 'small' | 'medium',
    variant: 'body' | 'footer' | 'head',
    width?: string | number,
}

const TableCell: React.FC<React.PropsWithChildren<ITableCellProps>> = ({ 
    children, 
    className, 
    align = 'inherit',
    padding = 'normal',
    size = 'medium',
    width = undefined,
    variant,
}) => {
    return (
        <MuiTableCell className={className} align={align} padding={padding} size={size} variant={variant} width={width}>
            {children}
        </MuiTableCell>
    );
}

export const Td: React.FC<React.PropsWithChildren<Omit<ITableCellProps, "variant">>> = ({ 
    children, 
    className, 
    align = 'inherit',
    padding = 'normal',
    size = 'medium',
    width = undefined,
}) => {
    return <TableCell className={className} align={align} padding={padding} size={size} variant={'body'} width={width}>
        {children}
    </TableCell>;
}

export const Th: React.FC<React.PropsWithChildren<Omit<ITableCellProps, "variant">>> = ({ 
    children, 
    className, 
    align = 'inherit',
    padding = 'normal',
    size = 'medium',
    width = undefined,
}) => {
    return <TableCell className={className} align={align} padding={padding} size={size} variant={'head'} width={width}>
        {children}
    </TableCell>;
}

export const Tf: React.FC<React.PropsWithChildren<Omit<ITableCellProps, "variant">>> = ({ 
    children, 
    className, 
    align = 'inherit',
    padding = 'normal',
    size = 'medium',
}) => {
    return <TableCell className={className} align={align} padding={padding} size={size} variant={'footer'}>
        {children}
    </TableCell>;
}
