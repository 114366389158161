import * as React from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { Button } from '@common/components/forms/button';
import { ButtonStrip } from '@common/components/forms/buttonStrip';
import { PasswordField } from '@common/components/forms/passwordField';
import { TextField } from "@common/components/forms/textField";
import { Form } from '@common/components/forms/form';
import { notificationService } from '@common/services/notification';
import { api } from '@auth/services/api';
import { defaultLogin, GOOGLE_AUTH_CLIENT_ID, GOOGLE_AUTH_SCRIPT_URI } from '@config/settings';
import { RejectReason } from "@common/models";
import { routes } from "@config/routes";
import { Grid } from "@common/components/elements/grid";
import { RouterLink } from "@common/components/elements/link";
import { injectScript } from "@common/helpers";
import style from "./style.module.css";
import Loader from "@common/components/elements/loader";
import { Backdrop } from "@common/components/elements/backdrop";

interface IProps {
}

interface IState {
    email: string, 
    password: string,
    isLoading: boolean,
    showOverlay: boolean,
}
class Signin extends React.Component<RouteComponentProps<IProps>, IState> {
    constructor(props: RouteComponentProps<IProps>) {
        super(props);
        this.state = { ...defaultLogin, isLoading: false, showOverlay: false };
    }

    unmountScript: (() => void) | undefined;

    override componentDidMount() {
        (window as any)["onGoogleSignIn"] = (data: any) => {
            // console.log("GOOGLE signin", data);
            const { history } = this.props;
            
            this.setState({ showOverlay: true }, () => 
                api.postGoogleLogin(data.credential)
                    .then(res => {
                        console.log("login res", res);
                        this.setState({ isLoading: false, showOverlay: false }, () => history.push(routes.home));
                    })
                    .catch((rejected: RejectReason) => {
                        console.error(rejected);
                        this.setState({ isLoading: false, showOverlay: false }, () => {
                            notificationService.rejected(rejected);
                        });
                    })
            );
        }
        this.unmountScript = injectScript("#root", GOOGLE_AUTH_SCRIPT_URI, true);
    }

    override componentWillUnmount() {
        delete (window as any)["onGoogleSignIn"];
        this.unmountScript && this.unmountScript();
    }

    onSubmit: (event?: React.FormEvent<HTMLFormElement>) => void = e => {
        e?.preventDefault();

        const { email, password } = this.state;
        const { history } = this.props;
        
        this.setState({
            isLoading: true,
        }, () => {
            api.postLogin(email, password)
                .then(_res => {
                    //console.log("login res", res);
                    this.setState({ isLoading: false }, () => history.push(routes.home));
                })
                .catch((rejected: RejectReason) => {
                    console.error(rejected);
                    this.setState({ isLoading: false }, () => {
                        notificationService.rejected(rejected);
                    });
                });
        });
    }

    emailChange = (e: any) => {
        this.setState({ email: e.currentTarget.value })
    }

    passwordChange = (e: any) => {
        this.setState({ password: e.currentTarget.value })
    }

    override render = () => {
        const { email, password, isLoading, showOverlay } = this.state || {};

        return (
            <Grid container spacing={2}>
                <Grid sm></Grid>
                <Grid xs={12} sm={12} md={6} lg={4} xl={3}
                    sx={{ maxWidth: {
                        sm: 400
                    } }}
                >
                    <Form onSubmit={this.onSubmit} label="Sign in" overview={
                        <p>Don&apos;t have an account? <RouterLink to={routes.auth.signup}>Create one.</RouterLink></p>
                    }>
                        <TextField id="email" label="Email" value={email || ""} onChange={this.emailChange} />
                        <PasswordField id="password" label="Password" value={password || ""} onChange={this.passwordChange} />
                        <ButtonStrip>
                            <Button className={style.signin} variant="contained" key="submit" label="Sign in" isLoading={isLoading} onClick={() => this.onSubmit()} />
                        </ButtonStrip>
                    </Form>
                    <div id="g_id_onload"
                        data-client_id={GOOGLE_AUTH_CLIENT_ID}
                        data-context="signin"
                        data-ux_mode="popup"
                        data-callback="onGoogleSignIn"
                        data-nonce=""
                        data-auto_prompt="false"
                        data-auto_select="false"
                        data-itp_support="true">
                    </div>

                    <div style={{
                        marginTop: "1.5rem",
                        marginBottom: "1.5rem",
                        textAlign: "center",
                    }}>
                        Or sign in with a provider
                    </div>
                    <div className={style.signin_container}>
                        <div className="g_id_signin"
                            data-type="standard"
                            data-shape="rectangular"
                            data-theme="outline"
                            data-text="signin_with"
                            data-size="large"
                            data-width="328"
                            data-logo_alignment="left">
                        </div>
                    </div>
                    <Backdrop open={showOverlay}>
                        <Loader />
                    </Backdrop>
                </Grid>
                <Grid sm></Grid>
            </Grid>
        );
    }
}

export default withRouter(Signin);