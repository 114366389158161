export type TodoStatus = "Pending" | "Done";

export interface ITodoId {
    todo_id: string,
}

export interface ITodo extends ITodoId {    
    list_id: string,
    description: string,
    details: string,
    status: TodoStatus,
    tags?: Array<string>,
    due_at?: Date | undefined,
    done_at?: Date | undefined,
}

export interface ITodoList {
    list_id: string,
    description: string,
    is_owner: boolean,
    is_shared: boolean,
}

export interface ITodoListUser {
    list_id: string,
    email: string,
    type_id: number,
    is_owner: boolean,
}

export interface ITodoListUserIndexResult {
    list: ITodoList,
    list_users: ITodoListUser[],
}

export const newTodoList = (list_id: string = "", description: string = ""): ITodoList => ({
    list_id, description, is_owner: true, is_shared: false,
});

export interface IFilterType { 
    tag: string 
};

export interface ITodoOrder extends IFilterType {
    list_id: string,
    todo_ids: Array<string>,
}

export interface ITodoIndex {
    todos: ITodo[],
    todo_orders: ITodoOrder[],
    todo_lists: ITodoList[],
}
