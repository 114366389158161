import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { RejectReason, Uuid } from "@common/models";
import { Button } from "@common/components/forms/button";
import { IVErrorsKind } from "@common/models/validation";
import { notificationService } from "@common/services/notification";
import { api } from "@estimator/services/api";
import { IProjectIndexRow } from "@estimator/models";
import style from "./style.module.css";
import Breadcrumb from "@common/components/elements/breadcrumb";
import { routes } from "@config/routes";
import { SkeletonDataTable } from "@common/components/elements/skeleton";
import { DataTable, IDataTableHeader } from "@common/components/elements/datatable";
import { Td } from "@common/components/elements/table";

interface IProps {
}

interface IState {
    isLoading: boolean,
    rows: Array<IProjectIndexRow>,
    confirmDeleteIds: Set<Uuid>,
    validationErrors?: IVErrorsKind,
}

class Projects extends React.Component<RouteComponentProps<IProps>, IState> {
    constructor(props: RouteComponentProps<IProps>) {
        super(props);

        this.state = {
            rows: [],
            isLoading: true,
            confirmDeleteIds: new Set<Uuid>(),
        }
    }

    override componentDidMount = () => {
        this.getEstimates();
    }

    getEstimates = () => api.getEstimates()
        .then(rows => {
            this.setState({ 
                rows: rows as IProjectIndexRow[], 
                isLoading: false 
            });
        })
        .catch((rejected: RejectReason) => {
            this.setState({ 
                rows: [], 
                isLoading: false 
            });
            notificationService.rejected(rejected);
        });

    addEstimate = () => {
        const { history } = this.props;
        history.push(routes.estimator.addEstimate);
    }

    viewEstimate = (id: Uuid) => {
        const { history } = this.props;
        history.push(routes.estimator.estimate(id));
    }

    deleteEstimate = (id: Uuid) => {
        const {confirmDeleteIds} = this.state;
        confirmDeleteIds.add(id);

        this.setState({
            confirmDeleteIds: new Set<Uuid>(confirmDeleteIds),
        });        
    }

    cancelDeleteEstimate = (id: Uuid) => {
        const {confirmDeleteIds} = this.state;
        confirmDeleteIds.delete(id);

        this.setState({
            confirmDeleteIds: new Set<Uuid>(confirmDeleteIds),
        });
    }

    confirmDeleteEstimate = (id: Uuid) => {
        return api.deleteEstimate(id)
                .then((data: any | RejectReason) => {
                    if (typeof data === "undefined") {
                        notificationService.success("Success", "Record deleted.");
                        this.getEstimates();
                    } else {
                        notificationService.rejected(data as RejectReason);
                    }                
                })
                .catch((rejected: RejectReason) => {
                    const validationErrors = notificationService.rejected(rejected);
                    if (validationErrors) {
                        console.log("validationErrors", validationErrors);
                        this.setState({ validationErrors });
                    }
                })
                .finally(() => { 
                    this.setState({ isLoading: false })
                });
    }

    headers = [{
            header: "Project title",
            rowValueKey: "title", 
        }, {
            header: "",
            rowValueKey: "title", 
            rowValueFn: (row, _rk, _ri) => {
                return (
                    !this.state.confirmDeleteIds.has(row.id)
                        ?   (<Td key={row.id + "_vw"} className={style.cellBtns}>
                                <Button variant="text" label="View" onClick={() => this.viewEstimate(row.id)} />
                                <Button variant={"text"} color="error" label="Delete" onClick={() => this.deleteEstimate(row.id)} />
                            </Td>)
                        :   (<Td key={row.id + "_vw"} className={style.cellBtns}>
                                <Button color="error" label="Delete" onClick={() => this.confirmDeleteEstimate(row.id)} />
                                <Button variant="text" label="Cancel" onClick={() => this.cancelDeleteEstimate(row.id)} />
                            </Td>)
                );
            }
        }
    ] as Array<IDataTableHeader>;

    override render = () => {
        const { rows, isLoading } = this.state;
        const title = "Projects";

        return (
            <div>
                <Breadcrumb path={[
                    { href: routes.home, title: "Home" },
                    { href: routes.estimator.projects, title },
                ]} />
                {isLoading && <SkeletonDataTable rowCount={5} />}
                {!isLoading && <DataTable headers={this.headers} rows={rows} />}
            </div>
        );
    }
};

export default withRouter(Projects);
