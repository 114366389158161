import { Breadcrumbs } from "@mui/material";
import * as React from "react";
import { RouterLink } from "../link";
import { SkeletonText } from "../skeleton";

interface IBreadcrumb {
    href: string,
    title: string,
    isLoading?: boolean,
    loadingWidth?: string | number,
}
interface IProps {
    path: Array<IBreadcrumb>,
    isLoading?: boolean,
}

export const Breadcrumb: React.FC<IProps> = ({ path, isLoading = false }) => {
    const all = path || [];
    const count = all.length;

    return (
        <Breadcrumbs>
            {all.map((p, i) => {
                if (i !== count - 1 && !p.isLoading) 
                    return (<RouterLink key={i} underline="hover" to={p.href}>{p.title}</RouterLink>);

                if (isLoading || p.isLoading) {
                    return <SkeletonText key={i} style={{ width: p.loadingWidth ?? "6rem", minWidth: p.loadingWidth ?? "6rem" }} />
                }
    
                return (
                    <span key={i}>{p.title}</span>
                );
            })}
        </Breadcrumbs> 
    );
}

export default Breadcrumb;
