import * as React from "react";
import Button from "@common/components/forms/button";
import MuiDialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { IconButton } from "@mui/material";
import { ClearX } from "@common/components/icons";

export type ModalAction = "primary" | "secondary" | "close";

interface ModalProps {
    open: boolean, 
    heading: string,
    primaryText: string,
    secondaryText: string,
    danger?: boolean,
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false,
    fullWidth?: boolean,
    requestClose: (action: ModalAction) => Promise<void>,
}

export const Modal: React.FC<ModalProps> = ({ children, maxWidth = "sm", fullWidth = true, open, requestClose, heading, primaryText, secondaryText, danger = false }) => {
    const [submitting, setSubmitting] = React.useState(false);

    const onActionClick = (action: ModalAction) => {
        action === "primary" && setSubmitting(true);
        setTimeout(() => requestClose(action).finally(() => setSubmitting(false)), 1);
    }

    return (
        <MuiDialog 
            open={open} 
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            onClose={() => onActionClick("secondary")}
        >
            <IconButton
                aria-label="close"
                onClick={() => onActionClick("close")}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                <ClearX />
            </IconButton>
            {heading && <DialogTitle>{heading}</DialogTitle>}
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button label={secondaryText} disabled={submitting} onClick={() => onActionClick("secondary")} />
                <Button label={primaryText} isLoading={submitting} variant="contained" color={danger ? "error" : "primary"} onClick={() => onActionClick("primary")} />
            </DialogActions>
        </MuiDialog>
    );
}
