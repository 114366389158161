import * as React from "react";
import { RejectReason } from "@common/models";
import { IVErrorsKind } from "@common/models/validation";
import { notificationService } from "@common/services/notification";
import { uuidService } from "@common/services/uuid";
import { ITodoList } from "@todos/models";
import { api } from "@todos/services/api";
import ListForm, { newList } from "../listForm";
import { useHistory } from "react-router-dom";
import { routes } from "@config/routes";
import Breadcrumb from "@common/components/elements/breadcrumb";

interface IProps { 
}

const ListAdd: React.FC<IProps> = () => {
    const history = useHistory();
    const [validationErrors, setValidationErrors] = React.useState<IVErrorsKind | undefined>(undefined);
    const [list, setList] = React.useState<ITodoList>(newList());
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    
    const postList = (changed: ITodoList) => {
        if (!changed.list_id || changed.list_id.length < uuidService.NIL_LENGTH) {
            changed.list_id = uuidService.NIL;
        }

        setIsSubmitting(true);
        api.postTodoList(changed)
            .then((data: ITodoList | RejectReason) => {
                setList(data as ITodoList);
            })
            .catch((err: RejectReason) => {
                const validationErrors = notificationService.rejected(err);
                if (validationErrors) {
                    setValidationErrors({ validationErrors });
                }
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const cancel = React.useCallback(() => {
        history.push(routes.todos.lists);
    }, []);

    return (
        <>
            <Breadcrumb path={[
                { href: routes.home, title: "Home" },
                { href: routes.todos.lists, title: "Lists" },
                { href: "", title: "Add" },
            ]} isLoading={false} />
            <ListForm 
                label="Add a list" 
                list={list} 
                submit={postList} 
                isSubmitting={isSubmitting} 
                validationErrors={validationErrors} 
                cancel={cancel}
            />
        </>
    );
}

export default ListAdd;
