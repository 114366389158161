
import { DateTimePicker as MuiDateTimePicker } from "@mui/x-date-pickers";
import MuiTextField from "@mui/material/TextField";
import { IVErrorsKind, messageFor } from '@common/models/validation';
import { SkeletonTextbox } from '@common/components/elements/skeleton';
import { Dayjs } from "dayjs";

interface IProps {
    id: string,
    value: Date | undefined,
    label: string,
    placeholder?: string,
    onChange: (value: Dayjs | null | undefined) => void,
    errorMessage?: IVErrorsKind | null,
    isLoading?: boolean,
    readonly?: boolean,
    disabled?: boolean,
    className?: string,
    variant?: "filled" | "outlined" | "standard" | undefined,
    style?: React.CSSProperties,
    hiddenLabel?: boolean,
    rightLabel?: string | undefined,
}

function wrapWithRightLabel(rightLabel: string, el: React.ReactNode): React.ReactNode {
    if (rightLabel != null) {
        return (
            <div style={{ position: "relative", display: "flex", flexGrow: 1, }}>
                <div style={{
                    fontSize: ".75rem",
                    position: "absolute",
                    color: "rgba(255, 255, 255, 0.7)",
                    top: "1.4rem",
                    right: ".75rem",
                }}>{rightLabel}</div>
                {el}
            </div>
        )
    } else {
        return el;
    }
}

export const DateTimeField = ({ value, variant = "filled", label, rightLabel = "", placeholder = "", isLoading, onChange, errorMessage: validationError = null, readonly = false, className = undefined, disabled = undefined }: IProps) => {
    const invalidMessage = messageFor(validationError);
    const invalid = invalidMessage !== "";

    return (
        <>
        {isLoading && 
            <SkeletonTextbox fullwidth />
        }
        {!isLoading &&
            wrapWithRightLabel(rightLabel, (
                <MuiDateTimePicker
                    label={label}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    InputProps={{
                        readOnly: readonly,
                    }}
                    className={className}
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => {
                        params.error = invalid;
                        params.helperText = invalidMessage;
                        params.placeholder = placeholder; // TODO: Maybe
                        params.margin = "normal";
                        params.fullWidth = true;
                        params.variant = variant;

                        return (
                            <MuiTextField {...params} />
                        );
                    }}
                />
            ))
        }
        </>
    );
};

export default DateTimeField;
