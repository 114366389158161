import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { RejectReason } from "@common/models";
import { notificationService } from "@common/services/notification";
import { Breadcrumb } from "@common/components/elements/breadcrumb";
import { routes } from "@config/routes";
import { SkeletonTile } from "@common/components/elements/skeleton";
import { BackButton } from "@common/components/elements/backButton";
import { api, ISummary } from "src/trade/services/api";
import type { SummaryRow } from "src/trade/services/api";

import style from "./style.module.css";
import { Grid } from "@common/components/elements/grid";
import Fab from "@common/components/elements/fab";
import { Add, Refresh, Menu, TrendingDown, TrendingUp } from "@common/components/icons";
import { Card, CardActionArea, CardContent } from "@common/components/elements/card";
import { colors } from "@common/components/elements/colors";

import { useTheme } from "@mui/material";
import { interpolateRgb } from "d3-interpolate";
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from "@nivo/pie";
import { Drawer } from "@common/components/elements/drawer";
import { List, ListButtonFn } from "@common/components/elements/list";
import { RightHeaderMenu } from "@common/components/elements/rightHeaderToolbar";
import { IconButton } from "@common/components/forms/iconButton";

const CHART_HEIGHT = 480;
const colorsF = interpolateRgb(colors.blue[200], colors.purple.A400);

interface IProps {
}

interface IState {
    holdings: ISummary,
    isLoading: boolean,
    selectedExchange: string, 
    selectedSymbol: string,
    rightMenuOpen: boolean,
}

function getEmptySummary(): ISummary {
    return { data: [] };
}

function formatNumber(n: number|string, dp: number = 4): string {
    if (typeof n === "string") {
        n = Number.parseFloat(n);
    }
    return Number.isNaN(n) ? "UNK" : n.toFixed(dp); 
}

function toFloat(source: string | null, defaultValue: number = Number.NaN): number {
    return source !== null ? Number.parseFloat(source) : defaultValue;
}

class Holdings extends React.Component<RouteComponentProps<IProps>, IState> {
    constructor(props: RouteComponentProps<IProps>) {
        super(props);

        this.state = {
            holdings: getEmptySummary(),
            isLoading: true,
            selectedExchange: "",
            selectedSymbol: "",
            rightMenuOpen: false,
        };
    }

    override componentDidMount = () => {
        this.getHoldings();
    };

    getHoldings = () => api.getHoldings()
        .then(data => {
            this.setState({
                holdings: data as ISummary,
                isLoading: false,
            });
        })
        .catch((rejected: RejectReason) => {
            this.setState({
                holdings: getEmptySummary(),
                isLoading: false,
            });
            notificationService.rejected(rejected);            
        });

    refreshHoldings = () => {
        this.setState({
            isLoading: true,
        }, () => {
            api.postHoldings()
            .then(data => {
                this.setState({
                    holdings: data as ISummary,
                    isLoading: false,
                });
            })
            .catch((rejected: RejectReason) => {
                this.setState({
                    holdings: getEmptySummary(),
                    isLoading: false,
                });
                notificationService.rejected(rejected);            
            });
        })
    }

    addHolding = () => {
        console.log("add holding clicked.")
    }

    setRightMenuOpen = (open: boolean) => {
        this.setState({ rightMenuOpen: open });
    }

    refreshPrices = () => {

    }

    override render = () => {
        const { isLoading, holdings, selectedExchange, selectedSymbol, rightMenuOpen: drawerOpen, } = this.state;
        const { history } = this.props;

        const holdingsData: Array<SummaryRow> = holdings?.data?.filter(p => !Number.isNaN(toFloat(p[3]))) ?? [];

        let holdingCards: JSX.Element, 
            totalChart: JSX.Element,
            totalPie: JSX.Element;

        if (isLoading) {
            holdingCards = (
                <>
                    {[0,0,0,0,0,0,0,0].map((_, i) => { 
                        return ( 
                            <Grid key={i} xs={12} sm={6} md={4} lg={3}>
                                <SkeletonTile className={style.skeletonTile} />
                            </Grid> 
                        ) 
                    })}
                </>
            );

            totalChart = (
                <SkeletonTile style={{height: CHART_HEIGHT}}/>
            );

            totalPie = (
                <SkeletonTile style={{height: CHART_HEIGHT}}/>
            );
        } else {
            holdingCards = (
                <>
                    {holdingsData.map((p, i) => {
                        let invested = toFloat(p[3]);
                        let currentValue = toFloat(p[4]);
                        let changeValue = currentValue - invested;
                        let buttonClass = (changeValue > 0) ? style.isSuccess : style.isDanger;
                        let selectedClass = selectedExchange == p[0] && selectedSymbol == p[1] ? style.isSelected : "";
                        let aClass = `${style.tile} ${buttonClass} ${selectedClass}`;
                        let trendingDown = Number.isNaN(changeValue) || changeValue <= 0;
                        
                        return (
                            <Grid key={i} xs={12} sm={6} md={4} lg={3}>
                                <Card>
                                    <CardActionArea onClick={_ => {
                                            console.log(p[0], p[1]);
                                            //getPrices(p[0], p[1]);
                                        }}>
                                        <CardContent className={aClass}>
                                            <div>
                                                <h2 className={style.holdingTitle}>
                                                    {p[0]}:<b>{p[1]}</b>
                                                </h2>
                                                <div className={style.holdingValue}>
                                                    {formatNumber(currentValue, 3)}
                                                </div>
                                                <div className={style.holdingChange}>
                                                    <span className={style.iconText}>
                                                        <span className="icon">
                                                            {trendingDown && 
                                                                <TrendingDown color={colors.red[400]} />
                                                            }
                                                            {!trendingDown && 
                                                                <TrendingUp color={colors.green[400]} />
                                                            }                                                    
                                                        </span>
                                                        <span>{formatNumber(changeValue, 3)} ({formatNumber(changeValue/currentValue*100,2)}%)</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        )
                    })}
                    <div style={{ 
                            position: "fixed", 
                            bottom: "1rem", 
                            right: "1rem",
                            zIndex: 101,
                        }}>
                        <Fab
                            disabled={isLoading}
                            icon={<Add color="#000" />}
                            onClick={() => this.addHolding()} 
                            label="Add an item"
                            color="primary"
                        />  
                    </div>
                </>
            );

            const colorPalette: Array<string> = [];
            const colorCount = holdingsData.length;
            for (let i = 0; i < colorCount; i++) {
                colorPalette.push(colorsF(i/colorCount));    
            }

            totalChart = (
                <Card className={style.chart}>
                    <CardContent>
                        <div style={{ minHeight: CHART_HEIGHT, height: CHART_HEIGHT, width: "100%" }}>
                            <TotalSummaryChart holdingsData={holdingsData} colorPalette={colorPalette} />
                        </div>
                    </CardContent>
                </Card>
            );

            totalPie = (
                <Card>
                    <CardContent>
                        <div style={{ minHeight: CHART_HEIGHT, height: CHART_HEIGHT, width: "100%" }}>
                            <TotalSummaryPie holdingsData={holdingsData} colorPalette={colorPalette} />
                        </div>
                    </CardContent>
                </Card>
            );
        }

        return (
            <>
                <Breadcrumb path={[
                    { href: routes.home, title: "Home" },
                    { href: "", title: "Holdings" },
                ]} isLoading={isLoading} />
                <>
                    <BackButton onClick={() => { history.replace(routes.home); }} />
                    <RightHeaderMenu>
                        <IconButton 
                            size="large" 
                            icon={<Menu size={24} />}
                            onClick={() => { this.setRightMenuOpen(true) }} 
                        />
                    </RightHeaderMenu>
                    <Grid container spacing={2} sx={{ flexGrow: 1, paddingLeft: "24px", paddingRight: "24px", paddingTop: "2rem" }}>
                        <Grid lg></Grid>
                        <Grid id="summary" container maxWidth="lg" xs={12}>
                            <Grid xs={12} container>
                                <Grid xs={8}>
                                    {totalPie}
                                </Grid>
                                <Grid xs={4}>
                                    {totalChart}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid lg></Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ flexGrow: 1, paddingLeft: "24px", paddingRight: "24px", paddingTop: "2rem" }}>
                        <Grid lg></Grid>
                        <Grid id="holdings" container  maxWidth="lg" xs={12}>
                            {holdingCards}
                        </Grid>
                        <Grid lg></Grid>
                    </Grid>
                </>
                <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={() => this.setRightMenuOpen(false)}
                >
                    <List>
                        {ListButtonFn("refreshHoldings", "Refresh Holdings", <Refresh />, this.refreshHoldings)}
                    </List>
                </Drawer>
            </>
        );
    }
}

const TotalSummaryPie: React.FC<{ holdingsData: Array<SummaryRow>, colorPalette: string[] }> = ({ holdingsData, colorPalette }) => {
    const theme = useTheme();
    const totalData = holdingsData.map((p) => {
        
        return {
            id: p[0] + ":" + p[1],
            label: p[0] + ":" + p[1],
            value: toFloat(p[4], 0).toFixed(2),
        }
    });
    return (
        <ResponsivePie 
            colors={colorPalette}
            data={totalData}
            margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
            innerRadius={0.55}
            padAngle={0.7}
            isInteractive={true}
            activeOuterRadiusOffset={8}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsThickness={2}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        2
                    ]
                ]
            }}
            theme={{
                labels: { text: { fill: theme.palette.text.primary } },
                tooltip: { container: { background: theme.palette.background.default } },
            }}
            onClick={(node, event) => {
                console.log(node, event);
            }}
        />
    );
}

const TotalSummaryChart: React.FC<{ holdingsData: Array<SummaryRow>, colorPalette: string[] }> = ({ holdingsData, colorPalette }) => {
    const theme = useTheme();

    const totalData: any = { id: "Value" };
            const totalKeys: any = [];
            for (let i = 0; i < holdingsData.length; i++) {
                const p = holdingsData[i];
                const key = p[0] + ":" + p[1];
                totalData[key] = toFloat(p[4], 0).toFixed(2);

                totalKeys.push(key);
            }

    return (
        <ResponsiveBar 
            colors={colorPalette}
            data={[totalData]}
            keys={totalKeys}
            enableLabel={false}
            axisBottom={null}
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            theme={{
                legends: { text: { fill: theme.palette.text.primary } },
                labels: { text: { fill: theme.palette.text.primary } },
                axis: { 
                    legend: { text: { fill: theme.palette.text.primary } },
                    ticks: { text: { fill: theme.palette.text.primary } },
                },
                annotations: { text: { fill: theme.palette.text.primary } },
                tooltip: { container: { background: theme.palette.background.default } },
            }}
            tooltip={p => (
                <div style={{ background: theme.palette.background.default }}>
                    <div style={{ background: p.color, height: 12, width: 12, display: "inline-block", marginLeft: 10 }}></div>
                    &nbsp;{p.id}&nbsp;<b>{p.value}</b>
                </div>
            )}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    itemsSpacing: 2,
                    itemWidth: 150,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    translateX: 170,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
    );
}

export default withRouter(Holdings);