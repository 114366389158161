import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { IVErrorsKind, messageFor } from '@common/models/validation';
import { Typography, useTheme } from '@mui/material';

interface IProps {
    label?: string | undefined,
    placeholder?: string | undefined,
    initialValue: string,
    errorMessage?: IVErrorsKind | null,
    height?: number | undefined,
    onBlur: (content: string) => void,
}

export const HtmlTextArea = ({ initialValue, onBlur, placeholder, height = 500, label, errorMessage = null } : IProps) => {
    const invalidMessage = messageFor(errorMessage);
    const invalid = invalidMessage !== "";
    const editorRef = React.useRef<TinyMCEEditor | null>(null);
    const theme = useTheme();

    return (
        <>
            {label && 
            <Typography variant="caption" display="block" gutterBottom>
                {label}
            </Typography>
            }
            <Editor
                tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                onInit={(_evt, editor) => editorRef.current = editor}
                initialValue={initialValue}
                onBlur={() => {
                    if (onBlur != null && editorRef.current) {
                        onBlur((editorRef.current as TinyMCEEditor).getContent());
                    }
                }}
                init={{
                    skin: "oxide-dark",
                    placeholder: placeholder,
                    height: height,
                    menubar: false,
                    statusbar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_css: 'dark',
                    content_style: `
                        body { background-color: #272727; }
                        `,
                }}
            />
            {invalid &&
            <Typography variant="caption" display="block" 
                style={{ color: theme.palette.error.main }}
                className="Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained" gutterBottom>
                {invalidMessage}
            </Typography>
            }
        </>
    );
}