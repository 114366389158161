import * as React from "react";
import MuiSkeleton from "@mui/material/Skeleton";

interface IProps {
    className?: string,
    style?: React.CSSProperties
}

export const SkeletonTile: React.FC<IProps> = ({ className, style }) => {
    return (
        <MuiSkeleton variant="rectangular" style={style} className={className} />
    );
}

export const SkeletonText: React.FC<IProps & { fullwidth?: boolean }> = ({ className, style, fullwidth = false }) => {
    if (fullwidth) {
        style = style || {};
        style.width = "100%";
        style.maxWidth = "100%";
        style.minWidth = "100%";
    }
    return (
        <MuiSkeleton className={className} variant="text" style={style} />
    );
}

export const SkeletonTextbox: React.FC<IProps & { fullwidth?: boolean }> = ({ className, style, fullwidth = true }) => {
    style = style || {};
    style.height = 92;
    style.minHeight = 92;

    if (fullwidth) {
        style.width = "100%";
        style.maxWidth = "100%";
        style.minWidth = "100%";
    }
    return (
        <MuiSkeleton variant="text" style={style} className={className} />
    );
}

interface ISkeletonDataTableProps {
    style?: React.CSSProperties,
    rowCount?: number,
}


export const SkeletonDataTable: React.FC<ISkeletonDataTableProps> = ({ style, rowCount = 5 }) => {
    const s1 = Object.assign({ width: "100%", height: 64, marginBottom: "1rem" }, style);

    const rows = [];
    for (let i = 0; i < rowCount; i++) {
        rows.push(<MuiSkeleton key={i} variant="rectangular" style={s1} />)    
    }
    return (
        <>
            {rows}        
        </>
    );
}
